
import FormularFelt from '@/components/FormularFelt.vue'
import Opsummering from '@/components/Opsummering.vue'
import TGOpsummering from '@/components/TGOpsummering.vue'
import FeltModel from '@/components/models/FeltModel.vue'
import { Options, Vue } from 'vue-class-component'
import TGFormularModel from '@/components/models/TGFormularModel.vue'
import FormularModel from '@/components/models/FormularModel.vue'
import OGFormularModel from '@/components/models/OGFormularModel.vue'
import TrinModel from '@/components/models/TrinModel.vue'
import * as DKFDS from 'dkfds'
import axios from '@/services/axios-instance'
import { FeltType } from '@/components/Const.ts'
import { Prop, Watch } from 'vue-property-decorator'
import { Action, Getter } from 'vuex-class'
import { FormularFeltValidator, MandatoryValidator, MinMaxValidator, RegexValidator } from './FormularFeltValidator'
import TGResultat from '@/components/TGResultat.vue'
import TGProductModel from '@/components/models/TGProductModel.vue'
import KlarTekst from '@/components/KlarTekst.vue'
import ToastUtil from '@/ToastUtil'
import { ToastType } from './models/ToastMessage'
import FormularFeltUtil from './util/FormularFeltUtil'
import TGPublishReplaceModal from '@/components/TGPublishReplaceModal.vue'
import CVRVirksomhedInfo from '@/components/models/CVRVirksomhedInfo'
import { isNull } from 'cypress/types/lodash'
import { Fejlbesked } from './models/Fejl'

@Options({
  components: {
    TGResultat,
    FormularFelt,
    Opsummering,
    TGOpsummering,
    KlarTekst,
    TGPublishReplaceModal
  },
  data () {
    return {
      FeltType
    }
  }
})
export default class Formular extends Vue {
  @Getter('getCurrentUser') currentUser
  @Getter('getContentfulTcoModel') contentfulTcoModel
  @Action('addToast') addToast
  @Getter('isTilbudsgiver') isTilbudsgiver

  editOffer = false
  emitter = require('tiny-emitter/instance')
  nuvaerendeTrin = 0
  nuvaerendeMaksTrin = 1
  numberOfProducts = 1
  numberOfTrinPerProduct = 4
  samletAntalTrin = (this.numberOfTrinPerProduct * this.numberOfProducts) + 1
  formular: TGFormularModel = new TGFormularModel()
  maaGaaTilOpsummering = false
  path = '/web/form/getFormTG'
  title = ''
  opsummeringOverskrift = ''
  savePath = '/web/form/gemTilbud'
  udbudId = ''
  metadataId = ''
  productTemplate : OGFormularModel = new OGFormularModel()
  currentProductIndex = 0
  productLimit = 40
  onResultPage = false
  // to prevent from adding a extra product when fetching an existing formular
  numberOfProductsInitialized = false
  blanketId
  savingDraftInProgress = false

  goToFirstEmptyProduct = true
  unknownOgBlanketId = false
  SkipContactInfoAtInit = true

  feltValidators: Map<string, FormularFeltValidator[]> = new Map();
  eventSubscriptions: Map<string, ((arg1: any, arg2?: any, arg3?: any) => void)[]> = new Map()

  companyInfo?: CVRVirksomhedInfo;
  isOGErrorCVR = true

  get isOGOnBehalfOfTG (): boolean {
    return this.formular?.ogOnBehalfOfTG
  }

  get nuvaerendeFelter (): FeltModel[] {
    return this.getProductFormularAt(this.currentProductIndex)?.trin?.filter(x => x.trin === this.nuvaerendeTrin)[0]?.felter || []
  }

  // watch for changes in udbud in tg blanket, to trigger auto choosing of product group
  @Watch('formular', { deep: true })
  onPropertyChanged (value: TGFormularModel) : void {
    const numProductsFelt = this.findFelt(this.getProductFormularAt(0), 'frontendNumberOfProductVariants')
    // if we add manually one more product - we dont want to reset
    if (numProductsFelt && this.numberOfProducts !== +numProductsFelt?.vaerdi /* && this.numberOfProducts < +numProductsFelt?.vaerdi */) {
      this.goToFirstEmptyProduct = false
    }
  }

  deepClone (formular: OGFormularModel) : OGFormularModel {
    // poor man's deep clone
    return JSON.parse(JSON.stringify(formular))
  }

  @Watch('numberOfProducts')
  numberOfProductsChanged (value: number, oldValue: number) : void {
    // add empty products
    if (oldValue < value) {
      if (this.numberOfProductsInitialized) {
        for (let i = oldValue; i < value; i++) {
          const productModel = new TGProductModel()
          productModel.formular = this.deepClone(this.productTemplate)
          this.formular.products.push(productModel)
        }
      } else {
        this.numberOfProductsInitialized = true
      }
    } else if (oldValue > value) {
      this.currentProductIndex = 0
      for (let i = oldValue; i > value; i--) {
        if (this.formular.products && this.formular.products.length > 1) { // cant delete last product
          // update formular
          this.formular.products.splice(i - 1, 1)
        }
      }
    }

    // update dropdown in trin 1 with new value
    for (let i = 0; i < this.formular.products.length; i++) {
      const numProductsFelt = this.findFelt(this.getProductFormularAt(i), 'frontendNumberOfProductVariants')
      if (numProductsFelt) {
        numProductsFelt.vaerdi = this.numberOfProducts.toString()
      }
    }
    this.samletAntalTrin = (this.numberOfTrinPerProduct * this.numberOfProducts) + 1

    if (oldValue < value && this.goToFirstEmptyProduct) {
      this.changeToFirstEmptyProductWithoutName()
    }
  }

  changeToFirstEmptyProductWithoutName () : void {
    // change to first product without name if we are adding new products
    let productIdx = 0
    for (let i = 0; i < this.formular.products.length; i++) {
      const produktnavnFelt = this.findFelt(this.getProductFormularAt(i), 'cac:TCOLine.cac:Item.cbc:Name')
      if (produktnavnFelt && !produktnavnFelt.vaerdi) {
        productIdx = i
        break
      }
    }

    this.skiftTilProduktTrin(productIdx, 2)
  }

  initBlanket (blanket : TGFormularModel, initOgSummary : boolean) : void {
    this.formular = blanket
    this.productTemplate = blanket.productTemplate
    if (initOgSummary) {
      this.formular.ogInformation = new OGFormularModel()
    }
    this.currentProductIndex = 0

    // Lyt efter søskende-komponenters kald til redigerFelt
    this.subscribeEvent('redigerFelt', (arg1, arg2, arg3) => {
      this.redigerFelt(arg1, arg2, arg3)
    })

    // Lyt efter søskende-komponenters kald til rediger
    this.subscribeEvent('rediger', (arg1) => {
      this.rediger(arg1)
    })

    // Lyt efter søskende-komponenters kald til deleteProduct
    this.subscribeEvent('deleteProduct', (arg1) => {
      this.deleteProduct(arg1)
    })

    this.subscribeEvent('feltValideret', (felt) => {
      if (!this.isOGOnBehalfOfTG && felt.tcoElementName === 'cac:SellerSupplierParty.cac:Party.cac:PartyLegalEntity.cbc:CompanyID' && felt.erValid && felt.vaerdi && (!this.companyInfo || this.companyInfo.cvrNummer !== felt.vaerdi)) {
        this.lookupCVRForContactInfo(felt.vaerdi)
      } else if (this.isOGOnBehalfOfTG && felt.tcoElementName === 'cac:SellerSupplierParty.cac:Party.cac:PartyLegalEntity.cbc:CompanyID' && felt.erValid && felt.vaerdi) {
        this.lookupCVRForContactInfo(felt.vaerdi)
      }
      if (this.isOGOnBehalfOfTG && felt.tcoElementName === 'cac:SellerSupplierParty.cac:Party.cac:PartyLegalEntity.cbc:CompanyID' && !felt.erValid) {
        this.isOGErrorCVR = true
        const navn = this.formular.contactInfo.felter.find(f => f.tcoElementName === 'cac:SellerSupplierParty.cac:Party.cac:PartyLegalEntity.cbc:RegistrationName')
        if (navn) {
          navn.touched = true
          navn.vaerdi = ''
        }
        const vej = this.formular.contactInfo.felter.find(f => f.tcoElementName === 'cac:SellerSupplierParty.cac:Party.cac:PostalAddress.cbc:StreetName')
        if (vej) {
          vej.touched = true
          vej.vaerdi = ''
        }
        const husnr = this.formular.contactInfo.felter.find(f => f.tcoElementName === 'cac:SellerSupplierParty.cac:Party.cac:PostalAddress.cbc:BuildingNumber')
        if (husnr) {
          husnr.touched = true
          husnr.vaerdi = ''
        }
        const postnr = this.formular.contactInfo.felter.find(f => f.tcoElementName === 'cac:SellerSupplierParty.cac:Party.cac:PostalAddress.cbc:PostalZone')
        if (postnr) {
          postnr.touched = true
          postnr.vaerdi = ''
        }
        const by = this.formular.contactInfo.felter.find(f => f.tcoElementName === 'cac:SellerSupplierParty.cac:Party.cac:PostalAddress.cbc:CityName')
        if (by) {
          by.touched = true
          by.vaerdi = ''
        }
        const land = this.formular.contactInfo.felter.find(f => f.tcoElementName === 'cac:SellerSupplierParty.cac:Party.cac:PostalAddress.cac:Country.cbc:IdentificationCode')
        if (land) {
          land.touched = true
          land.vaerdi = ''
          land.displayVaerdi = ''
        }
      }
    })
    this.subscribeEvent('felt-change', (felt) => {
      if (felt.tcoElementName === 'cac:TCOLine.cbc:WarrantyInformation') {
        var radioJaNej: FeltModel[] = []
        radioJaNej.push(felt)
        this.aktiverValidering(radioJaNej)
      }
    })
  }

  created () : void {
    this.title = this.contentfulTcoModel.findKlarTekst('tco.blanket.tg-blanket.overskrift')
    this.opsummeringOverskrift = this.contentfulTcoModel.findKlarTekst('tco.blanket.tg-blanket.opsummering.overskrift')

    const queryParams: any = {}
    queryParams.updateContactInfo = true
    const ogId = this.$route.query.og_id as string
    queryParams.ogId = ogId
    const productId = this.$route.query.product_id as string
    queryParams.productId = productId

    this.blanketId = this.$route.params.id as string
    if (this.blanketId) {
      // example of url: first id is blanketId second is OG-id
      // http://localhost:8080/blanketTG/4ef87c86-b4ab-4508-a9a8-d83593106f52?og_id=31845965-ac9f-4cfc-8721-1897e3a2c43f
      axios.get(process.env.VUE_APP_DIRIGENT_URL + this.path + '/' + this.blanketId, { params: queryParams })
        .then(
          async response => {
            this.maaGaaTilOpsummering = true
            this.initBlanket(response.data, true)
            this.numberOfProducts = response.data.numberOfProducts
            if (this.numberOfProducts === 1) {
              // if number of product variants is 1 it will not trigger the watch when entering the page, we need to set this parameter as true
              this.numberOfProductsInitialized = true
              this.changeToFirstEmptyProductWithoutName()
            }
            if (this.formular.status === 'published') {
              this.editOffer = true
            }
            const ogId = this.$route.query.og_id as string
            const goto = this.$route.query.goto as string
            if (ogId) {
              await this.initOGOplysninger(ogId)
              const productGroupId = this.findFelt(this.formular.ogInformation, 'cac:TCOLine[1].cbc:ID')
              this.metadataId = productGroupId?.vaerdi || ''
              await this.initProduktGruppe(this.metadataId)
            }
            if (goto === 'opsummering') {
              this.gotoOpsummering()
            }
          }).catch(error => this.addToast(ToastUtil.createToastForErrorResponse(error, this.contentfulTcoModel)))
    } else {
      if (!ogId) this.$router.replace('/')

      axios.get(process.env.VUE_APP_DIRIGENT_URL + this.path, { params: queryParams })
        .then(
          async response => {
            // nothing to initialize
            this.numberOfProductsInitialized = true
            this.initBlanket(response.data, true)
            if (ogId) {
              await this.initOGOplysninger(ogId)
                .then(async () => {
                  if (this.formular.ogInformation) {
                    const productGroupId = this.findFelt(this.formular.ogInformation, 'cac:TCOLine[1].cbc:ID')
                    this.metadataId = productGroupId?.vaerdi || ''
                    await this.initProduktGruppe(this.metadataId)
                  }
                })
            }
          }).catch(error => this.addToast(ToastUtil.createToastForErrorResponse(error, this.contentfulTcoModel)))
    }
  }

  mounted () : void {
    const tab = new DKFDS.Tabnav(document.getElementById('tabs')) // NOSONAR
  }

  async initOGOplysninger (ogId: string): Promise<void> {
    this.udbudId = ogId
    await this.hentOgDocument(ogId)
    const numProductsFelt = this.findFelt(this.formular.products[0].formular, 'frontendNumberOfProductVariants')
    if (numProductsFelt) {
      numProductsFelt.vaerdi = this.numberOfProducts + ''
    }
  }

  async initProduktGruppe (id : string) : Promise<void> {
    if (!id) return

    await axios.post(process.env.VUE_APP_DIRIGENT_URL + '/web/form/tilfoejmetadata/TG/' + this.udbudId + '/' + id,
      this.hentJsonFeltDataForProduct(this.getProductFormularAt(0)),
      { headers: { 'content-type': 'application/json' } }).then(
      response => {
        this.productTemplate = this.deepClone(response.data.products[0].formular)
        console.log(this.productTemplate)
        // copy ref trin 1 - indledende valg af antal produkter er 'shared' between products
        this.productTemplate.trin[0] = response.data.products[0].formular.trin[0]
        console.log(this.productTemplate.trin[0])
        if (!this.blanketId) {
          for (let i = 0; i < this.formular.products.length; i++) {
            this.formular.products[i].formular = this.deepClone(this.productTemplate)
          }
        }
        console.log(this.formular.products[0].formular)
        // after population of metadata we need to manually do this - should must likely be moved to back end
        this.formular.numberOfProducts = this.numberOfProducts
        const numProductsFelt = this.findFelt(this.formular.products[0].formular, 'frontendNumberOfProductVariants')
        console.log(numProductsFelt)
        if (numProductsFelt != null) {
          numProductsFelt.vaerdi = this.numberOfProducts + ''
        }

        for (const felt of this.getProductFormularAt(0).trin[0].felter) {
          felt.erValid = true
        }
      }
    ).catch(error => this.addToast(ToastUtil.createToastForErrorResponse(error, this.contentfulTcoModel)))
  }

  redigerFelt (feltId: string, trinNummer : number, productIndex : number) : void {
    // Kontaktoplysninger
    if (trinNummer === 0) {
      this.skiftTilKontaktOplysninger()
    } else {
      this.currentProductIndex = productIndex
      this.skiftTrin(trinNummer, true)
    }

    setTimeout(() => { // hack til at afvente ovenstående kald
      const input = document.getElementById(feltId)
      if (input) {
        input.focus()
      }
    }, 100)
  }

  rediger (productIndex : number) : void {
    this.currentProductIndex = productIndex
    this.skiftTrin(2, true)
  }

  deleteProduct (productIndex : number) : void {
    if (this.numberOfProducts > 1) {
      this.currentProductIndex = 0
      // update formular
      this.formular.products.splice(productIndex, 1)

      this.numberOfProducts = this.numberOfProducts - 1
      const numProductsFelt = this.findFelt(this.formular.products[0].formular, 'frontendNumberOfProductVariants')
      if (numProductsFelt) {
        numProductsFelt.vaerdi = this.numberOfProducts + ''
      }
      // update counters
      this.nuvaerendeTrin = this.nuvaerendeTrin - this.numberOfTrinPerProduct
      this.samletAntalTrin = this.samletAntalTrin - this.numberOfTrinPerProduct
      this.nuvaerendeMaksTrin = this.nuvaerendeMaksTrin - this.numberOfTrinPerProduct
      if (this.formular.numberOfProducts > 1) {
        this.formular.numberOfProducts = this.formular.numberOfProducts - 1
      } else if (this.formular.numberOfProducts === 1) {
        this.formular.numberOfProducts = 1
      }
    } else {
      console.log('Du må ikke fjerne den sidste produkt')
    }
  }

  /**
   * @param trinNummer (valgfri) Hvis der angives et trin nummer, hentes fejl for det pågældende trin, eller hentes alle fejl i formularen
   */
  hentFejl (trinNummer? : number) : Fejlbesked[] {
    const fejl: Fejlbesked[] = []
    const harTrin = this.getProductFormularAt(this.currentProductIndex)?.trin
    const trinMedNummer = harTrin && this.getProductFormularAt(this.currentProductIndex).trin.filter(x => x.trin === trinNummer)[0]
    const trin = trinNummer != null && harTrin && trinMedNummer
      ? trinMedNummer
      : new TrinModel()
    const felter = trinNummer != null
      ? trin.felter?.filter(y => !y.erValid && y.felttype !== FeltType.HIDDEN)
      : []
    if (typeof trinNummer === 'undefined' || trinNummer == null) {
      for (let i = 0; i < this.formular.products.length; i++) {
        this.getProductFormularAt(i).trin.forEach(value => {
          if (value.felter) {
            value.felter.filter(y => !y.erValid && y.felttype !== FeltType.HIDDEN).forEach(felt => felter.push(felt))
          }
        })
      }
    }
    if (felter) {
      felter.forEach(felt => {
        const feltId = felt.tcoElementName != null && felt.tcoElementName !== '' ? felt.tcoElementName : 'produktgruppeVaelger'
        if (felt.fejl) {
          felt.fejl.forEach(f => {
            const error = { tcoElementName: feltId, fejlbesked: `${felt.maerkat}: ${f}` }
            fejl.push(error)
          })
        }
      })
    }
    return fejl
  }

  hentFejlContactInfo () : Fejlbesked[] {
    const fejl: Fejlbesked[] = []
    const trin = this.formular.contactInfo
    const felter = trin.felter?.filter(y => !y.erValid && y.felttype !== FeltType.HIDDEN)
    if (felter) {
      felter.forEach(felt => {
        const feltId = felt.tcoElementName != null && felt.tcoElementName !== '' ? felt.tcoElementName : 'produktgruppeVaelger'
        if (felt.fejl) {
          felt.fejl.forEach(f => {
            const error = { tcoElementName: feltId, fejlbesked: `${felt.maerkat}: ${f}` }
            fejl.push(error)
          })
        }
      })
    }
    return fejl
  }

  async skiftTrin (value: number, erSpecifik? : boolean) : Promise<void> {
    // når vi går til side 2 i TG henter vi metadata felter fra vald produktgruppe
    if (this.nuvaerendeTrin === 1) {
      this.goToFirstEmptyProduct = false
      const numProductsFelt = this.findFelt(this.getProductFormularAt(this.currentProductIndex), 'frontendNumberOfProductVariants')
      if (numProductsFelt) {
        this.numberOfProducts = +numProductsFelt.vaerdi
      }
    }
    if (this.nuvaerendeFelter) {
      this.aktiverValidering(this.nuvaerendeFelter)
    }

    this.hentFejl(this.nuvaerendeTrin)

    if (!erSpecifik) {
      this.nuvaerendeTrin += value
    } else {
      this.nuvaerendeTrin = value
    }
    if (this.getTrinNumberOutOfTotal(this.nuvaerendeTrin) > this.nuvaerendeMaksTrin) {
      this.nuvaerendeMaksTrin = this.getTrinNumberOutOfTotal(this.nuvaerendeTrin)
    }

    // Forhindre evt. dobbelt klik på Tilbage ved trin 2 (ingen trin før 1)
    if (this.nuvaerendeTrin < 1) {
      this.nuvaerendeTrin = 1
    }

    // on next click we need to ensure that mandatory fields not have been skipped.
    this.validateCurrentFields()

    this.onResultPage = false

    this.validatePreviousTrins()

    const tilbageKnap = document.getElementById('tilbageKnap')
    if (tilbageKnap != null) {
      tilbageKnap.focus()
    }
  }

  validatePreviousTrins () : void {
    // validate all fields when editing an existing formular, up to max trin for the current product
    this.formular.contactInfo.felter.forEach((f) => this.erValid(f))
    // all trins for previous products
    for (let i = 0; i < this.currentProductIndex; i++) {
      this.formular.products[i].formular.trin.forEach((t) => t.felter.forEach((f) => this.erValid(f)))
    }

    // up to previous trin for selected product
    this.formular.products[this.currentProductIndex].formular.trin.slice(0, this.nuvaerendeTrin - 1).forEach((t) => t.felter.forEach((f) => this.erValid(f)))
  }

  // when going to summary
  validateAllPreviousTrins () : void {
    this.formular.contactInfo.felter.forEach((f) => this.erValid(f))
    // all trins for previous products
    for (let i = 0; i < this.numberOfProducts; i++) {
      this.formular.products[i].formular.trin.forEach((t) => t.felter.forEach((f) => this.erValid(f)))
    }
  }

  validateCurrentFields () : void {
    if (this.nuvaerendeFelter) {
      for (const felt of this.nuvaerendeFelter) {
        if (felt.erObligatorisk && (felt.vaerdi == null || felt.vaerdi === '')) {
          felt.erValid = false
        }
      }
    }
  }

  // this function helps the trin guide to make sure that it shows error symbols if not mandatory field are filled out yet.
  isMandatoryFilled (trinNumber: number) : boolean {
    const fieldsInTrin = this.getProductFormularAt(this.currentProductIndex).trin.filter(t => t.trin === trinNumber)[0]
    let isMandatoryFilled = true
    for (const f of fieldsInTrin.felter) {
      if (f.erObligatorisk && (f.vaerdi == null || f.vaerdi === '')) {
        isMandatoryFilled = false
        break
      }
    }
    return isMandatoryFilled
  }

  hentNuvaerendeTrin () : TrinModel {
    return this.getProductFormularAt(this.currentProductIndex)?.trin
      ? this.getProductFormularAt(this.currentProductIndex).trin.filter(x => x.trin === this.nuvaerendeTrin)[0] : new TrinModel('1', 1)
  }

  hentNuvaerendeTrinBeskrivelse () : string {
    const trinModel = this.hentNuvaerendeTrin()
    return this.contentfulTcoModel.renderRichTekstFromJson(trinModel?.beskrivelse)
  }

  gemFormular (doCalculateScore : boolean, saveDraftNotification : boolean) : void {
    this.savingDraftInProgress = true
    const path = this.savePath + (this.blanketId ? '/' + this.blanketId : '')
    // POST request using axios
    axios.post(process.env.VUE_APP_DIRIGENT_URL + path, this.hentJsonFeltData(),
      {
        headers: {
          'content-type': 'application/json'
        },
        params: {
          doCalcScore: doCalculateScore
        }
      }).then(response => {
      if (doCalculateScore) {
        const scoreModel : TGFormularModel = response.data
        for (let i = 0; i < scoreModel.products.length; i++) {
          this.formular.products[i].score = scoreModel.products[i].score
        }
        this.blanketId = scoreModel.id
        this.savingDraftInProgress = false
      } else {
        this.blanketId = response.data
        if (response.status === 200) {
          if (saveDraftNotification) {
            const headline = this.contentfulTcoModel.findKlarTekst('tco.blanket.tg-blanket.gemkladde.success.overskrift')
            const text = this.contentfulTcoModel.findKlarTekst('tco.blanket.tg-blanket.gemkladde.success.beskrivelse')
            this.addToast({ headline: headline, text: text, type: ToastType.SUCCESS })
          }
        }
        this.savingDraftInProgress = false
      }
    }).catch(error => {
      this.onResultPage = false
      this.addToast(ToastUtil.createToastForErrorResponse(error, this.contentfulTcoModel))
      this.savingDraftInProgress = false
    })
  }

  gemKladde (doCalculateScore : boolean, saveDraftNotification : boolean) : void {
    if (this.savingDraftInProgress) {
      window.setTimeout(() => this.gemKladde(doCalculateScore, saveDraftNotification), 500)
    } else {
      this.gemFormular(doCalculateScore, saveDraftNotification)
    }
  }

  hentJsonFeltData () : string {
    return JSON.stringify(this.formular, null, 2)
  }

  hentJsonFeltDataForProduct (product: OGFormularModel) : string {
    return JSON.stringify(product, null, 2)
  }

  // Funktion til at aktivere valideringen ved at fjerne "null" state for feltet og erstatte det med ""
  aktiverValidering (felter : FeltModel[]) : void {
    felter.forEach(felt => {
      if (felt.vaerdi == null || felt.vaerdi === '') {
        felt.vaerdi = ''
        this.emitter.emit('opdaterFeltValiditet', felt.tcoElementName)
      } else if (felt.felttype === FeltType.DATO) { // force revalidation af dato felter da de ikke virker automatisk on blur pga. DKFDS
        this.emitter.emit('opdaterFeltValiditet', felt.tcoElementName)
      } else {
        this.emitter.emit('opdaterFeltValiditet', felt.tcoElementName)
      }
    })
  }

  skiftTilUdbudsInfo () : void {
    const produktTab = document.getElementById('tab_udbudsinfo')
    if (produktTab) {
      produktTab.click()
    }
  }

  skiftTilKontaktOplysninger () : void {
    const kontaktTab = document.getElementById('tab_kontaktinfo')
    if (kontaktTab) {
      kontaktTab.click()
    }
  }

  async hentOgDocument (id : string) : Promise<void> {
    await axios.post(process.env.VUE_APP_DIRIGENT_URL + '/web/form/getForm/' + id + '?active=true',
      '',
      { headers: { 'content-type': 'application/json' } }).then(
      response => {
        this.formular.ogInformation = response.data
      }).catch(error => {
      this.unknownOgBlanketId = true
      if (error.response.status === 404) {
        const headline = this.contentfulTcoModel.findKlarTekst('tco.blanket.tg-blanket.introduktion.fejl.ukendt-og-blanket.overskrift')
        const text = this.contentfulTcoModel.findKlarTekst('tco.blanket.tg-blanket.introduktion.fejl.ukendt-og-blanket.beskrivelse')
        this.addToast({ headline: headline, text: text, type: ToastType.ERROR })
      } else {
        this.addToast(ToastUtil.createToastForErrorResponse(error, this.contentfulTcoModel))
      }
    })
  }

  setCurrentProductIndex (productIndex : number) : void {
    this.currentProductIndex = productIndex
  }

  skiftTilProduktTrin (productIndex: number, trin: number) : void {
    this.skiftTilUdbudsInfo()
    this.setCurrentProductIndex(productIndex)
    this.skiftTrin(trin, true)
  }

  nextProduct () : void {
    this.currentProductIndex++
    (document.getElementById('product-index-dropdown') as HTMLFormElement).value++
    this.skiftTrin(-2)
  }

  getProductNameForDropdown (n : number) : string {
    const productName = this.getProductFormularAt(n - 1)?.trin[1]?.felter[0]?.vaerdi
    if (productName != null && productName !== '') {
      return productName
    } else {
      return 'Produkt #' + n
    }
  }

  private findFelt (formular: FormularModel, tcoElementName: string): FeltModel | null {
    if (!formular || !formular.trin) {
      return null
    }
    if (tcoElementName) {
      for (const trin of formular.trin) {
        if (trin.felter) {
          const felt = trin.felter.find(x => x.tcoElementName === tcoElementName)
          if (felt) {
            return felt
          }
        }
      }
    }
    return null
  }

  addNewProduct (): void {
    this.goToFirstEmptyProduct = true
    this.numberOfProducts++
  }

  getTrinNumberOutOfTotal (trin : number): number {
    return trin + (this.currentProductIndex * this.numberOfTrinPerProduct)
  }

  getMainTabLabel () : string {
    if (this.onResultPage) {
      return 'Resultat'
    }
    return this.nuvaerendeTrin === this.samletAntalTrin ? this.contentfulTcoModel.findKlarTekst('tco.blanket.tg-blanket.opsummering.tabnavn') : this.contentfulTcoModel.findKlarTekst('tco.blanket.tg-blanket.produktoplysninger.tabnavn')
  }

  onBackButtonClick () : void {
    if (this.nuvaerendeTrin < this.samletAntalTrin) {
      if (this.nuvaerendeTrin === 1) {
        this.skiftTilKontaktOplysninger()
      } else {
        this.skiftTrin(-1)
      }
    } else if (!this.onResultPage) {
      this.skiftTrin(4, true)
    } else {
      this.onResultPage = false
    }
  }

  getProductFormularAt (i : number) : OGFormularModel {
    return this.formular.products[i]?.formular
  }

  publish () : void {
    let responseID
    const udbudsnavn = this.formular.ogInformation.contactInfo.felter[1].vaerdi
    if (this.blanketId !== '') {
      axios.post(process.env.VUE_APP_DIRIGENT_URL + '/web/form/publishTg/' + this.blanketId,
        '',
        {
          headers: { 'content-type': 'application/json' }
        }).then(
        response => {
          if (response.status === 200) {
            responseID = response.data
            this.$router.push({
              name: 'kvitteringTG',
              params: { formularID: responseID.toString(), ogid: this.formular.ogInformation.id, ogName: udbudsnavn }
            })
          }
        }).catch(error => this.addToast(ToastUtil.createToastForErrorResponse(error, this.contentfulTcoModel)))
    }
  }

  private defaultFeltValidators (): FormularFeltValidator[] {
    const validators: FormularFeltValidator[] = []
    validators.push(new MandatoryValidator(this.contentfulTcoModel))
    validators.push(new MinMaxValidator(this.contentfulTcoModel))
    validators.push(new RegexValidator(this.contentfulTcoModel))
    return validators
  }

  erValid (felt: FeltModel) : void { // NOSONAR
    felt.fejl = []
    felt.touched = true
    felt.erValid = true
    const defaultValidators = this.defaultFeltValidators()

    for (const validator of defaultValidators) {
      const fejl = validator.validate(felt)
      if (fejl && fejl.length > 0) {
        this.tilfoejFejl(felt, fejl)
        felt.erValid = false
      }
    }

    const validators = this.feltValidators.get(felt.tcoElementName)
    if (validators && validators.length) {
      for (const validator of validators) {
        const fejl = validator.validate(felt)
        if (fejl && fejl.length > 0) {
          this.tilfoejFejl(felt, fejl)
          felt.erValid = false
        }
      }
    }
  }

  tilfoejFejl (felt: FeltModel, nyeFejl: string[]): void {
    if (nyeFejl && nyeFejl.length > 0) {
      nyeFejl.forEach(nf => {
        if (felt.fejl.findIndex(f => f === nf) < 0) {
          felt.fejl.push(nf)
        }
      })
    }
  }

  subscribeEvent (eventName: string, callbackFn: (arg1: any, arg2?: any, arg3?: any) => void): void {
    if (!this.eventSubscriptions.get(eventName)) {
      this.eventSubscriptions.set(eventName, [])
    }
    this.eventSubscriptions.get(eventName)!.push(callbackFn)
    this.emitter.on(eventName, callbackFn)
  }

  unmounted (): void {
    if (this.eventSubscriptions && this.eventSubscriptions.size > 0) {
      this.eventSubscriptions.forEach((callbacks, eventName) => {
        callbacks.forEach((eventHandler) => {
          this.emitter.off(eventName, eventHandler)
        })
      })
    }
    this.eventSubscriptions.clear()
  }

  gotoOpsummering (): void {
    this.nuvaerendeMaksTrin = this.nuvaerendeTrin = this.samletAntalTrin
    this.maaGaaTilOpsummering = true
    this.setCurrentProductIndex(this.formular.numberOfProducts - 1) // set last product as active to ensure proper validation of all products
    this.skiftTilUdbudsInfo()
    this.validateAllPreviousTrins()
  }

  forsaetTilBlanket (): void {
    this.skiftTilUdbudsInfo()
    // necessary otherwise vue for some reason loses reactivity on fields in trin 1
    this.setCurrentProductIndex(this.currentProductIndex)
    this.aktiverValidering(this.formular.contactInfo?.felter)
  }

  printResultat () : void {
    const route = this.$router.resolve({ path: '/blanketTG/resultat/print/' + this.blanketId })
    window.open(route.href, '_blank')
  }

  printOpsummering () : void {
    const route = this.$router.resolve({ path: '/opsummeringTG/print/' + this.blanketId })
    window.open(route.href, '_blank')
  }

  SkipContact () : void {
    if (this.SkipContactInfoAtInit) {
      this.SkipContactInfoAtInit = false
      this.nuvaerendeTrin++
    }
  }

  genererDomId (tcoElementName: string): string {
    return FormularFeltUtil.escapeForDomId(tcoElementName)
  }

  lookupCVRForContactInfo (cvr: string): void {
    if (!this.isOGOnBehalfOfTG) return
    const queryParams: any = {}
    queryParams.ogId = this.udbudId
    queryParams.tgBlanketId = this.blanketId
    axios.get(process.env.VUE_APP_DIRIGENT_URL + '/web/form/cvr-info/' + cvr, { params: queryParams })
      .then(response => {
        this.companyInfo = response.data

        if (this.companyInfo?.eksisterendeTGBlanketForUdbud) {
          const errorMessage = this.contentfulTcoModel.findKlarTekst('tco.blanket.tg-blanket.fejl.og-paa-vegne-af-tg-eksisterende-tg-blanket')
          this.addToast({ text: errorMessage, type: ToastType.ERROR })
          if (this.isOGOnBehalfOfTG) {
            this.isOGErrorCVR = true
            return
          }
        }

        const navn = this.formular.contactInfo.felter.find(f => f.tcoElementName === 'cac:SellerSupplierParty.cac:Party.cac:PartyLegalEntity.cbc:RegistrationName')
        if (navn) {
          navn.touched = true
          navn.vaerdi = this.companyInfo?.navn ? this.companyInfo.navn : ''
        }

        const vej = this.formular.contactInfo.felter.find(f => f.tcoElementName === 'cac:SellerSupplierParty.cac:Party.cac:PostalAddress.cbc:StreetName')
        if (vej) {
          vej.touched = true
          vej.vaerdi = this.companyInfo?.vejnavnFormatted ? this.companyInfo.vejnavnFormatted : ''
        }

        const husnr = this.formular.contactInfo.felter.find(f => f.tcoElementName === 'cac:SellerSupplierParty.cac:Party.cac:PostalAddress.cbc:BuildingNumber')
        if (husnr) {
          husnr.touched = true
          husnr.vaerdi = this.companyInfo?.husnummerFormatted ? this.companyInfo.husnummerFormatted : ''
        }

        const postnr = this.formular.contactInfo.felter.find(f => f.tcoElementName === 'cac:SellerSupplierParty.cac:Party.cac:PostalAddress.cbc:PostalZone')
        if (postnr) {
          postnr.touched = true
          postnr.vaerdi = this.companyInfo?.postnummer ? this.companyInfo.postnummer : ''
        }

        const by = this.formular.contactInfo.felter.find(f => f.tcoElementName === 'cac:SellerSupplierParty.cac:Party.cac:PostalAddress.cbc:CityName')
        if (by) {
          by.touched = true
          by.vaerdi = this.companyInfo?.postdistrikt ? this.companyInfo.postdistrikt : ''
        }

        const land = this.formular.contactInfo.felter.find(f => f.tcoElementName === 'cac:SellerSupplierParty.cac:Party.cac:PostalAddress.cac:Country.cbc:IdentificationCode')
        if (land) {
          land.touched = true
          land.vaerdi = this.companyInfo?.landekode ? this.companyInfo.landekode : ''
          land.displayVaerdi = this.companyInfo?.land ? this.companyInfo.land : ''
        }
        if (this.isOGOnBehalfOfTG) {
          this.isOGErrorCVR = false
        }
      }).catch(error => {
        this.addToast(ToastUtil.createToastForErrorResponse(error, this.contentfulTcoModel))
        const felter = [
          'cac:SellerSupplierParty.cac:Party.cac:PartyLegalEntity.cbc:RegistrationName',
          'cac:SellerSupplierParty.cac:Party.cac:PostalAddress.cbc:StreetName',
          'cac:SellerSupplierParty.cac:Party.cac:PostalAddress.cbc:BuildingNumber',
          'cac:SellerSupplierParty.cac:Party.cac:PostalAddress.cbc:PostalZone',
          'cac:SellerSupplierParty.cac:Party.cac:PostalAddress.cbc:CityName',
          'cac:SellerSupplierParty.cac:Party.cac:PostalAddress.cac:Country.cbc:IdentificationCode'
        ]
        felter.forEach(feltNavn => {
          const felt = this.formular.contactInfo?.felter.find(f => f.tcoElementName === feltNavn)
          if (felt) {
            felt.vaerdi = ''
          }
        })
        if (this.isOGOnBehalfOfTG) {
          this.isOGErrorCVR = true
        }
      })
  }
}
