
/* eslint-disable semi */
import { Vue } from 'vue-class-component'
import {
  Action,
  namespace
} from 'vuex-class'
import router from '@/router'

const oidcStoreModule = namespace('oidcStore');

export default class Logout extends Vue {
  @oidcStoreModule.Action('signOutOidc') oidcStoreLogoutAction;
  @Action('logout') logoutAction;
  @oidcStoreModule.Getter('oidcIsAuthenticated') isAuthenticated;

  beforeMount (): void {
    if (this.isAuthenticated) {
      this.oidcStoreLogoutAction();
      this.logoutAction();
    } else {
      router.push('/');
    }
  }
}
