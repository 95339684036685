
/* eslint-disable */

import { documentToHtmlString } from '@contentful/rich-text-html-renderer';
import { BLOCKS } from '@contentful/rich-text-types';

//Note: Render options below are used due to TCO styling issues on standard Contentful redering using only p tag
export default class ContentfulTcoContent {
  renderOptions = {
    renderNode: {
      [BLOCKS.PARAGRAPH]: (node, next) =>
        `<span>${next(node.content)}<p></p></span>`
    }
  }

  klarTekstMap: Map<string, string> = new Map()
  richTekstMap: Map<string, object> = new Map()

  defaultRt =  {
    nodeType: 'document',
    content: [
      {
        nodeType: 'paragraph',
        content: [
          {
            nodeType: 'text',
            value: 'Hello world!',
            marks: []
          }
        ]
      }
    ]
  };

  constructor (data : any) {
    let tekster: string[] = data.klarTekster
    // eslint-disable-next-line no-unused-expressions
    tekster?.forEach((tekst: any) => {
      this.klarTekstMap.set(tekst.noegle, tekst.vaerdi)
    })
    let rtekster = data.richTekster
    // eslint-disable-next-line no-unused-expressions
    rtekster?.forEach((tekst: any) => {
      this.richTekstMap.set(tekst.noegle, tekst.vaerdi)
    })
  }

  findKlarTekst (tekstNoegle: string): string {
    const value = this.klarTekstMap.get(tekstNoegle)
    return (value != null ? value : tekstNoegle)
  }

  findKlarTekstOrNull (tekstNoegle: string): string | null {
    const value = this.klarTekstMap.get(tekstNoegle)
    return (value != null ? value : null)
  }

  findRichTekst (tekstNoegle: string): any {
    const value = this.richTekstMap.get(tekstNoegle)
    return (value != null ? value : this.defaultRt)
  }

  renderRichTekst(tekstNoegle: string): string {
    const richTekst = this.findRichTekst(tekstNoegle).json
    if (richTekst) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      return documentToHtmlString(richTekst, this.renderOptions);
    }
    return ''
  }

  renderRichTekstFromJson(richTekstAsString: string): string {
    if (richTekstAsString && richTekstAsString.length != 0) {
      const richTekst = JSON.parse(richTekstAsString)
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      return documentToHtmlString(richTekst, this.renderOptions);
    }
    return ''
  }
}
