
import { Options, Vue } from 'vue-class-component'
import * as DKFDS from 'dkfds'
import DokumentListe from '@/components/DokumentListe.vue'
import KlarTekst from '@/components/KlarTekst.vue'

@Options({
  components: {
    DokumentListe,
    KlarTekst
  }
})
export default class OverblikTG extends Vue {
  initialActiveTab = 0

  mounted () : void {
    const tab = new DKFDS.Tabnav(document.getElementById('tabs'))

    if (this.$route.query.tab === 'drafts') {
      // This is absolutely NOT the way to do this, but the problem is
      // that child components call DKFDS.init which reinitializes the tabs again,
      // which resets the active tab to the first one. The proper thing to do would be
      // to get rid of DKFDS.init everywhere, but requires some analysis as well as testing,
      // so we use this workaround temporarily instead
      setTimeout(() => { this.initialActiveTab = 1 }, 250)
    }
  }
}
