
import { Options, Vue } from 'vue-class-component'
import TGFormularModel from './models/TGFormularModel.vue'
import TGDetailsEntry from '@/components/TGDetailsEntry.vue'
import KlarTekst from '@/components/KlarTekst.vue'
import { FeltType } from '@/components/Const'
import FeltModel from './models/FeltModel.vue'
import DateUtil from '@/DateUtil'
import { Prop } from 'vue-property-decorator'

@Options({
  components: {
    KlarTekst,
    TGDetailsEntry
  },
  data () {
    return {
      FeltType
    }
  }
})
export default class TGDetails extends Vue {
  @Prop({ type: Boolean }) printMode = false
  @Prop({ type: Boolean }) noContactInfo = false
  @Prop({ type: String }) blanketId
  @Prop({ type: TGFormularModel }) formular = new TGFormularModel()

  formatFelt (felt : FeltModel) : string {
    switch (felt.felttype) {
      case FeltType.TEKSTFELT_TAL:
        return this.formatNumeric(felt.vaerdi)
      case FeltType.RADIO_JA_NEJ:
        return felt.vaerdi === 'true' ? 'Ja' : 'Nej'
      case FeltType.VALGLISTE:
        return felt.valgmuligheder[felt.vaerdi]
      case FeltType.DATO:
        return this.formatDate(felt.vaerdi)
      case FeltType.STATIC_TEKST:
        return felt.displayVaerdi || felt.vaerdi
      default:
        return felt.vaerdi
    }
  }

  formatNumeric (value : string) : string {
    if (value == null) {
      return ''
    }
    return value.replaceAll('.', ',')
  }

  formatDate (value: string): string {
    return DateUtil.formatDate(value)
  }
}
