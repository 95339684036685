

import { Action, Getter } from 'vuex-class'
import { Vue } from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import { ToastMessage, ToastType } from './models/ToastMessage'
import * as DKFDS from 'dkfds'

export default class Toast extends Vue {
  @Action('removeToast') removeToast
  @Getter('getContentfulTcoModel') contentfulTcoModel

  @Prop()
  toast!: ToastMessage

  dkfdsToast: any

  closed = false
  defaultTimeoutDuration = 5000

  mounted () : void {
    if (this.toast && this.toast.id) {
      this.dkfdsToast = new DKFDS.Toast(document.getElementById(this.toast.id))
      this.dkfdsToast.show()
      if (this.toast.autoDismiss) {
        if (this.toast.autoDismissTimeout) {
          setTimeout(() => this.closeToast(), this.toast.autoDismissTimeout)
        } else {
          setTimeout(() => this.closeToast(), this.defaultTimeoutDuration)
        }
      }
    }
  }

  unmounted (): void {
    if (this.toast && !this.closed) {
      this.removeToast(this.toast.id)
    }
  }

  closeToast (): void {
    this.closed = true
    this.removeToast(this.toast.id)
  }

  get headline (): string {
    if (!this.toast) return ''
    if (this.toast.headline) return this.toast.headline

    // default fallback headlines hvis der ikke er angivet en ekplicit headline
    if (this.toast?.type === ToastType.SUCCESS) {
      return this.contentfulTcoModel.findKlarTekst('tco.kvittering-notifikation.overskrift')
    } else if (this.toast?.type === ToastType.ERROR) {
      return this.contentfulTcoModel.findKlarTekst('tco.fejl-notifikation.overskrift')
    } else if (this.toast?.type === ToastType.WARNING) {
      return this.contentfulTcoModel.findKlarTekst('tco.advarsel-notifikation.overskrift')
    } else {
      return this.contentfulTcoModel.findKlarTekst('tco.info-notifikation.overskrift')
    }
  }

  getToastTypeClass (toast: ToastMessage): string {
    if (!toast) return ''

    let toastType = ''
    if (toast?.type === ToastType.SUCCESS) {
      toastType = 'toast-success'
    } else if (toast?.type === ToastType.ERROR) {
      toastType = 'toast-error'
    } else if (toast?.type === ToastType.WARNING) {
      toastType = 'toast-warning'
    } else {
      toastType = 'toast-info'
    }

    return toastType
  }
}
