
import { Options, Vue } from 'vue-class-component'
import axios from '@/services/axios-instance'
import Opsummering from '@/components/Opsummering.vue'
import OGFormularModel from './models/OGFormularModel.vue'
import KlarTekst from '@/components/KlarTekst.vue'
import RichTekst from '@/components/RichTekst.vue'
import { Action, Getter } from 'vuex-class'
import ToastUtil from '@/ToastUtil'

@Options({
  components: {
    KlarTekst,
    Opsummering,
    RichTekst
  }
})
export default class OGOpsummeringStandalone extends Vue {
  @Getter('getContentfulTcoModel') contentfulTcoModel
  @Action('addToast') addToast

  id = ''
  ogInformation: OGFormularModel = new OGFormularModel()

  overskrift = ''
  underoverskrift = ''
  beskrivelse
  firstPage = true

  created () : void {
    this.overskrift = this.contentfulTcoModel.findKlarTekst('tco.blanket.og-blanket.opsummering.overskrift')
    this.underoverskrift = this.contentfulTcoModel.findKlarTekst('tco.blanket.og-blanket.opsummering.underoverskrift')
    const beskrivelse = this.contentfulTcoModel.findRichTekst('tco.blanket.og-blanket.opsummering.beskrivelse')
    if (beskrivelse && beskrivelse.json) {
      this.beskrivelse = this.contentfulTcoModel.renderRichTekstFromJson(JSON.stringify(beskrivelse.json))
    }
    this.firstPage = history.state.back.toString().startsWith('/auth')

    this.id = this.$route.params.id as string
    if (this.id) {
      axios.post(process.env.VUE_APP_DIRIGENT_URL + '/web/form/getForm/' + this.id, '', { headers: { 'content-type': 'application/json' } })
        .then(
          response => {
            this.ogInformation = response.data
          })
        .catch(error => this.addToast(ToastUtil.createToastForErrorResponse(error, this.contentfulTcoModel)))
    }
  }
}
