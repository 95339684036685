import { User } from 'oidc-client'
import store from '.'
import { publicPath } from '@/../vue.config'

export const loginEvent = async (user: User): Promise<void> => {
  store.dispatch('login', user)
}

export const logoutEvent = async (): Promise<void> => {
  store.dispatch('logout')
}

export const accessTokenExpiringEvent = () => {
  store.dispatch('accessTokenExpiring')
}

export const accessTokenExpiredEvent = () => {
  const authenticated = store.getters['oidcStore/oidcIsAuthenticated']
  store.dispatch('accessTokenExpired')
  logoutEvent().then(() => {
    if (authenticated) {
      /**
       * hack til at redirect til forsiden når access token udløber og brugeren
       * automatisk logges ud. Af en eller anden grund kan vi ikke import og bruge
       * vue routeren her - hvis man forsøger det så smides der fejl når routeren forsøges at initialiseres
       * ved initielle startup af applikationen. Det er formentlig relateret til en cirkulær dependency
       * fordi initialisering af vue routeren er afhængig af vuex storen (da vi bruger vuex-oidc)
       * så hvis store også gøres afhængig af routeren via import så laves der en cirkulær afhængighed.
       */
      window.location.href = publicPath
    }
  })
}
