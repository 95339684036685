
import { Vue } from 'vue-class-component'
import axios from '@/services/axios-instance'

export default class Integration extends Vue {
  msg = 'no data yet'

  mounted (): void {
    axios
      .get<string>(process.env.VUE_APP_DIRIGENT_URL + '/')
      .then(response => (this.msg = response.data))
      .catch(error => console.error(error))
  }
}
