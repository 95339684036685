
import { Options, Vue } from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import TGFormularModel from './models/TGFormularModel.vue'
import DKFDS from 'dkfds'
import FeltModel from '@/components/models/FeltModel.vue'
import { FeltType } from '@/components/Const'
import KlarTekst from '@/components/KlarTekst.vue'

@Options({
  components: {
    KlarTekst
  },
  data () {
    return {
      FeltType
    }
  }
})
export default class TGDetailsEntry extends Vue {
  @Prop({ type: TGFormularModel })
  formular? : TGFormularModel

  @Prop({ type: String })
  id

  @Prop({ type: Boolean })
  printMode = false

  mounted () : void {
    // initialize accordion
    DKFDS.init() // NOSONAR
  }

  lavVistNavn (felt : FeltModel) : string {
    switch (felt.felttype) {
      case FeltType.TEKSTFELT_TAL:
        return this.formatNumeric(felt.vaerdi)
      case FeltType.RADIO_JA_NEJ:
        return felt.vaerdi === 'true' ? 'Ja' : 'Nej'
      case FeltType.VALGLISTE:
        return felt.valgmuligheder[felt.vaerdi]
      case FeltType.STATIC_TEKST:
        return felt.displayVaerdi || felt.vaerdi
      default:
        return felt.vaerdi
    }
  }

  formatNumeric (value : string) : string {
    if (value == null) {
      return ''
    }
    return value.replaceAll('.', ',')
  }
}
