import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "accordion accordion-unbordered accordion-multiselectable"
}
const _hoisted_2 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DokumentVisningTG = _resolveComponent("DokumentVisningTG")!
  const _component_DokumentVisning = _resolveComponent("DokumentVisning")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (this.dokumenter.length > 0)
      ? (_openBlock(), _createElementBlock("ul", _hoisted_1, [
          (_ctx.tg)
            ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(this.dokumenter, (dokument) => {
                return (_openBlock(), _createBlock(_component_DokumentVisningTG, {
                  key: dokument.id,
                  dokument: dokument,
                  tab: _ctx.status
                }, null, 8, ["dokument", "tab"]))
              }), 128))
            : (_openBlock(true), _createElementBlock(_Fragment, { key: 1 }, _renderList(this.dokumenter, (dokument) => {
                return (_openBlock(), _createBlock(_component_DokumentVisning, {
                  key: dokument.id,
                  dokument: dokument
                }, null, 8, ["dokument"]))
              }), 128))
        ]))
      : _createCommentVNode("", true),
    (_ctx.dokumenter.length === 0)
      ? (_openBlock(), _createElementBlock("p", _hoisted_2, _toDisplayString(_ctx.tomListeBesked), 1))
      : _createCommentVNode("", true)
  ], 64))
}