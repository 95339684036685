/* eslint-disable semi */
import { Log, UserManagerSettings, WebStorageStateStore } from 'oidc-client';

export default class OidcConfig {
  oidcSettings: UserManagerSettings;

  constructor (options: {
    clientId: string;
    authority: string;
    metadataUrl: string;
    redirectUri: string;
    postLogoutRedirectUri: string;
  }) {
    Log.logger = console;
    Log.level = Log.DEBUG;

    this.oidcSettings = {
      userStore: new WebStorageStateStore({ store: window.sessionStorage }),
      authority: options.authority,
      client_id: options.clientId,
      redirect_uri: options.redirectUri,
      response_type: 'code',
      scope: 'openid',
      post_logout_redirect_uri: options.postLogoutRedirectUri,
      loadUserInfo: true,
      metadataUrl: options.metadataUrl,
      automaticSilentRenew: false,
      monitorSession: false,
      accessTokenExpiringNotificationTime: 5 * 60, // notification 5 minutes before expiration
      extraQueryParams: {
        claims: `{"userinfo":{
          "name":null,
          "dk:gov:cpr":null,
          "dk:gov:cvr":null,
          "dk:gov:org_name":null,
          "dk:gov:privileges":null,
          "dk:gov:virk:roles":null
        }
      }`
      }
    }
  }
}

const options = {
  clientId: process.env.VUE_APP_OAUTH_CLIENTID,
  authority: process.env.VUE_APP_OAUTH_AUTHORITY,
  metadataUrl: process.env.VUE_APP_OAUTH_METADATAURL,
  redirectUri: process.env.VUE_APP_OAUTH_REDIRECTURI,
  postLogoutRedirectUri: process.env.VUE_APP_OAUTH_POSTLOGOUTREDIRECTURI
}

export const oidcConfig = new OidcConfig(options)
