
import { Options, Vue } from 'vue-class-component'
import * as DKFDS from 'dkfds'
import { oidcConfig } from '@/config/oidc'
import { Prop } from 'vue-property-decorator'
import MicroModal from 'micromodal'
import KlarTekst from '@/components/KlarTekst.vue'
import ModalUtil from '@/components/util/ModalUtil'

@Options({
  components: {
    KlarTekst
  }
})
export default class InviterTilbudsgiver extends Vue {
  url: string | undefined

  @Prop()
  modalId

  @Prop({ type: Number })
  ogId

  created () : void {
    const baseurl = oidcConfig.oidcSettings.post_logout_redirect_uri // should this be window.location.origin instead?
    if (baseurl) {
      this.url = baseurl + (!baseurl.endsWith('/') ? '/' : '') + 'invitation-tilbud/' + this.ogId
    }
  }

  mounted () : void {
    MicroModal.init({
      onShow: modal => ModalUtil.dkfdsZIndexHack(true),
      onClose: modal => ModalUtil.dkfdsZIndexHack(false)
    })
  }

  copyToClipboard () : void {
    const el = document.getElementById('copy-text-' + this.modalId) as HTMLInputElement
    if (el) {
      el.focus()
      el.select()
    }

    if (!navigator.clipboard) {
      document.execCommand('copy')
    } else {
      const text = el.value
      navigator.clipboard.writeText(text)
    }
  }
}
