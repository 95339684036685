import TCOEvaluationModel from '@/components/models/evaluation/TCOEvaluationModel.vue'

export default class OGEvaluateUtil {
  /**
   * Create toast message for error response from backend. Message property is used as error text if present otherwise
   * generic error response for http status code is used
   * @param errorResponse
   * @param contentfulTcoModel
   * @returns
   */
  static setEvaluationLabels (evaluation: InstanceType<typeof TCOEvaluationModel>, sortOfferProduct = 'offer') : void {
    if (!evaluation || !evaluation.companies || evaluation.companies.length === 0) return

    let lowestScore
    let highestScore
    let lowestCO2
    let highestCO2

    if (sortOfferProduct === 'offer') {
      // find lowest average tco score
      lowestScore = evaluation.companies.reduce(function (prev, curr) {
        return +prev.averageScore.tcoTotalsTotalAmount < +curr.averageScore.tcoTotalsTotalAmount ? prev : curr
      })
      evaluation.companies
        .filter(company => company.averageScore.tcoTotalsTotalAmount === lowestScore.averageScore.tcoTotalsTotalAmount)
        .forEach(company => { company.showLowestScoreLabel = true })

      // find highest average tco score
      highestScore = evaluation.companies.reduce(function (prev, curr) {
        return +prev.averageScore.tcoTotalsTotalAmount > +curr.averageScore.tcoTotalsTotalAmount ? prev : curr
      })
      evaluation.companies
        .filter(company => company.averageScore.tcoTotalsTotalAmount === highestScore.averageScore.tcoTotalsTotalAmount)
        .forEach(company => { company.showHighestScoreLabel = true })

      // find lowest average co2
      lowestCO2 = evaluation.companies.reduce(function (prev, curr) {
        return +prev.averageScore.tcoTotalsCO2EmissionQuantity < +curr.averageScore.tcoTotalsCO2EmissionQuantity ? prev : curr
      })
      evaluation.companies
        .filter(company => company.averageScore.tcoTotalsCO2EmissionQuantity === lowestCO2.averageScore.tcoTotalsCO2EmissionQuantity)
        .forEach(company => { company.showLowestCO2Label = true })

      // find highest average co2
      highestCO2 = evaluation.companies.reduce(function (prev, curr) {
        return +prev.averageScore.tcoTotalsCO2EmissionQuantity > +curr.averageScore.tcoTotalsCO2EmissionQuantity ? prev : curr
      })
      evaluation.companies
        .filter(company => company.averageScore.tcoTotalsCO2EmissionQuantity === highestCO2.averageScore.tcoTotalsCO2EmissionQuantity)
        .forEach(company => { company.showHighestCO2Label = true })
    } else {
      // find lowest products tco score
      lowestScore = evaluation.companies.reduce(function (prev, curr) {
        return +prev.minProductScore.tcoTotalsTotalAmount < +curr.minProductScore.tcoTotalsTotalAmount ? prev : curr
      })
      evaluation.companies
        .filter(company => company.minProductScore.tcoTotalsTotalAmount === lowestScore.minProductScore.tcoTotalsTotalAmount)
        .forEach(company => { company.showLowestScoreLabel = true })

      // find highest products tco score
      highestScore = evaluation.companies.reduce(function (prev, curr) {
        return +prev.maxProductScore.tcoTotalsTotalAmount > +curr.averageScore.tcoTotalsTotalAmount ? prev : curr
      })
      evaluation.companies
        .filter(company => company.maxProductScore.tcoTotalsTotalAmount === highestScore.maxProductScore.tcoTotalsTotalAmount)
        .forEach(company => { company.showHighestScoreLabel = true })

      // find lowest products co2
      lowestCO2 = evaluation.companies.reduce(function (prev, curr) {
        return +prev.minProductScore.tcoTotalsCO2EmissionQuantity < +curr.minProductScore.tcoTotalsCO2EmissionQuantity ? prev : curr
      })
      evaluation.companies
        .filter(company => company.minProductScore.tcoTotalsCO2EmissionQuantity === lowestCO2.minProductScore.tcoTotalsCO2EmissionQuantity)
        .forEach(company => { company.showLowestCO2Label = true })

      // find highest products co2
      highestCO2 = evaluation.companies.reduce(function (prev, curr) {
        return +prev.maxProductScore.tcoTotalsCO2EmissionQuantity > +curr.maxProductScore.tcoTotalsCO2EmissionQuantity ? prev : curr
      })
      evaluation.companies
        .filter(company => company.maxProductScore.tcoTotalsCO2EmissionQuantity === highestCO2.maxProductScore.tcoTotalsCO2EmissionQuantity)
        .forEach(company => { company.showHighestCO2Label = true })
    }
  }
}
