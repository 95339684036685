export default class DateUtil {
  // formaterer en dato (uden tid og tidszone) fra et xml felt
  static formatDate (value: string): string {
    if (!value) return ''
    const date = new Date(value)
    if (date) {
      const month = date.getMonth() + 1
      const day = date.getDate()
      const year = date.getFullYear()
      return [this.padZeroes(day, 2), this.padZeroes(month, 2), this.padZeroes(year, 4)].join('/')
    } else {
      return ''
    }
  }

  private static padZeroes (value: number, length: number): string {
    return `0000${value}`.slice(-length)
  }

  // Format as DD/MM/YYYY HH:mm
  static formatDateTime (value: Date): string {
    const day = ('0' + value.getDate()).slice(-2)
    const month = ('0' + (value.getMonth() + 1)).slice(-2)
    const year = value.getFullYear()
    const hour = ('0' + value.getHours()).slice(-2)
    const minutes = ('0' + value.getMinutes()).slice(-2)
    return `${day}/${month}/${year} ${hour}:${minutes}`
  }

  static getYear (value: string): number | null {
    if (!value) return null
    const date = new Date(value)
    return date.getFullYear()
  }
}
