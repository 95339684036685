import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SideHoved = _resolveComponent("SideHoved")!
  const _component_Ikoner = _resolveComponent("Ikoner")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_SideFod = _resolveComponent("SideFod")!
  const _component_ToastMessages = _resolveComponent("ToastMessages")!

  return (!_ctx.isFetching)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_SideHoved),
        _createVNode(_component_Ikoner),
        _createVNode(_component_router_view, {
          key: _ctx.$route.path
        }),
        _createVNode(_component_SideFod),
        _createVNode(_component_ToastMessages)
      ]))
    : _createCommentVNode("", true)
}