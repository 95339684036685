export const enum ToastType {
    SUCCESS = 'SUCCESS',
    INFO = 'INFO',
    ERROR = 'ERROR',
    WARNING = 'WARNING'
}

export interface ToastMessage {
    id?: string;
    headline?: string;
    text: string;
    autoDismiss?: boolean;
    autoDismissTimeout?: number;
    type: ToastType;
}
