
import { Options, Vue } from 'vue-class-component'
import { ChartColors } from '@/components/Const'
import { Prop, Watch } from 'vue-property-decorator'
import DKFDS from 'dkfds'
import TCOEvaluationModel from '@/components/models/evaluation/TCOEvaluationModel.vue'
import TCOScoreProductComparisonChart from '@/components/charts/TCOScoreProductComparisonChart.vue'
import CO2ComparisonChart from '@/components/charts/CO2ComparisonChart.vue'
import KlarTekst from '@/components/KlarTekst.vue'
import RichTekst from '@/components/RichTekst.vue'
import { Getter } from 'vuex-class'
import ProductVariantEvaluationModel from './models/evaluation/ProductVariantEvaluationModel.vue'

@Options({
  components: {
    TCOScoreProductComparisonChart,
    CO2ComparisonChart,
    KlarTekst,
    RichTekst
  },
  data () {
    return {
      ChartColors
    }
  },
  computed: {
    filteredCompanies () {
      return this.evaluation.companies.filter(x => !this.printTGId || this.printTGId === x.blanketId)
    },
    accordionsOpen () {
      return this.printMode && !this.printAccordionsClosed
    },
    cssVars () {
      return {
        '--co2-color': ChartColors.CURRENT_CO2_TOTAL,
        '--tco-color': ChartColors.CURRENT_SERVICE_TOTAL
      }
    }
  }
})
export default class OGEvaluateEntry extends Vue {
  emitter = require('tiny-emitter/instance')

  @Getter('getContentfulTcoModel') contentfulTcoModel

  @Prop({ type: TCOEvaluationModel })
  evaluation = new TCOEvaluationModel()

  @Prop({ type: Boolean })
  printMode = false

  @Prop({ type: Boolean })
  printAccordionsClosed = false

  @Prop({ type: String })
  printTGId?

  // the co2IndexMapSorted is sorted by lowest co2 value, the key is the product index
  co2IndexMapSorted: Map<number, number>[] | null = []

  mounted () : void {
    // initialize accordion
    DKFDS.init() // NOSONAR

    this.generateGraphDatasets()
  }

  @Watch('evaluation')
  onPropertyChanged () : void {
    this.generateGraphDatasets()
  }

  formatNumeric (amount: string, suffix: string, co2?: boolean) : string {
    if (amount == null || amount === '') {
      return '-'
    } else {
      const value = (+amount).toLocaleString('da-DK', { minimumFractionDigits: co2 ? 2 : 0, maximumFractionDigits: co2 ? 2 : 0 })
      return value.toString() + ' ' + suffix
    }
  }

  getLowestCo2ProductIndex (products: ProductVariantEvaluationModel[]) : number {
    let lowestIndex = 0
    for (var i = 1; i < products.length; i++) {
      if (products[i].score.tcoTotalsCO2EmissionQuantity < products[lowestIndex].score.tcoTotalsCO2EmissionQuantity) {
        lowestIndex = i
      } else if (products[i].score.tcoTotalsCO2EmissionQuantity === products[lowestIndex].score.tcoTotalsCO2EmissionQuantity) {
        if (products[i].score.tcoTotalsTotalAmount < products[lowestIndex].score.tcoTotalsTotalAmount) {
          lowestIndex = i
        } else if (products[i].score.tcoTotalsTotalAmount === products[lowestIndex].score.tcoTotalsTotalAmount) {
          if (products[i].name.localeCompare(products[lowestIndex].name) === -1) {
            lowestIndex = i
          }
        }
      }
    }
    return lowestIndex
  }

  getHighestCo2ProductIndex (products: ProductVariantEvaluationModel[]) : number {
    let highestIndex = 0
    for (var i = 1; i < products.length; i++) {
      if (products[i].score.tcoTotalsCO2EmissionQuantity > products[highestIndex].score.tcoTotalsCO2EmissionQuantity) {
        highestIndex = i
      } else if (products[i].score.tcoTotalsCO2EmissionQuantity === products[highestIndex].score.tcoTotalsCO2EmissionQuantity) {
        if (products[i].score.tcoTotalsTotalAmount > products[highestIndex].score.tcoTotalsTotalAmount) {
          highestIndex = i
        } else if (products[i].score.tcoTotalsTotalAmount === products[highestIndex].score.tcoTotalsTotalAmount) {
          if (products[i].name.localeCompare(products[highestIndex].name) === -1) {
            highestIndex = i
          }
        }
      }
    }
    return highestIndex
  }

  getOtherProductsWithMatchingCo2EmissionToIndex (products: ProductVariantEvaluationModel[], index) : ProductVariantEvaluationModel[] {
    return products.filter(p => p.score.tcoTotalsCO2EmissionQuantity === products[index].score.tcoTotalsCO2EmissionQuantity)
      .filter(p => p.name !== products[index].name)
  }

  getLowestTotalCostsProductIndex (products: ProductVariantEvaluationModel[]) : number {
    let lowestIndex = 0
    for (var i = 1; i < products.length; i++) {
      if (products[i].score.tcoTotalsTotalAmount < products[lowestIndex].score.tcoTotalsTotalAmount) {
        lowestIndex = i
      } else if (products[i].score.tcoTotalsTotalAmount === products[lowestIndex].score.tcoTotalsTotalAmount) {
        if (products[i].score.tcoTotalsCO2EmissionQuantity < products[lowestIndex].score.tcoTotalsCO2EmissionQuantity) {
          lowestIndex = i
        } else if (products[i].score.tcoTotalsCO2EmissionQuantity === products[lowestIndex].score.tcoTotalsCO2EmissionQuantity) {
          if (products[i].name.localeCompare(products[lowestIndex].name) === -1) {
            lowestIndex = i
          }
        }
      }
    }
    return lowestIndex
  }

  getOtherProductsWithMatchingTotalCostsToIndex (products: ProductVariantEvaluationModel[], index) : ProductVariantEvaluationModel[] {
    return products.filter(p => p.score.tcoTotalsTotalAmount === products[index].score.tcoTotalsTotalAmount)
      .filter(p => p.name !== products[index].name)
  }

  generateGraphDatasets () : void {
    for (let i = 0; i < this.evaluation.companies.length; i++) {
      const products = this.evaluation.companies[i].productVariants
      const lastIndex = products.length - 1
      const lowestCo2ProductIndex = this.getLowestCo2ProductIndex(products)
      const highestCo2ProductIndex = this.getHighestCo2ProductIndex(products)
      for (const p of products) {
        p.co2Data = {
          labels: [
            // best
            products[lowestCo2ProductIndex].name,
            // current
            p.name,
            // worst
            products[highestCo2ProductIndex].name
          ],
          datasets: [
            {
              backgroundColor: [ChartColors.OTHER_CO2_TOTAL, ChartColors.CURRENT_CO2_TOTAL, ChartColors.OTHER_CO2_TOTAL],
              data: [products[lowestCo2ProductIndex].score.tcoTotalsCO2EmissionQuantity, p.score.tcoTotalsCO2EmissionQuantity, products[highestCo2ProductIndex].score.tcoTotalsCO2EmissionQuantity]
            }
          ]
        }
        p.dataCollection = {
          labels: [
            // first - best
            products[0].name,
            // current
            p.name,
            // last - worst
            products[lastIndex].name
          ],
          datasets: [
            {
              label: this.contentfulTcoModel.findKlarTekst('tco.evaluering.label.resultat3'),
              backgroundColor: [ChartColors.OTHER_EFTERFORBRUG_TOTAL, ChartColors.CURRENT_EFTERFORBRUG_TOTAL, ChartColors.OTHER_EFTERFORBRUG_TOTAL],
              data: [products[0].score.tcoTotalsExitAmount, p.score.tcoTotalsExitAmount, products[lastIndex].score.tcoTotalsExitAmount]
            },
            {
              label: this.contentfulTcoModel.findKlarTekst('tco.evaluering.label.resultat1'),
              backgroundColor: [ChartColors.OTHER_DRIFTOMKOSTNINGER_TOTAL, ChartColors.CURRENT_DRIFTOMKOSTNINGER_TOTAL, ChartColors.OTHER_DRIFTOMKOSTNINGER_TOTAL],
              data: [products[0].score.tcoTotalsOperationalAmount, p.score.tcoTotalsOperationalAmount, products[lastIndex].score.tcoTotalsOperationalAmount]
            },
            {
              label: this.contentfulTcoModel.findKlarTekst('tco.evaluering.label.resultat2'),
              backgroundColor: [ChartColors.OTHER_SERVICE_TOTAL, ChartColors.CURRENT_SERVICE_TOTAL, ChartColors.OTHER_SERVICE_TOTAL],
              data: [products[0].score.tcoTotalsServiceAmount, p.score.tcoTotalsServiceAmount, products[lastIndex].score.tcoTotalsServiceAmount]
            },
            {
              label: this.contentfulTcoModel.findKlarTekst('tco.evaluering.label.resultat0'),
              backgroundColor: [ChartColors.OTHER_INVESTERINGSOMKOSTNINGER_TOTAL, ChartColors.CURRENT_INVERSTERINGSOMKOSTNINGER_TOTAL, ChartColors.OTHER_INVESTERINGSOMKOSTNINGER_TOTAL],
              data: [products[0].score.tcoTotalsInitialAmount, p.score.tcoTotalsInitialAmount, products[lastIndex].score.tcoTotalsInitialAmount]
            },
            {
              label: this.contentfulTcoModel.findKlarTekst('tco.evaluering.label.resultat4'),
              backgroundColor: [ChartColors.OTHER_SAMFUNDSOEKONOMISKE_TOTAL, ChartColors.CURRENT_SAMFUNDSOEKONOMISKE_TOTAL, ChartColors.OTHER_SAMFUNDSOEKONOMISKE_TOTAL],
              data: [products[0].score.tcoTotalsEnvironmentalAmount, p.score.tcoTotalsEnvironmentalAmount, products[lastIndex].score.tcoTotalsEnvironmentalAmount]
            }
          ]
        }
      }
    }
  }

  printEvaluering (ogId: string, tgId?: string) : void {
    const route = this.$router.resolve({ path: '/evalueringOG/print/' + ogId, query: { tg_id: tgId } })
    window.open(route.href, '_blank')
  }

  keyHasValue (evaluate: string, res: string) : boolean {
    if ((this.contentfulTcoModel.findKlarTekst(evaluate) !== evaluate && this.contentfulTcoModel.findKlarTekst(evaluate).length > 0) ||
      (this.contentfulTcoModel.findKlarTekst(res) !== res && this.contentfulTcoModel.findKlarTekst(res).length > 0)) {
      return true
    } else {
      return false
    }
  }
}
