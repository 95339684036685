/* eslint-disable semi */
import axios, { AxiosInstance } from 'axios'
import store from '@/components/store'
import router from '@/router';
import { timezone } from '@/components/Const.ts'

const axiosInstance: AxiosInstance = axios.create() // TODO: inject api baseurl here?

axiosInstance.interceptors.request.use(
  (req) => {
    // TODO: only add access token if request url is TCO api (just in case)?
    const accessToken = store.getters['oidcStore/oidcAccessToken'];
    if (accessToken) {
      req.headers.Authorization = 'Bearer ' + accessToken;
    }
    req.headers.Timezone = timezone
    return req;
  },
  (error) => {
    return Promise.reject(error);
  }
)

axiosInstance.interceptors.response.use(
  (resp) => {
    return resp;
  },
  (error) => {
    if (error && error.response) {
      if (error.response.status === 401) {
        store.dispatch('logout');
        store.dispatch('oidcStore/authenticateOidc');
      } else if (error.response.status === 403) {
        // TODO: for some reason using the router here causes the Integration component to become invalid - resulting in 'invalid route component' from the vue router when accessing
        // an url with this component.
        // Seems that the component is undefined when imported in the router component for some reason - not sure why
        // Other components seem to work fine. Fix this once we have a proper front page or access denied page.
        // router.push('/')
      }
    }
    return Promise.reject(error);
  }
);

export default axiosInstance;
