
import { Options, Vue } from 'vue-class-component'
import { Prop, Watch } from 'vue-property-decorator'
import TGFormularModel from '@/components/models/TGFormularModel.vue'
import * as DKFDS from 'dkfds'
import { ChartColors } from '@/components/Const'
import TCOScoreProductComparisonChart from '@/components/charts/TCOScoreProductComparisonChart.vue'
import CO2ComparisonChart from '@/components/charts/CO2ComparisonChart.vue'
import { ChartData } from 'chart.js'
import KlarTekst from '@/components/KlarTekst.vue'
import { Getter } from 'vuex-class'

@Options({
  components: {
    TCOScoreProductComparisonChart,
    CO2ComparisonChart,
    KlarTekst
  },
  data () {
    return {
      ChartColors
    }
  },
  computed: {
    cssVars () {
      return {
        '--co2-color': ChartColors.CURRENT_CO2_TOTAL,
        '--tco-color': ChartColors.CURRENT_SERVICE_TOTAL
      }
    }
  }
})
export default class TGResultat extends Vue {
  emitter = require('tiny-emitter/instance')
  dataCollection: ChartData[] | null = null
  co2Data: ChartData[] | null = null

  @Getter('getContentfulTcoModel') contentfulTcoModel

  @Prop({ type: TGFormularModel })
  formular! : TGFormularModel

  @Prop({ type: Boolean })
  standalone = false

  @Prop({ type: Boolean })
  printMode = false

  initialized = false
  // the co2IndexMapSorted is sorted by lowest co2 value, the key is the product index
  co2IndexMapSorted = new Map<number, number>();

  eventSubscriptions: Map<string, ((arg1: any) => void)[]> = new Map()

  currencyUnit = ''
  currencyPerTimeUnit = ''
  co2Unit = ''

  @Watch('formular', { deep: true })
  onPropertyChanged (value: TGFormularModel) : void {
    this.onDataReceived()
  }

  created () : void {
    this.currencyUnit = this.contentfulTcoModel.findKlarTekst('tco.blanket.tg-blanket.resultat.valuta.enhed')
    this.currencyPerTimeUnit = this.contentfulTcoModel.findKlarTekst('tco.blanket.tg-blanket.resultat.valuta-per-tid.enhed')
    this.co2Unit = this.contentfulTcoModel.findKlarTekst('tco.blanket.tg-blanket.resultat.co2.enhed')
  }

  mounted () : void {
    if (this.formular !== null && this.formular.products.length > 0 && this.formular.products[0].score) {
      this.onDataReceived()
    }
    // initialize accordion
    DKFDS.init() // NOSONAR

    // DKFDS.init() resets the selected tab - so we manually set the correct tab here
    const produktTab = document.getElementById('tab_udbudsinfo')
    if (produktTab) {
      produktTab.click()
    }
  }

  rediger (productIndex : number) : void {
    this.emitter.emit('rediger', productIndex)
  }

  deleteProduct (productIndex : number) : void {
    this.emitter.emit('deleteProduct', productIndex)
    this.calculateCO2Order()
    this.generateGraphDatasets()
  }

  formatNumeric (amount: string, suffix: string, co2?: boolean) : string {
    if (amount == null || amount === '') {
      return '-'
    } else {
      const value = (+amount).toLocaleString('da-DK', { minimumFractionDigits: co2 ? 2 : 0, maximumFractionDigits: co2 ? 2 : 0 })
      return value.toString() + ' ' + suffix
    }
  }

  calculateCO2Order () : void {
    // map of k:product index, v: co2 value
    const co2IndexMap = new Map<number, number>()
    this.formular.products.forEach((p, index) => co2IndexMap.set(index, +p.score.tcoTotalsCO2EmissionQuantity))
    this.co2IndexMapSorted = new Map([...co2IndexMap.entries()].sort((a, b) => a[1] - b[1]))
  }

  // this function returns the position in the top list of CO2 values for the product with input index
  // the co2IndexMapSorted is sorted by lowest co2 value, the key is the product index
  getCo2OrderForProductIndex (index : number) : number {
    return Array.from(this.co2IndexMapSorted.keys()).indexOf(index)
  }

  getLowestCo2ProductIndex () : number {
    return Array.from(this.co2IndexMapSorted.keys())[0]
  }

  getHighestCo2ProductIndex () : number {
    return Array.from(this.co2IndexMapSorted.keys())[this.co2IndexMapSorted.size - 1]
  }

  onDataReceived () : void {
    this.initialized = false
    // sort products on tco score ascending
    this.formular.products.sort((a, b) => {
      return +a.score.tcoTotalsTotalAmount - +b.score.tcoTotalsTotalAmount
    })

    this.calculateCO2Order()

    this.generateGraphDatasets()

    this.initialized = true
  }

  generateGraphDatasets () : void {
    this.dataCollection = []
    this.co2Data = []
    const products = this.formular.products
    const lastIndex = products.length - 1
    const lowestCo2ProductIndex = this.getLowestCo2ProductIndex()
    const highestCo2ProductIndex = this.getHighestCo2ProductIndex()
    for (const p of products) {
      const co2ChartData : ChartData = {
        labels: [
          // best
          products[lowestCo2ProductIndex].formular.trin[1].felter[0].vaerdi,
          // current
          p.formular.trin[1].felter[0].vaerdi,
          // worst
          products[highestCo2ProductIndex].formular.trin[1].felter[0].vaerdi
        ],
        datasets: [
          {
            backgroundColor: [ChartColors.OTHER_CO2_TOTAL, ChartColors.CURRENT_CO2_TOTAL, ChartColors.OTHER_CO2_TOTAL],
            data: [products[lowestCo2ProductIndex].score.tcoTotalsCO2EmissionQuantity, p.score.tcoTotalsCO2EmissionQuantity, products[highestCo2ProductIndex].score.tcoTotalsCO2EmissionQuantity]
          }
        ]
      }
      this.co2Data.push(co2ChartData)

      const tcoChartData : ChartData = {
        labels: [
          // first - best
          products[0].formular.trin[1].felter[0].vaerdi,
          // current
          p.formular.trin[1].felter[0].vaerdi,
          // last - worst
          products[lastIndex].formular.trin[1].felter[0].vaerdi
        ],
        datasets: [
          {
            label: this.contentfulTcoModel.findKlarTekst('tco.resultat.label.resultat3'),
            backgroundColor: [ChartColors.OTHER_EFTERFORBRUG_TOTAL, ChartColors.CURRENT_EFTERFORBRUG_TOTAL, ChartColors.OTHER_EFTERFORBRUG_TOTAL],
            data: [products[0].score.tcoTotalsExitAmount, p.score.tcoTotalsExitAmount, products[lastIndex].score.tcoTotalsExitAmount]
          },
          {
            label: this.contentfulTcoModel.findKlarTekst('tco.resultat.label.resultat1'),
            backgroundColor: [ChartColors.OTHER_DRIFTOMKOSTNINGER_TOTAL, ChartColors.CURRENT_DRIFTOMKOSTNINGER_TOTAL, ChartColors.OTHER_DRIFTOMKOSTNINGER_TOTAL],
            data: [products[0].score.tcoTotalsOperationalAmount, p.score.tcoTotalsOperationalAmount, products[lastIndex].score.tcoTotalsOperationalAmount]
          },
          {
            label: this.contentfulTcoModel.findKlarTekst('tco.resultat.label.resultat2'),
            backgroundColor: [ChartColors.OTHER_SERVICE_TOTAL, ChartColors.CURRENT_SERVICE_TOTAL, ChartColors.OTHER_SERVICE_TOTAL],
            data: [products[0].score.tcoTotalsServiceAmount, p.score.tcoTotalsServiceAmount, products[lastIndex].score.tcoTotalsServiceAmount]
          },
          {
            label: this.contentfulTcoModel.findKlarTekst('tco.resultat.label.resultat0'),
            backgroundColor: [ChartColors.OTHER_INVESTERINGSOMKOSTNINGER_TOTAL, ChartColors.CURRENT_INVERSTERINGSOMKOSTNINGER_TOTAL, ChartColors.OTHER_INVESTERINGSOMKOSTNINGER_TOTAL],
            data: [products[0].score.tcoTotalsInitialAmount, p.score.tcoTotalsInitialAmount, products[lastIndex].score.tcoTotalsInitialAmount]
          },
          {
            label: this.contentfulTcoModel.findKlarTekst('tco.resultat.label.resultat4'),
            backgroundColor: [ChartColors.OTHER_SAMFUNDSOEKONOMISKE_TOTAL, ChartColors.CURRENT_SAMFUNDSOEKONOMISKE_TOTAL, ChartColors.OTHER_SAMFUNDSOEKONOMISKE_TOTAL],
            data: [products[0].score.tcoTotalsEnvironmentalAmount, p.score.tcoTotalsEnvironmentalAmount, products[lastIndex].score.tcoTotalsEnvironmentalAmount]
          }
        ]
      }
      this.dataCollection.push(tcoChartData)
    }
  }

  subscribeEvent (eventName: string, callbackFn: (arg1: any, arg2?: any, arg3?: any) => void): void {
    if (!this.eventSubscriptions.get(eventName)) {
      this.eventSubscriptions.set(eventName, [])
    }
    this.eventSubscriptions.get(eventName)!.push(callbackFn)
    this.emitter.on(eventName, callbackFn)
  }

  unmounted (): void {
    if (this.eventSubscriptions && this.eventSubscriptions.size > 0) {
      this.eventSubscriptions.forEach((callbacks, eventName) => {
        callbacks.forEach((eventHandler) => {
          this.emitter.off(eventName, eventHandler)
        })
      })
    }
    this.eventSubscriptions.clear()
  }
}
