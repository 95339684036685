

export default class TCOScoreModel {
  tcoTotalsInitialAmount
  tcoTotalsOperationalAmount
  tcoTotalsServiceAmount
  tcoTotalsExitAmount
  tcoTotalsEnvironmentalAmount
  tcoTotalsCO2EmissionQuantity
  tcoTotalsTotalAmount
  tcoTotalsTotalPerYearAmount
}
