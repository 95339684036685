
export default class FeltModel {
  enhed = ''
  vaerdi = ''
  displayVaerdi = ''
  felttype = 0
  maerkat = ''
  rettigheder = 0
  beskrivelse = ''
  hjaelpeTekst = ''
  tcoElementName = ''
  valideringsRegex? = ''
  valgmuligheder: Map<string, string> = new Map()
  specifikCssClass = ''
  erValid? = true
  minVaerdi = 0
  maksVaerdi = 0
  minDato = ''
  maksDato = ''
  valideringsFejlBesked = ''
  dokumentSorterEfter = 0
  erObligatorisk = false
  touched = false
  readOnly = false
  fejl: string[] = []

  jaRadioMaerkat? = ''
  jaRadioVaerdi?: string = 'true'
  jaInputMaerkat? = ''
  jaInputBeskrivelse? = ''
  jaInputTcoElementName? = ''
  jaInputVaerdi? = ''
  jaInputReadOnly? = false
  jaInputEnhed? = ''
  jaInputTouched = false

  nejRadioMaerkat? = ''
  nejRadioVaerdi?: string = 'false'
  nejInputMaerkat? = ''
  nejInputBeskrivelse? = ''
  nejInputTcoElementName? = ''
  nejInputVaerdi? = ''
  nejInputReadOnly? = false
  nejInputEnhed? = ''
  nejInputTouched = false
}
