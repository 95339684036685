
import { Options, Vue } from 'vue-class-component'
import OGFormularModel from '@/components/models/OGFormularModel.vue'
import { Prop } from 'vue-property-decorator'
import FeltModel from '@/components/models/FeltModel.vue'
import axios from '@/services/axios-instance'
import { FeltType } from '@/components/Const'
import DateUtil from '@/DateUtil'
import MetadataDokument from './models/MetadataDocument'
import KlarTekst from '@/components/KlarTekst.vue'
import { Action, Getter } from 'vuex-class'
import ToastUtil from '@/ToastUtil'
import FormularFeltUtil from './util/FormularFeltUtil'

@Options({
  components: {
    KlarTekst
  },
  data () {
    return {
      FeltType
    }
  }
})
export default class Opsummering extends Vue {
  emitter = require('tiny-emitter/instance')
  @Getter('getContentfulTcoModel') contentfulTcoModel
  @Action('addToast') addToast

  produktGrupper : MetadataDokument[] = []

  @Prop({ type: OGFormularModel })
  formular? : OGFormularModel

  @Prop({ editable: Boolean })
  editable? : boolean

  lavVistNavn (felt : FeltModel) : string {
    if (!felt.vaerdi) {
      return '-'
    }
    let name
    switch (felt.felttype) {
      case FeltType.TEKSTFELT_TAL:
        name = this.formatNumeric(felt.vaerdi)
        break
      case FeltType.RADIO_JA_NEJ:
        name = felt.vaerdi === 'true' ? 'Ja' : 'Nej'
        break
      case FeltType.VALGLISTE:
        name = felt.valgmuligheder[felt.vaerdi]
        break
      case FeltType.PRODUKTGRUPPEVÆLGER:
        name = this.formatProductGroup(felt.vaerdi)
        break
      case FeltType.DATO:
        name = this.formatDate(felt.vaerdi)
        break
      case FeltType.STATIC_TEKST:
        name = felt.displayVaerdi || felt.vaerdi
        break
      case FeltType.RADIO_JA_NEJ_MED_INPUT:
        name = this.isJaValgt(felt) ? felt.jaRadioMaerkat : felt.nejRadioMaerkat
        break
      default:
        name = felt.vaerdi
        break
    }

    if (!felt.enhed) {
      return name
    } else {
      return name + ' ' + felt.enhed
    }
  }

  isJaValgt (felt: FeltModel) : boolean {
    return felt.felttype === FeltType.RADIO_JA_NEJ_MED_INPUT && felt.vaerdi === felt.jaRadioVaerdi
  }

  genererDomId (tcoElementName : string) : string {
    if (tcoElementName != null) {
      return FormularFeltUtil.escapeForDomId(tcoElementName)
    }
    return ''
  }

  created () : void {
    if (this.formular) {
      this.hentProduktGrupper()
    }
  }

  redigerFelt (felt : FeltModel, trinNummer : number) : void {
    let elementName = felt.tcoElementName
    if (felt.felttype === FeltType.RADIO_JA_NEJ_MED_INPUT) {
      elementName = (this.isJaValgt(felt) ? felt.jaInputTcoElementName : felt.nejInputTcoElementName) || felt.tcoElementName
    }
    this.emitter.emit('redigerFelt', this.genererDomId(elementName), trinNummer)
  }

  hentProduktGrupper () : void {
    axios
      .get(process.env.VUE_APP_DIRIGENT_URL + '/web/form/alleAktiveMetadataList')
      .then(response => {
        this.produktGrupper = response.data
      }).catch(error => this.addToast(ToastUtil.createToastForErrorResponse(error, this.contentfulTcoModel)))
  }

  formatNumeric (value : string) : string {
    if (value == null) {
      return ''
    }
    return value.replaceAll('.', ',')
  }

  formatDate (value: string): string {
    return DateUtil.formatDate(value)
  }

  formatProductGroup (value: string): string {
    if (!value) return '-'
    const productGroup = this.produktGrupper.filter(p => p.id === value)[0]
    if (!productGroup) return '-'

    let formatted = productGroup.product_group_name
    if (productGroup.product_subgroup_name) {
      formatted += ' - ' + productGroup.product_subgroup_name
    }
    return formatted
  }
}
