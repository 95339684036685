
import { Options, Vue } from 'vue-class-component'
import Chart from '@/components/charts/Chart.vue'
import { ChartData, ChartOptions, Plugin } from 'chart.js'
import { Prop } from 'vue-property-decorator'
import { TCOComparisonPlugin, TCOLongLabelPlugin } from './ChartPlugins'

@Options({
  components: {
    Chart
  }
})
export default class CO2ComparisonChart extends Vue {
  @Prop()
  chartData: ChartData | null = null

  @Prop()
  chartTitle!: string

  @Prop()
  printMode = false;

  currentProductIndex = 1 // assuming this always contains at most 3 values
  chartOptions!: ChartOptions
  chartType = 'bar'

  chartPlugins: Plugin[] = [TCOComparisonPlugin, TCOLongLabelPlugin]

  created (): void {
    this.chartOptions = {
      layout: {
        padding: {
          top: 30
        }
      },
      datasets: {
        bar: {
          barPercentage: 0.5,
          categoryPercentage: 1
        }
      },
      plugins: {
        title: {
          display: this.chartTitle?.length > 0,
          text: this.chartTitle,
          font: {
            weight: 'bold',
            size: 18
          },
          padding: { bottom: 20 }
        },
        legend: {
          display: false
        },
        datalabels: {
          align: 'end',
          anchor: 'end',
          clamp: true,
          display: function (context) {
            // display laveste/højeste label over de respektive bars
            if (!context.chart.$tcoScore) return false
            const MIN_CO2_INDEX = context.chart.$tcoScore.minIndex
            const MAX_CO2_INDEX = context.chart.$tcoScore.maxIndex
            if (!context.chart.isDatasetVisible(context.datasetIndex)) return false
            if (context.dataIndex === MAX_CO2_INDEX || context.dataIndex === MIN_CO2_INDEX) {
              const maxVisibleDataset = context.chart.$tcoScore.maxVisibleDatasetIndex
              if (context.datasetIndex === maxVisibleDataset) {
                return true
              }
            }
            return false
          },
          formatter: function (value, context) {
            if (!context.chart.$tcoScore) return ''
            const MIN_CO2_INDEX = context.chart.$tcoScore.minIndex
            const MAX_CO2_INDEX = context.chart.$tcoScore.maxIndex
            if (context.dataIndex === MAX_CO2_INDEX) return 'Højeste CO\u2082e'
            else if (context.dataIndex === MIN_CO2_INDEX) return 'Laveste CO\u2082e'
            else return ''
          }
        },
        tooltip: {
          callbacks: {
            title: (tooltip) => {
              let label = ''
              if (tooltip.length) {
                label = tooltip[0].label
              }

              if (label.length > TCOLongLabelPlugin.longLabelThreshold) {
                // Hack: long labels are split into an array of strings in TCOLongLabelPlugin but in the tooltip
                // chartjs has converted the array to string so essentially it will be a commaseparated string
                // this will not work correctly for long labels which contain comma
                label = label.replaceAll(',', '')
              }
              return label
            }
          }
        }
      },
      responsive: true,
      maintainAspectRatio: true,
      aspectRatio: 1.333, // 4:3 aspect ratio
      interaction: {
        mode: 'index'
      },
      scales: {
        x: {
          ticks: {
            font: {
              size: 14,
              weight: 'bold'
            }
          }
        },
        y: {
          title: {
            text: 'Tons CO\u2082e',
            display: true,
            font: {
              size: 14,
              weight: 'bold'
            },
            padding: {
              bottom: 10
            }
          }
        }
      }
    }
  }
}
