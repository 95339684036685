import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-14c69da2"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  id: "formContainer",
  class: "container page-container"
}
const _hoisted_2 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_KlarTekst = _resolveComponent("KlarTekst")!
  const _component_TGResultat = _resolveComponent("TGResultat")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (!this.printMode)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("a", {
            id: "tilbageKnap",
            class: _normalizeClass('back-link d-none d-lg-inline-block'),
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$router.go(-1)))
          }, [
            _createVNode(_component_KlarTekst, { "tekst-noegle": "tco.tg-resultat.tilbage" })
          ])
        ]))
      : _createCommentVNode("", true),
    (this.formular.products.length > 0)
      ? (_openBlock(), _createBlock(_component_TGResultat, {
          key: 1,
          formular: this.formular,
          standalone: true,
          printMode: this.printMode
        }, null, 8, ["formular", "printMode"]))
      : _createCommentVNode("", true)
  ]))
}