import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-c9acab78"),n=n(),_popScopeId(),n)
const _hoisted_1 = { key: 0 }
const _hoisted_2 = { class: "font-lead" }
const _hoisted_3 = { key: 1 }
const _hoisted_4 = {
  key: 0,
  class: "contact-info"
}
const _hoisted_5 = { style: {"margin-top":"16px"} }
const _hoisted_6 = {
  "aria-label": "kontaktoplysninger",
  class: "table table--borderless table--compact table--responsive-headers d-print-table"
}
const _hoisted_7 = ["id"]
const _hoisted_8 = ["id"]
const _hoisted_9 = {
  key: 3,
  class: "spinner"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_KlarTekst = _resolveComponent("KlarTekst")!
  const _component_TGDetailsEntry = _resolveComponent("TGDetailsEntry")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (!this.printMode)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("a", {
            id: "tilbageKnap",
            class: _normalizeClass('back-link d-none d-lg-inline-block'),
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$router.go(-1)))
          }, [
            _createVNode(_component_KlarTekst, { "tekst-noegle": "tco.tg-opsummering.tilbage" })
          ])
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("h2", null, [
      _createVNode(_component_KlarTekst, { "tekst-noegle": "tco.tg-opsummering.titel" })
    ]),
    _createElementVNode("p", _hoisted_2, [
      _createVNode(_component_KlarTekst, { "tekst-noegle": "tco.tg-opsummering.info-tekst" })
    ]),
    (!this.noContactInfo)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          (this.formular?.contactInfo)
            ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                _createElementVNode("h3", _hoisted_5, [
                  _createVNode(_component_KlarTekst, { tekstNoegle: 'tco.blanket.tg-blanket.kontaktinfo.overskrift' }, null, 8, ["tekstNoegle"])
                ]),
                _createElementVNode("table", _hoisted_6, [
                  _createElementVNode("tbody", null, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.formular?.contactInfo?.felter?.filter(f => f.felttype !== _ctx.FeltType.OVERSKRIFT && f.felttype !== _ctx.FeltType.HIDDEN), (felt, index) => {
                      return (_openBlock(), _createElementBlock("tr", { key: felt }, [
                        _createElementVNode("th", {
                          class: "w-percent-md-30",
                          id: 'contact-info-label-' + index
                        }, _toDisplayString(felt.maerkat), 9, _hoisted_7),
                        _createElementVNode("td", {
                          id: 'contact-info-vaerdi-' + index
                        }, _toDisplayString(_ctx.formatFelt(felt)) + " " + _toDisplayString(felt.enhed), 9, _hoisted_8)
                      ]))
                    }), 128))
                  ])
                ])
              ]))
            : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true),
    (this.formular.products.length > 0)
      ? (_openBlock(), _createBlock(_component_TGDetailsEntry, {
          key: 2,
          formular: this.formular,
          id: _ctx.blanketId,
          printMode: this.printMode
        }, null, 8, ["formular", "id", "printMode"]))
      : _createCommentVNode("", true),
    (this.formular.products.length === 0)
      ? (_openBlock(), _createElementBlock("div", _hoisted_9))
      : _createCommentVNode("", true)
  ], 64))
}