
/* eslint-disable semi */
import { Vue } from 'vue-class-component'
import {
  namespace
} from 'vuex-class'

const oidcStoreModule = namespace('oidcStore');

export default class Login extends Vue {
  @oidcStoreModule.Action('authenticateOidc') loginAction;

  beforeMount (): void {
    this.loginAction();
  }
}
