import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  id: "formContainer1",
  class: "container page-container"
}
const _hoisted_2 = { class: "title-area" }
const _hoisted_3 = { class: "col-12 col-lg-12" }
const _hoisted_4 = { class: "row" }
const _hoisted_5 = { class: "col-12 col-lg-12" }
const _hoisted_6 = {
  class: "tabnav",
  role: "tablist",
  id: "vejledningTabs"
}
const _hoisted_7 = {
  "aria-controls": "tabpanel_vejledning1",
  id: "tab_vejledning1",
  class: "tabnav-item",
  role: "tab",
  "aria-selected": "true"
}
const _hoisted_8 = {
  class: "tabnav-panel",
  "aria-hidden": "true",
  role: "tabpanel",
  id: "tabpanel_vejledning1",
  "aria-labelledby": "tab_vejledning1"
}
const _hoisted_9 = { class: "col-11 col-lg-11 accordionList" }
const _hoisted_10 = { class: "faneTitel" }
const _hoisted_11 = { class: "accordion accordion-multiselectable" }
const _hoisted_12 = {
  class: "accordion-button",
  "aria-expanded": "false",
  "aria-controls": "fane1Accordion1"
}
const _hoisted_13 = {
  id: "fane1Accordion1",
  "aria-hidden": "true",
  class: "accordion-content"
}
const _hoisted_14 = {
  class: "accordion-button",
  "aria-expanded": "false",
  "aria-controls": "fane1Accordion2"
}
const _hoisted_15 = {
  id: "fane1Accordion2",
  "aria-hidden": "true",
  class: "accordion-content"
}
const _hoisted_16 = {
  class: "accordion-button",
  "aria-expanded": "false",
  "aria-controls": "fane1Accordion3"
}
const _hoisted_17 = {
  id: "fane1Accordion3",
  "aria-hidden": "true",
  class: "accordion-content"
}
const _hoisted_18 = {
  class: "accordion-button",
  "aria-expanded": "false",
  "aria-controls": "fane1Accordion4"
}
const _hoisted_19 = {
  id: "fane1Accordion4",
  "aria-hidden": "true",
  class: "accordion-content"
}
const _hoisted_20 = {
  class: "accordion-button",
  "aria-expanded": "false",
  "aria-controls": "fane1Accordion5"
}
const _hoisted_21 = {
  id: "fane1Accordion5",
  "aria-hidden": "true",
  class: "accordion-content"
}
const _hoisted_22 = {
  class: "accordion-button",
  "aria-expanded": "false",
  "aria-controls": "fane1Accordion6"
}
const _hoisted_23 = {
  id: "fane1Accordion6",
  "aria-hidden": "true",
  class: "accordion-content"
}
const _hoisted_24 = {
  "aria-controls": "tabpanel_vejledning2",
  id: "tab_vejledning2",
  class: "tabnav-item",
  role: "tab",
  "aria-selected": "true"
}
const _hoisted_25 = {
  class: "tabnav-panel",
  "aria-hidden": "true",
  role: "tabpanel",
  id: "tabpanel_vejledning2",
  "aria-labelledby": "tab_vejledning2"
}
const _hoisted_26 = { class: "col-11 col-lg-11 accordionList" }
const _hoisted_27 = { class: "faneTitel" }
const _hoisted_28 = { class: "accordion accordion-multiselectable" }
const _hoisted_29 = {
  class: "accordion-button",
  "aria-expanded": "false",
  "aria-controls": "fane2Accordion1"
}
const _hoisted_30 = {
  id: "fane2Accordion1",
  "aria-hidden": "true",
  class: "accordion-content"
}
const _hoisted_31 = {
  class: "accordion-button",
  "aria-expanded": "false",
  "aria-controls": "fane2Accordion2"
}
const _hoisted_32 = {
  id: "fane2Accordion2",
  "aria-hidden": "true",
  class: "accordion-content"
}
const _hoisted_33 = {
  "aria-controls": "tabpanel_vejledning3",
  id: "tab_vejledning3",
  class: "tabnav-item",
  role: "tab",
  "aria-selected": "true"
}
const _hoisted_34 = {
  class: "tabnav-panel",
  "aria-hidden": "true",
  role: "tabpanel",
  id: "tabpanel_vejledning3",
  "aria-labelledby": "tab_vejledning3"
}
const _hoisted_35 = { class: "col-11 col-lg-11 accordionList" }
const _hoisted_36 = { class: "faneTitel" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_KlarTekst = _resolveComponent("KlarTekst")!
  const _component_RichTekst = _resolveComponent("RichTekst")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("h1", null, [
          _createVNode(_component_KlarTekst, { tekstNoegle: 'tco.vejledning.hovedtitel' }, null, 8, ["tekstNoegle"])
        ]),
        _createElementVNode("span", null, [
          _createVNode(_component_KlarTekst, { tekstNoegle: 'tco.vejledning.hovedtitelbeskrivelse' }, null, 8, ["tekstNoegle"])
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("div", _hoisted_6, [
          _createElementVNode("button", _hoisted_7, [
            _createElementVNode("span", null, [
              _createVNode(_component_KlarTekst, { tekstNoegle: 'tco.vejledning.fane1.titel1' }, null, 8, ["tekstNoegle"])
            ])
          ]),
          _createElementVNode("section", _hoisted_8, [
            _createElementVNode("div", _hoisted_9, [
              _createElementVNode("div", _hoisted_10, [
                _createElementVNode("h2", null, [
                  _createVNode(_component_KlarTekst, { tekstNoegle: 'tco.vejledning.fane1.titel' }, null, 8, ["tekstNoegle"])
                ])
              ]),
              _createElementVNode("ul", _hoisted_11, [
                _createElementVNode("li", null, [
                  _createElementVNode("h3", null, [
                    _createElementVNode("button", _hoisted_12, [
                      _createVNode(_component_KlarTekst, { tekstNoegle: 'tco.vejledning.fane1.accordiontitel1' }, null, 8, ["tekstNoegle"])
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_13, [
                    _createVNode(_component_RichTekst, { tekstNoegle: 'tco.vejledning.fane1.accordionbeskrivelse1' }, null, 8, ["tekstNoegle"])
                  ])
                ]),
                _createElementVNode("li", null, [
                  _createElementVNode("h3", null, [
                    _createElementVNode("button", _hoisted_14, [
                      _createVNode(_component_KlarTekst, { tekstNoegle: 'tco.vejledning.fane1.accordiontitel2' }, null, 8, ["tekstNoegle"])
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_15, [
                    _createVNode(_component_RichTekst, { tekstNoegle: 'tco.vejledning.fane1.accordionbeskrivelse2' }, null, 8, ["tekstNoegle"])
                  ])
                ]),
                _createElementVNode("li", null, [
                  _createElementVNode("h3", null, [
                    _createElementVNode("button", _hoisted_16, [
                      _createVNode(_component_KlarTekst, { tekstNoegle: 'tco.vejledning.fane1.accordiontitel3' }, null, 8, ["tekstNoegle"])
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_17, [
                    _createVNode(_component_RichTekst, { tekstNoegle: 'tco.vejledning.fane1.accordionbeskrivelse3' }, null, 8, ["tekstNoegle"])
                  ])
                ]),
                _createElementVNode("li", null, [
                  _createElementVNode("h3", null, [
                    _createElementVNode("button", _hoisted_18, [
                      _createVNode(_component_KlarTekst, { tekstNoegle: 'tco.vejledning.fane1.accordiontitel4' }, null, 8, ["tekstNoegle"])
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_19, [
                    _createVNode(_component_RichTekst, { tekstNoegle: 'tco.vejledning.fane1.accordionbeskrivelse4' }, null, 8, ["tekstNoegle"])
                  ])
                ]),
                _createElementVNode("li", null, [
                  _createElementVNode("h3", null, [
                    _createElementVNode("button", _hoisted_20, [
                      _createVNode(_component_KlarTekst, { tekstNoegle: 'tco.vejledning.fane1.accordiontitel5' }, null, 8, ["tekstNoegle"])
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_21, [
                    _createVNode(_component_RichTekst, { tekstNoegle: 'tco.vejledning.fane1.accordionbeskrivelse5' }, null, 8, ["tekstNoegle"])
                  ])
                ]),
                _createElementVNode("li", null, [
                  _createElementVNode("h3", null, [
                    _createElementVNode("button", _hoisted_22, [
                      _createVNode(_component_KlarTekst, { tekstNoegle: 'tco.vejledning.fane1.accordiontitel6' }, null, 8, ["tekstNoegle"])
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_23, [
                    _createVNode(_component_RichTekst, { tekstNoegle: 'tco.vejledning.fane1.accordionbeskrivelse6' }, null, 8, ["tekstNoegle"])
                  ])
                ])
              ])
            ])
          ]),
          _createElementVNode("button", _hoisted_24, [
            _createElementVNode("span", null, [
              _createVNode(_component_KlarTekst, { tekstNoegle: 'tco.vejledning.fane2.titel2' }, null, 8, ["tekstNoegle"])
            ])
          ]),
          _createElementVNode("section", _hoisted_25, [
            _createElementVNode("div", _hoisted_26, [
              _createElementVNode("div", _hoisted_27, [
                _createElementVNode("h2", null, [
                  _createVNode(_component_KlarTekst, { tekstNoegle: 'tco.vejledning.fane2.titel' }, null, 8, ["tekstNoegle"])
                ])
              ]),
              _createElementVNode("ul", _hoisted_28, [
                _createElementVNode("li", null, [
                  _createElementVNode("h3", null, [
                    _createElementVNode("button", _hoisted_29, [
                      _createVNode(_component_KlarTekst, { tekstNoegle: 'tco.vejledning.fane2.accordiontitel1' }, null, 8, ["tekstNoegle"])
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_30, [
                    _createVNode(_component_RichTekst, { tekstNoegle: 'tco.vejledning.fane2.accordionbeskrivelse1' }, null, 8, ["tekstNoegle"])
                  ])
                ]),
                _createElementVNode("li", null, [
                  _createElementVNode("h3", null, [
                    _createElementVNode("button", _hoisted_31, [
                      _createVNode(_component_KlarTekst, { tekstNoegle: 'tco.vejledning.fane2.accordiontitel2' }, null, 8, ["tekstNoegle"])
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_32, [
                    _createVNode(_component_RichTekst, { tekstNoegle: 'tco.vejledning.fane2.accordionbeskrivelse2' }, null, 8, ["tekstNoegle"])
                  ])
                ])
              ])
            ])
          ]),
          _createElementVNode("button", _hoisted_33, [
            _createElementVNode("span", null, [
              _createVNode(_component_KlarTekst, { tekstNoegle: 'tco.vejledning.fane3.titel3' }, null, 8, ["tekstNoegle"])
            ])
          ]),
          _createElementVNode("section", _hoisted_34, [
            _createElementVNode("div", _hoisted_35, [
              _createElementVNode("div", _hoisted_36, [
                _createElementVNode("h2", null, [
                  _createVNode(_component_KlarTekst, { tekstNoegle: 'tco.vejledning.fane3.titel' }, null, 8, ["tekstNoegle"])
                ])
              ]),
              _createVNode(_component_RichTekst, { tekstNoegle: 'tco.vejledning.fane3.beskrivelse' }, null, 8, ["tekstNoegle"])
            ])
          ])
        ])
      ])
    ])
  ]))
}