import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  id: "formContainer",
  class: "container page-container"
}
const _hoisted_2 = { class: "row" }
const _hoisted_3 = { class: "col-12 col-lg-12" }
const _hoisted_4 = { class: "font-lead" }
const _hoisted_5 = { class: "row" }
const _hoisted_6 = { class: "col-12 col-lg-12" }
const _hoisted_7 = {
  class: "tabnav",
  role: "tablist",
  id: "tabs"
}
const _hoisted_8 = ["aria-selected"]
const _hoisted_9 = ["aria-hidden"]
const _hoisted_10 = ["aria-selected"]
const _hoisted_11 = ["aria-hidden"]
const _hoisted_12 = ["aria-selected"]
const _hoisted_13 = ["aria-hidden"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_KlarTekst = _resolveComponent("KlarTekst")!
  const _component_DokumentListe = _resolveComponent("DokumentListe")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("h2", null, [
          _createVNode(_component_KlarTekst, { tekstNoegle: 'tco.tg-overblik.overskrift' }, null, 8, ["tekstNoegle"])
        ]),
        _createElementVNode("p", _hoisted_4, [
          _createVNode(_component_KlarTekst, { tekstNoegle: 'tco.tg-overblik.beskrivelse' }, null, 8, ["tekstNoegle"])
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createElementVNode("div", _hoisted_6, [
        _createElementVNode("div", _hoisted_7, [
          _createElementVNode("button", {
            "aria-controls": "tabpanel_aktive",
            id: "tab_aktive",
            class: "tabnav-item",
            role: "tab",
            "aria-selected": _ctx.initialActiveTab === 0 ? 'true' : 'false'
          }, [
            _createElementVNode("span", null, [
              _createVNode(_component_KlarTekst, { tekstNoegle: 'tco.tg-overblik.tab.sendte-tilbud' }, null, 8, ["tekstNoegle"])
            ])
          ], 8, _hoisted_8),
          _createElementVNode("section", {
            class: "tabnav-panel",
            "aria-hidden": _ctx.initialActiveTab === 0 ? 'false' : 'true',
            role: "tabpanel",
            tabindex: "0",
            id: "tabpanel_aktive",
            "aria-labelledby": "tab_aktive"
          }, [
            _createVNode(_component_DokumentListe, {
              path: "/web/form/aktiveTGList",
              tg: "true",
              status: "published"
            })
          ], 8, _hoisted_9),
          _createElementVNode("button", {
            "aria-controls": "tabpanel_kladder",
            id: "tab_kladder",
            class: "tabnav-item",
            role: "tab",
            "aria-selected": _ctx.initialActiveTab === 1 ? 'true' : 'false'
          }, [
            _createElementVNode("span", null, [
              _createVNode(_component_KlarTekst, { tekstNoegle: 'tco.tg-overblik.tab.kladder' }, null, 8, ["tekstNoegle"])
            ])
          ], 8, _hoisted_10),
          _createElementVNode("section", {
            class: "tabnav-panel",
            "aria-hidden": _ctx.initialActiveTab === 1 ? 'false' : 'true',
            role: "tabpanel",
            tabindex: "0",
            id: "tabpanel_kladder",
            "aria-labelledby": "tab_kladder"
          }, [
            _createVNode(_component_DokumentListe, {
              path: "/web/form/kladderTGList",
              tg: "true",
              status: "draft"
            })
          ], 8, _hoisted_11),
          _createElementVNode("button", {
            "aria-controls": "tabpanel_afsluttede",
            id: "tab_afsluttede",
            class: "tabnav-item",
            role: "tab",
            "aria-selected": _ctx.initialActiveTab === 2 ? 'true' : 'false'
          }, [
            _createElementVNode("span", null, [
              _createVNode(_component_KlarTekst, { tekstNoegle: 'tco.tg-overblik.tab.afsluttede-tilbud' }, null, 8, ["tekstNoegle"])
            ])
          ], 8, _hoisted_12),
          _createElementVNode("section", {
            class: "tabnav-panel",
            "aria-hidden": _ctx.initialActiveTab === 2 ? 'false' : 'true',
            role: "tabpanel",
            tabindex: "0",
            id: "tabpanel_afsluttede",
            "aria-labelledby": "tab_afsluttede"
          }, [
            _createVNode(_component_DokumentListe, {
              path: "/web/form/afsluttedeTGList",
              tg: "true",
              status: "closed"
            })
          ], 8, _hoisted_13)
        ])
      ])
    ])
  ]))
}