import { User } from 'oidc-client'
import ContentfulTcoContent from '@/components/models/ContentfulTcoContent'
import { State } from '.'
import { ToastMessage } from '../models/ToastMessage'

export const logout = (state: State): void => {
  state.currentUser = {} as User
  state.accessTokenExpiring = false
}

export const login = (state: State, user: User): void => {
  state.currentUser = user
  state.accessTokenExpiring = false
}

export const accessTokenExpiring = (state: State, value: boolean): void => {
  state.accessTokenExpiring = value
}

export const setReturnUrlSessionRefresh = (state: State, value: boolean): void => {
  // does nothing on purpose, since it is stored in window.sessionStorage
}

export const setKlarTekstMap = (state: State, klarTekstMap: Map<string, string>): void => {
  state.klarTekstMap = klarTekstMap
}

export const setContentfulTcoContent = (state: State, contentfulTcoContent: ContentfulTcoContent): void => {
  state.contentfulTcoModel = contentfulTcoContent
}

export const addToast = (state: State, toast: ToastMessage): void => {
  if (!toast.id) {
    state.nextToastId = state.nextToastId + 1
    toast.id = 'toast-' + state.nextToastId
  }
  state.toastMessages.push(toast)
}

export const removeToast = (state: State, id: string): void => {
  state.toastMessages = state.toastMessages.filter(t => t.id !== id)
}

export const clearToasts = (state: State): void => {
  state.toastMessages = []
}
