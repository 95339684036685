
import { Options, Vue } from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import Dokument from '@/components/models/Dokument.vue'
import * as DKFDS from 'dkfds'
import { v4 as uuid } from 'uuid'
import { DateTime } from 'luxon'
import { DocumentStatus } from '@/components/Const'
import axios from '@/services/axios-instance'
import KlarTekst from '@/components/KlarTekst.vue'
import { Action, Getter } from 'vuex-class'
import ToastUtil from '@/ToastUtil'

@Options({
  components: {
    Dokument,
    KlarTekst
  },
  data () {
    return {
      DocumentStatus
    }
  }
})
export default class DokumentVisning extends Vue {
  emitter = require('tiny-emitter/instance')
  @Getter('getContentfulTcoModel') contentfulTcoModel
  @Action('addToast') addToast
  randomIdentifier : string = uuid()

  @Prop({ type: Dokument })
  dokument

  @Prop({ type: String })
  tab

  mounted () : void {
    DKFDS.init()
  }

  label () : string {
    return 'accordion-' + this.randomIdentifier
  }

  date (value : string, format = 'dd-LL-y') : string | undefined {
    if (value) {
      return DateTime.fromISO(value).toFormat(format)
    }
  }

  getActionsBoxHeight (value : string) : string {
    if (value === DocumentStatus.PUBLISHED) {
      return 'height: 288px'
    } else if (value === DocumentStatus.DRAFT) {
      return 'height: 288px'
    } else if (value === DocumentStatus.CLOSED) {
      return 'height: 248px'
    } else {
      return ''
    }
  }

  IsNotOverdue (value : string) : boolean {
    if (!value) return true
    var deadline = Date.parse(value)
    var now = Date.parse(DateTime.now().toString())
    if (deadline > now) {
      return true
    }
    return false
  }

  doAction (url : string, id : string) : void {
    axios.post(process.env.VUE_APP_DIRIGENT_URL + url + id, '',
      {
        headers: {
          'content-type': 'application/json'
        }
      }).then(response => {
      if (response.status === 200) {
        this.emitter.emit('reloadDocuments')
      }
    }).catch(error => this.addToast(ToastUtil.createToastForErrorResponse(error, this.contentfulTcoModel)))
  }

  deleteDraft (id : string) : void {
    this.doAction('/web/form/sletKladdeTG/', id)
  }

  formatNumeric (amount: string, suffix: string) : string {
    if (amount == null || amount === '') {
      return '-'
    } else {
      const value = (+amount).toLocaleString('da-DK', { minimumFractionDigits: 0, maximumFractionDigits: 0 })
      return value.toString() + ' ' + suffix
    }
  }

  printResultat (blanketId: string) : void {
    const route = this.$router.resolve({ path: '/blanketTG/resultat/print/' + blanketId })
    window.open(route.href, '_blank')
  }

  printOpsummering (blanketId: string) : void {
    const route = this.$router.resolve({ path: '/opsummeringTG/print/' + blanketId })
    window.open(route.href, '_blank')
  }
}
