
import { Vue, Options } from 'vue-class-component'
import HelloWorld from './components/HelloWorld.vue'
import Integration from './components/Integration.vue'
import OGFormular from '@/components/OGFormular.vue'
import TGFormular from '@/components/TGFormular.vue'
import SideHoved from '@/components/SideHoved.vue'
import SideFod from '@/components/SideFod.vue'
import InviterTilbudsgiver from '@/components/InviterTilbudsgiver.vue'
import Ikoner from '@/components/Ikoner.vue'
import FormularModel from '@/components/models/FormularModel.vue'
import Dkfds from 'dkfds'
import NavBar from '@/components/NavBar.vue'
import axios from '@/services/axios-instance'
import store from '@/components/store'
import ContentfulTcoContent from '@/components/models/ContentfulTcoContent'
import ToastMessages from '@/components/ToastMessages.vue'
import { CookiePrompter, PiwikProTracker } from '@/components/util/CookiePrompter-2.3.3'
import { Getter } from 'vuex-class'
import { Watch } from 'vue-property-decorator'

@Options({
  components: {
    NavBar,
    SideFod,
    SideHoved,
    HelloWorld,
    TGFormular,
    OGFormular,
    FormularModel,
    Integration,
    Ikoner,
    InviterTilbudsgiver,
    Dkfds,
    ToastMessages
  }
})
export default class App extends Vue {
  @Getter('getContentfulTcoModel') contentfulTcoModel

  isFetching = true

  created () : void {
    document.title = 'TCO'

    Dkfds.init()
    this.initEmptyContentfulTcoContent()
    this.fetchContentfulTcoContent()
    const htmlTagElements = document.getElementsByTagName('html')
    if (htmlTagElements.length > 0) {
      const firstelem = htmlTagElements.item(0)
      if (firstelem) {
        firstelem.setAttribute('lang', 'da')
      }
    }
  }

  private async initEmptyContentfulTcoContent () {
    const contentfulTcoContent: ContentfulTcoContent = new ContentfulTcoContent({ klarTekster: [] })
    const klarTekstMap: Map<string, string> = new Map()
    store.dispatch('setKlarTekstMap', klarTekstMap)
    store.dispatch('setContentfulTcoContent', contentfulTcoContent)
  }

  private async fetchContentfulTcoContent () {
    axios
      .get(process.env.VUE_APP_DIRIGENT_URL + '/content')
      .then(rsp => {
        console.log('Response: ' + rsp)
        const contentfulTcoContent: ContentfulTcoContent = new ContentfulTcoContent(rsp.data)
        const klarTekstMap: Map<string, string> = new Map()
        const tekster: string[] = rsp.data.klarTekster
        // eslint-disable-next-line no-unused-expressions
        tekster?.forEach((tekst: any) => {
          klarTekstMap.set(tekst.noegle, tekst.vaerdi)
        })
        store.dispatch('setKlarTekstMap', klarTekstMap)
        store.dispatch('setContentfulTcoContent', contentfulTcoContent)
        this.isFetching = false
      })
      .catch(error => {
        console.log(error)
      })
  }

  @Watch('isFetching', { deep: true })
  onFetchingContentfulDataCompleted (): void {
    if (this.isFetching === false) {
      this.initTrackningCookiePrompter()
    }
  }

  initTrackningCookiePrompter (): void {
    if (process.env.VUE_APP_PIWIK_ACCOUNT_ID) {
      CookiePrompter.init({
        trackers: [
          {
            name: PiwikProTracker,
            config: {
              account: process.env.VUE_APP_PIWIK_ACCOUNT_ID
            }
          }
        ],
        readMoreUrl: this.contentfulTcoModel.findKlarTekst('tco.cookie-prompter.read-more-url'),
        textHeader: this.contentfulTcoModel.findKlarTekst('tco.cookie-prompter.text-header'),
        textReadMore: this.contentfulTcoModel.findKlarTekst('tco.cookie-prompter.text-read-more'),
        textblock1: this.contentfulTcoModel.findKlarTekst('tco.cookie-prompter.text-block1'),
        textAccept: this.contentfulTcoModel.findKlarTekst('tco.cookie-prompter.text-accept'),
        textDontAccept: this.contentfulTcoModel.findKlarTekst('tco.cookie-prompter.text-dont-accept')
      })
    }
  }
}

