import { User } from 'oidc-client'
import { State } from '.'
import { Sector9Claims, TCOPrivilege, TCORole } from '../Const'
import ContentfulTcoContent from '@/components/models/ContentfulTcoContent'
import { ToastMessage } from '@/components/models/ToastMessage'

export const isPrivateUser = (state: State): boolean => {
  return state.currentUser.profile
    ? state.currentUser.profile[Sector9Claims.CVR] !== undefined
    : false
}

export const getCurrentUser = (state: State): User => {
  return state.currentUser
}

export const isAdministrator = (state: State): boolean => {
  return hasRole(state)(TCORole.ADMIN)
}

export const isOrdregiver = (state: State): boolean => {
  const isOrdregiver = hasRole(state)(TCORole.ORDREGIVER)
  return isOrdregiver
}

export const isTilbudsgiver = (state: State): boolean => {
  const hasTilbudsgiverRole = hasRole(state)(TCORole.TILBUDSGIVER)
  return hasTilbudsgiverRole
}

export const getTCORoles = (state: State): string[] => {
  if (!state.currentUser || !state.currentUser.profile) return []

  const profile = state.currentUser.profile
  let roles: string[] = []
  if (profile && profile[Sector9Claims.ROLES]) {
    // hvis der kun er en role i roles array'et fra sector9 så parser oidc-client det direkte som en string istedet for et array med et element
    roles = Array.isArray(profile[Sector9Claims.ROLES]) ? profile[Sector9Claims.ROLES] : [profile[Sector9Claims.ROLES]]
  }
  roles = roles.filter(r => r === TCORole.ADMIN || r === TCORole.ORDREGIVER || r === TCORole.TILBUDSGIVER)

  const privRoles = getTCORolesFromPrivileges(state)
  if (privRoles) {
    privRoles.forEach(r => {
      if (roles.findIndex(role => role === r) < 0) {
        roles.push(r)
      }
    })
  }

  // default er bruger ordre giver og tilbudsgiver
  if (roles.length === 0) {
    roles.push(TCORole.ORDREGIVER)
    roles.push(TCORole.TILBUDSGIVER)
  }

  return roles
}

const getTCORolesFromPrivileges = (state: State): string[] => {
  if (!state.currentUser || !state.currentUser.profile) return []

  const profile = state.currentUser.profile
  let privileges: string[] = []
  if (profile && profile[Sector9Claims.PRIVILEGES]) {
    // hvis der kun er en privilegie i array'et fra sector9 så parser oidc-client det direkte som et object istedet for et array med et element
    const privs = Array.isArray(profile[Sector9Claims.PRIVILEGES]) ? profile[Sector9Claims.PRIVILEGES] : [profile[Sector9Claims.PRIVILEGES]]
    privs.forEach(p => {
      // TODO: should we check scope here?
      privileges = privileges.concat(p.privileges)
    })
  }
  privileges = privileges.filter(r => r === TCOPrivilege.ORDREGIVER || r === TCOPrivilege.TILBUDSGIVER)
    .map(r => {
      if (r === TCOPrivilege.ORDREGIVER) {
        return TCORole.ORDREGIVER
      } else if (r === TCOPrivilege.TILBUDSGIVER) {
        return TCORole.TILBUDSGIVER
      } else {
        return ''
      }
    })
    .filter(r => r !== null && r !== '') // just in case

  return privileges
}

export const hasRole = (state: State) => (role: string): boolean => {
  const roles = getTCORoles(state)
  return roles && roles.findIndex(r => r === role) >= 0
}

export const getKlarTekstMap = (state: State): Map<string, string> => {
  return state.klarTekstMap
}

export const getContentfulTcoModel = (state: State): ContentfulTcoContent => {
  return state.contentfulTcoModel
}

/*
export const getKlarTekst = (state: State) => (key: string): string => {
  const value = state.contentfulTcoModel.get(key)
  return (value != null ? value : key)
}
*/

export const getToastMessages = (state: State): ToastMessage[] => {
  return state.toastMessages
}

export const accessTokenExpiring = (state: State) => {
  return state.accessTokenExpiring
}

export const returnUrlSessionRefresh = (state: State) => {
  const returnUrl = window.sessionStorage.getItem('returnUrlSessionRefresh') || ''
  return returnUrl
}
