
import { Options, Vue } from 'vue-class-component'
import * as DKFDS from 'dkfds'
import axios from '@/services/axios-instance'
import TCOEvaluationModel from '@/components/models/evaluation/TCOEvaluationModel.vue'
import OGEvaluateEntry from '@/components/OGEvaluateEntry.vue'
import KlarTekst from '@/components/KlarTekst.vue'
import { Action, Getter } from 'vuex-class'
import ToastUtil from '@/ToastUtil'
import OGEvaluateUtil from '@/components/util/OGEvaluateUtil'
import OGEvaluatePrintModal from './OGEvaluatePrintModal.vue'

@Options({
  components: {
    KlarTekst,
    OGEvaluateEntry,
    OGEvaluatePrintModal
  }
})
export default class OGEvaluate extends Vue {
  emitter = require('tiny-emitter/instance')

  @Getter('getContentfulTcoModel') contentfulTcoModel
  @Action('addToast') addToast

  ogId
  evaluation = new TCOEvaluationModel()
  sortOfferProduct = 'offer'
  sortValue = 'low-score'

  created () : void {
    this.ogId = this.$route.params.id as string
    if (this.ogId) {
      this.getTcoScore()
    }
  }

  mounted () : void {
    const dropdown = new DKFDS.DropdownSort(document.getElementById('sorting-overflow-menu'))
    const tab = new DKFDS.Tabnav(document.getElementById('tabs'))
  }

  getTcoScore (sort? : string) : void {
    let modifiedSort = sort
    if (this.sortOfferProduct === 'product') {
      modifiedSort = 'product-' + sort
    }

    axios.get(process.env.VUE_APP_DIRIGENT_URL + '/web/form/getEvaluering/' + this.ogId, { params: { sort: modifiedSort } })
      .then(
        response => {
          this.evaluation = response.data
          OGEvaluateUtil.setEvaluationLabels(this.evaluation, this.sortOfferProduct)
        }).catch(error => this.addToast(ToastUtil.createToastForErrorResponse(error, this.contentfulTcoModel)))
  }

  sort ($event : any) : void {
    this.sortValue = $event.target.id
    if ($event.target.id !== '') {
      this.evaluation = new TCOEvaluationModel()
      this.getTcoScore($event.target.id)
    }
  }

  sortRadio ($event : any) : void {
    this.evaluation = new TCOEvaluationModel()
    this.getTcoScore(this.sortValue)
  }

  formatNumeric (amount: string, suffix: string, co2?: boolean) : string {
    if (amount == null || amount === '') {
      return '-'
    } else {
      const value = (+amount).toLocaleString('da-DK', { minimumFractionDigits: co2 ? 2 : 0, maximumFractionDigits: co2 ? 2 : 0 })
      return value.toString() + ' ' + suffix
    }
  }
}
