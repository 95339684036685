
import { Options, Vue } from 'vue-class-component'
import axios from '@/services/axios-instance'
import Opsummering from '@/components/Opsummering.vue'
import OGFormularModel from '@/components/models/OGFormularModel.vue'
import TGFormularModel from '@/components/models/TGFormularModel.vue'
import KlarTekst from '@/components/KlarTekst.vue'
import { Action, Getter } from 'vuex-class'
import ToastUtil from '@/ToastUtil'

@Options({
  components: {
    KlarTekst,
    Opsummering
  }
})
export default class TGKontaktOplysningerStandalone extends Vue {
  @Getter('getContentfulTcoModel') contentfulTcoModel
  @Action('addToast') addToast
  id = ''
  // Opsummering component take og formular as input.
  ogInformation: OGFormularModel = new OGFormularModel();

  created () : void {
    this.id = this.$route.params.id as string
    if (this.id) {
      axios.get(process.env.VUE_APP_DIRIGENT_URL + '/web/form/getFormTG/' + this.id)
        .then(
          response => {
            const tgFormularModel : TGFormularModel = response.data
            // hack so that we can use the Opsummering component
            this.ogInformation.contactInfo = tgFormularModel.contactInfo
          })
        .catch(error => this.addToast(ToastUtil.createToastForErrorResponse(error, this.contentfulTcoModel)))
    }
  }
}
