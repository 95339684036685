
/* eslint-disable semi */
import { Options, Vue } from 'vue-class-component'
import {
  Getter,
  Action,
  namespace
} from 'vuex-class'
import NavBar from './NavBar.vue'
import KlarTekst from '@/components/KlarTekst.vue'

const oidcStoreModule = namespace('oidcStore')

@Options({
  components: {
    NavBar,
    KlarTekst
  }
})
export default class SideHoved extends Vue {
  @oidcStoreModule.Action('authenticateOidc') loginAction
  @oidcStoreModule.Action('signOutOidc') oidcStoreLogoutAction
  @Action('logout') logoutAction

  @Getter('getCurrentUser') currentUser;
  @oidcStoreModule.Getter('oidcIsAuthenticated') isAuthenticated
  @oidcStoreModule.Getter('oidcAccessTokenExp') expiration
  @Action('setReturnUrlSessionRefresh') setReturnUrlSessionRefresh

  @Getter('getContentfulTcoModel') contentfulTcoModel

  showTestHeader = false

  menuTitle = ''

  created (): void {
    this.showTestHeader = process.env.VUE_APP_SHOW_TEST_HEADER.includes('true')
    this.menuTitle = this.contentfulTcoModel.findKlarTekst('tco.sidehoved.menu.title')
  }

  async login () : Promise<void> {
    await this.setReturnUrlSessionRefresh('')
    this.loginAction()
  }

  async logout () : Promise<void> {
    this.oidcStoreLogoutAction()
    this.logoutAction()
  }
}
