
/* eslint-disable semi */

import { Options, Vue } from 'vue-class-component'
import axios from '@/services/axios-instance'
import { DocumentStatus } from './Const'
import { formatDate } from '@/components/util/DateTime.ts'
import { Action, Getter } from 'vuex-class'
import KlarTekst from '@/components/KlarTekst.vue'
import RichTekst from '@/components/RichTekst.vue'
import ToastUtil from '@/ToastUtil'

@Options({
  components: {
    KlarTekst,
    RichTekst
  },
  data () {
    return {
      DocumentStatus
    }
  }
})
export default class MetadataOverblik extends Vue {
  @Getter('getContentfulTcoModel') contentfulTcoModel
  @Getter('isAdministrator') isAdministrator
  @Action('addToast') addToast

  productGroups = []

  headerNavn = ''
  headerVersion = ''
  headerValiditet = ''
  headerStatus = ''
  headerUnderNavn = ''

  mounted () : void {
    if (!this.isAdministrator) this.$router.replace('/')

    this.headerNavn = this.contentfulTcoModel.findKlarTekst('tco.produktgruppe.header.navn')
    this.headerVersion = this.contentfulTcoModel.findKlarTekst('tco.produktgruppe.header.version')
    this.headerValiditet = this.contentfulTcoModel.findKlarTekst('tco.produktgruppe.header.validitet')
    this.headerStatus = this.contentfulTcoModel.findKlarTekst('tco.produktgruppe.header.status')
    // this.headerUnderNavn = this.contentfulTcoModel.findKlarTekst('tco.produktgruppe.header.undernavn')
    this.headerUnderNavn = 'Produktundergruppe';
    this.hentMetadataListe()
  }

  async hentMetadataListe () : Promise<void> {
    await axios
      .get(process.env.VUE_APP_DIRIGENT_URL + '/web/form/alleMetadataList')
      .then(response => (this.productGroups = response.data))
      .catch(error => this.addToast(ToastUtil.createToastForErrorResponse(error, this.contentfulTcoModel)))
  }

  formatPeriod (startDate: string, endDate: string) {
    let period = ''
    if (startDate) {
      period += formatDate(startDate)
    }

    if (endDate) {
      period += ' til '
      period += formatDate(endDate)
    }
    return period
  }
}
