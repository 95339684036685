
import TrinModel from '@/components/models/TrinModel.vue'
import OGFormularModel from '@/components/models/OGFormularModel.vue'
import TGProductModel from '@/components/models/TGProductModel.vue'

export default class TGFormularModel {
  id = ''
  version? = ''
  originalBlanketId = ''
  editOfferBlanketId = ''
  ogOnBehalfOfTG = false
  status = ''

  contactInfo: TrinModel = new TrinModel()

  numberOfProducts = 1
  products: TGProductModel[] = []
  productTemplate: OGFormularModel = new OGFormularModel()

  ogInformation: OGFormularModel = new OGFormularModel()
}

