

import CompanyEvaluationModel from '@/components/models/evaluation/CompanyEvaluationModel.vue'
import TopTotalModel from '@/components/models/evaluation/TopTotalModel.vue'
import TopEnvModel from '@/components/models/evaluation/TopEnvModel.vue'

export default class TCOEvaluationModel {
  companies: CompanyEvaluationModel[] = []
  topTotalProducts: TopTotalModel[] = []
  topEnvProducts: TopEnvModel[] = []
  id = ''
}
