import { createRouter, createWebHistory } from 'vue-router'
import Metadata from '@/components/Metadata'
import OGFormular from '@/components/OGFormular'
import OGEvaluate from '@/components/OGEvaluate'
import TGOpsummeringStandalone from '@/components/TGOpsummeringStandalone'
import TGFormular from '@/components/TGFormular'
import Overblik from '@/components/Overblik'
import OverblikTG from '@/components/OverblikTG'
import Integration from '@/components/Integration'
import Auth from '@/components/Auth'
import Login from '@/components/Login'
import Logout from '@/components/Logout'
import Forside from '@/components/Forside'
import Vejledning from '@/components/Vejledning'
import MetadataOverblik from '@/components/MetadataOverblik'
import Kvittering from '@/components/Kvittering'
import kvitteringTG from '@/components/kvitteringTG'
import PrintOpsummering from '@/components/PrintOpsummering'
import OGOpsummeringStandalone from '@/components/OGOpsummeringStandalone'
import TGKontaktOplysningerStandalone from '@/components/TGKontaktOplysningerStandalone'
import TGResultatStandalone from '@/components/TGResultatStandalone'
import { vuexOidcCreateRouterMiddleware } from 'vuex-oidc'
import store from '@/components/store'
import { publicPath } from '@/../vue.config'
import TGLandingPage from '@/components/TGLandingPage'
import OGEvaluatePrint from '@/components/OGEvaluatePrint'
import CookiePolicy from '@/components/CookiePolicy.vue'

const routes = [
  { path: '/', component: Forside, meta: { isPublic: true } },
  { path: '/blanket/:id?', component: OGFormular, name: 'og-blanket-formular' },
  { path: '/overblikOG', component: Overblik },
  { path: '/evalueringOG/:id', component: OGEvaluate },
  { path: '/evalueringOG/print/:id', component: OGEvaluatePrint },
  { path: '/opsummeringTG/:id', component: TGOpsummeringStandalone },
  { path: '/opsummeringTG/print/:id', component: TGOpsummeringStandalone, props: { printMode: true } },
  { path: '/opsummeringTG/noContactInfo/:id', component: TGOpsummeringStandalone, props: { noContactInfo: true } },
  { path: '/kontaktTG/:id', component: TGKontaktOplysningerStandalone },
  { path: '/overblikTG', component: OverblikTG },
  { path: '/blanketTG', component: TGFormular, name: 'tg-blanket-formular' },
  { path: '/blanketTG/:id?', component: TGFormular, name: 'tg-blanket-formular-edit' },
  { path: '/blanketTG/resultat/:id?', component: TGResultatStandalone },
  { path: '/blanketTG/resultat/print/:id?', component: TGResultatStandalone, props: { printMode: true } },
  { path: '/metadata', component: MetadataOverblik },
  { path: '/metadata/:id', component: Metadata },
  { path: '/metadata/ny', component: Metadata },
  { path: '/helloworld', component: Integration },
  { path: '/kvittering', component: Kvittering },
  { path: '/TGkvittering', component: kvitteringTG },
  { path: '/blanketprint/:id', component: PrintOpsummering },
  { path: '/blanket/opsummering/:id', component: OGOpsummeringStandalone },
  { path: '/vejledning', component: Vejledning, meta: { isPublic: true } },
  { path: '/invitation-tilbud/:id', component: TGLandingPage },
  {
    path: '/auth',
    component: Auth,
    meta: {
      title: 'Logger ind',
      isPublic: true
    }
  },
  {
    path: '/login',
    component: Login,
    meta: {
      title: 'Logger ind',
      isPublic: true
    }
  },
  {
    path: '/logout',
    component: Logout,
    meta: {
      title: 'Logger ud',
      isPublic: true
    }
  },
  {
    path: '/kvittering',
    name: 'Kvittering',
    component: Kvittering,
    meta: { title: 'Kvittering' }
  },
  {
    path: '/kvitteringTG',
    name: 'kvitteringTG',
    component: kvitteringTG,
    meta: { title: 'kvitteringTG' }
  },
  {
    path: '/cookie-policy',
    name: 'CookiePolicy',
    component: CookiePolicy,
    meta: {
      isPublic: true
    }
  }
]

const router = createRouter({
  history: createWebHistory(publicPath),
  routes
})

function clearToasts (store) {
  return (to, from, next) => {
    const keepAlerts = to && to.query && to.query.keepAlerts
    if (!keepAlerts) {
      store.dispatch('clearToasts')
    }
    next()
  }
}

router.beforeEach(vuexOidcCreateRouterMiddleware(store, 'oidcStore'))
router.beforeEach(clearToasts(store))

export default router
