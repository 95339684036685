
/* eslint-disable semi */
import { Options, Vue } from 'vue-class-component'
import * as DKFDS from 'dkfds'
import KlarTekst from '@/components/KlarTekst.vue'
import RichTekst from '@/components/RichTekst.vue'
import { Getter, namespace } from 'vuex-class'

const oidcStoreModule = namespace('oidcStore');

@Options({
  components: {
    KlarTekst,
    RichTekst
  }
})
export default class Forside extends Vue {
  @Getter('isOrdregiver') isOrdregiver
  @oidcStoreModule.Getter('oidcIsAuthenticated') isAuthenticated;

  mounted () : void {
    DKFDS.init()
  }
}
