import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Chart = _resolveComponent("Chart")!

  return (_openBlock(), _createBlock(_component_Chart, {
    chartData: _ctx.chartData,
    options: _ctx.chartOptions,
    chartPlugins: _ctx.chartPlugins,
    chartType: _ctx.chartType,
    printMode: _ctx.printMode
  }, null, 8, ["chartData", "options", "chartPlugins", "chartType", "printMode"]))
}