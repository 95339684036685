
/* eslint-disable semi */
import { defineComponent } from 'vue';
import { mapActions, mapGetters } from 'vuex';
import router from '@/router';

export default defineComponent({
  name: 'Auth',
  components: {},
  computed: {
    ...mapGetters(['returnUrlSessionRefresh'])
  },
  props: {},
  methods: {
    ...mapActions('oidcStore', ['oidcSignInCallback']),
    ...mapActions(['setReturnUrlSessionRefresh'])
  },
  data: function () {
    return {}
  },
  async mounted () {
    this.oidcSignInCallback()
      .then(async (redirectPath) => {
        const storedReturnUrl = this.returnUrlSessionRefresh
        this.setReturnUrlSessionRefresh('')

        if (storedReturnUrl) {
          router.push(storedReturnUrl)
        } else {
          router.push(redirectPath)
        }
      })
      .catch((err) => {
        this.setReturnUrlSessionRefresh('')
        console.error(err)
      })
  }
})
