
import { Options, Vue } from 'vue-class-component'
import TGFormularModel from '@/components/models/TGFormularModel.vue'
import { Prop } from 'vue-property-decorator'
import FeltModel from '@/components/models/FeltModel.vue'
import axios from '@/services/axios-instance'
import { FeltType } from '@/components/Const'
import DateUtil from '@/DateUtil'
import MetadataDokument from './models/MetadataDocument'
import * as DKFDS from 'dkfds'
import OGFormularModel from '@/components/models/OGFormularModel.vue'
import KlarTekst from '@/components/KlarTekst.vue'
import ToastUtil from '@/ToastUtil'
import { Action, Getter } from 'vuex-class'
import FormularFeltUtil from './util/FormularFeltUtil'
import { Fejlbesked } from './models/Fejl'

@Options({
  components: {
    KlarTekst
  },
  data () {
    return {
      FeltType
    }
  }
})
export default class TGOpsummering extends Vue {
  @Getter('getContentfulTcoModel') contentfulTcoModel
  @Action('addToast') addToast
  emitter = require('tiny-emitter/instance')
  produktGrupper : MetadataDokument[] = []

  @Prop({ type: TGFormularModel })
  formular? : TGFormularModel

  @Prop({ editable: Boolean })
  editable? : boolean

  mounted () : void {
    // initialize accordion
    DKFDS.init() // NOSONAR

    // DKFDS.init() resets the selected tab - so we manually set the correct tab here
    const produktTab = document.getElementById('tab_udbudsinfo')
    if (produktTab) {
      produktTab.click()
    }
  }

  lavVistNavn (felt : FeltModel) : string {
    switch (felt.felttype) {
      case FeltType.TEKSTFELT_TAL:
        return this.formatNumeric(felt.vaerdi)
      case FeltType.RADIO_JA_NEJ:
        return felt.vaerdi === 'true' ? 'Ja' : 'Nej'
      case FeltType.VALGLISTE:
        return felt.valgmuligheder[felt.vaerdi]
      case FeltType.PRODUKTGRUPPEVÆLGER:
        return this.produktGrupper.filter(value => value.id === felt.vaerdi)[0]?.product_group_name + ' - ' + this.produktGrupper.filter(value => value.id === felt.vaerdi)[0]?.product_subgroup_name
      case FeltType.DATO:
        return this.formatDate(felt.vaerdi)
      case FeltType.STATIC_TEKST:
        return felt.displayVaerdi || felt.vaerdi
      case FeltType.RADIO_JA_NEJ_MED_INPUT:
        return (this.isJaValgt(felt) ? felt.jaRadioMaerkat : felt.nejRadioMaerkat) || ''
      default:
        return felt.vaerdi
    }
  }

  isJaValgt (felt: FeltModel) : boolean {
    return felt.felttype === FeltType.RADIO_JA_NEJ_MED_INPUT && felt.vaerdi === felt.jaRadioVaerdi
  }

  formatEnhed (felt: FeltModel) : string {
    if (felt.felttype === FeltType.RADIO_JA_NEJ_MED_INPUT) {
      return (this.isJaValgt(felt) ? felt.jaInputEnhed : felt.nejInputEnhed) || ''
    }
    return felt.enhed
  }

  genererDomId (tcoElementName : string) : string {
    if (tcoElementName != null) {
      return FormularFeltUtil.escapeForDomId(tcoElementName)
    }
    return ''
  }

  genererDomIdWithIndex (tcoElementName : string, index : number) : string {
    if (tcoElementName != null) {
      return this.genererDomId(tcoElementName) + index
    }
    return ''
  }

  created () : void {
    if (this.formular) {
      this.hentProduktGrupper()
    }
  }

  redigerFelt (felt : FeltModel, trinNummer : number, productIndex : number) : void {
    let elementName = felt.tcoElementName
    if (felt.felttype === FeltType.RADIO_JA_NEJ_MED_INPUT) {
      elementName = (this.isJaValgt(felt) ? felt.jaInputTcoElementName : felt.nejInputTcoElementName) || felt.tcoElementName
    }
    this.emitter.emit('redigerFelt', this.genererDomId(elementName), trinNummer, productIndex)
  }

  redigerFeltByTCOElementName (tcoElementName: string, trinNummer : number, productIndex : number): void {
    this.emitter.emit('redigerFelt', this.genererDomId(tcoElementName), trinNummer, productIndex)
  }

  rediger (productIndex : number) : void {
    this.emitter.emit('rediger', productIndex)
  }

  deleteProduct (productIndex : number) : void {
    this.emitter.emit('deleteProduct', productIndex)
  }

  hentProduktGrupper () : void {
    axios
      .get(process.env.VUE_APP_DIRIGENT_URL + '/web/form/alleAktiveMetadataList')
      .then(response => {
        this.produktGrupper = response.data
      })
      .catch(error => this.addToast(ToastUtil.createToastForErrorResponse(error, this.contentfulTcoModel)))
  }

  formatNumeric (value : string) : string {
    if (value == null) {
      return ''
    }
    return value.replaceAll('.', ',')
  }

  formatDate (value: string): string {
    return DateUtil.formatDate(value)
  }

  doContainError (model: OGFormularModel): boolean {
    return model.trin.some(t => t.felter.some(f => !f.erValid && f.felttype !== FeltType.HIDDEN))
  }

  getErrorCount (model: OGFormularModel): number {
    let errorCount = 0
    model.trin.forEach(t => t.felter.forEach(f => {
      if (!f.erValid && f.felttype !== FeltType.HIDDEN) {
        errorCount++
      }
    }))
    return errorCount
  }

  getContactInfoErrors (): Fejlbesked[] {
    const fejl: Fejlbesked[] = []
    if (this.formular?.contactInfo) {
      const felter = this.formular.contactInfo.felter?.filter(y => !y.erValid && y.felttype !== FeltType.HIDDEN)
      if (felter) {
        felter.forEach(felt => {
          if (felt.tcoElementName !== '' && felt.fejl) {
            felt.fejl.forEach(f => {
              const error = { tcoElementName: felt.tcoElementName, fejlbesked: `${felt.maerkat}: ${f}` }
              fejl.push(error)
            })
          }
        })
      }
    }
    return fejl
  }

  getProductErrors () : Fejlbesked[] {
    const fejl: Fejlbesked[] = []
    if (this.formular?.products) {
      for (let i = 0; i < this.formular?.products.length; i++) {
        // first trin is not in the summary hence j=1
        for (let j = 1; j < this.formular.products[i].formular.trin.length; j++) {
          const trin = this.formular?.products[i].formular.trin[j]
          const felter = trin.felter?.filter(f => !f.erValid && f.felttype !== FeltType.HIDDEN)
          if (felter) {
            felter.forEach(felt => {
              if (felt.tcoElementName !== '' && felt.fejl) {
                felt.fejl.forEach(f => {
                  const error = { tcoElementName: felt.tcoElementName + i + ',' + i, fejlbesked: `${felt.maerkat}: ${f}` }
                  fejl.push(error)
                })
              }
            })
          }
        }
      }
    }
    return fejl
  }

  expandAccordion (i : number) : void {
    const p = document.getElementById('product-' + i)
    if (p) {
      p.setAttribute('aria-hidden', 'false')
    }
    const a = document.getElementById('product-button-' + i)
    if (a) {
      a.setAttribute('aria-expanded', 'true')
    }
  }
}
