
import { Options, Vue } from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import axios from '@/services/axios-instance'
import Dokument from '@/components/models/Dokument.vue'
import DokumentVisning from '@/components/DokumentVisning.vue'
import DokumentVisningTG from '@/components/DokumentVisningTG.vue'
import { Action, Getter } from 'vuex-class'
import ToastUtil from '@/ToastUtil'

@Options({
  components: {
    DokumentVisningTG,
    DokumentVisning,
    Dokument
  }
})
export default class DokumentListe extends Vue {
  emitter = require('tiny-emitter/instance')

  @Getter('getContentfulTcoModel') contentfulTcoModel
  @Action('addToast') addToast

  @Prop({ type: String })
  path

  @Prop({ type: Boolean })
  tg

  @Prop({ type: String })
  status

  dokumenter: Dokument[] = []
  henter = ''
  ingenFundet = ''
  tomListeBesked = this.henter

  eventSubscriptions: Map<string, ((arg1: any) => void)[]> = new Map()

  created () : void {
    this.henter = this.contentfulTcoModel.findKlarTekst(this.tg ? 'tco.tgoverblik.dokumentliste.henter' : 'tco.ogoverblik.dokumentliste.henter')
    this.ingenFundet = this.contentfulTcoModel.findKlarTekst(this.tg ? 'tco.tgoverblik.dokumentliste.ingenfundet' : 'tco.ogoverblik.dokumentliste.ingenfundet')

    this.hentData()
    this.subscribeEvent('reloadDocuments', () => {
      this.hentData()
    })
  }

  hentData () : void {
    this.tomListeBesked = this.henter
    axios.get(process.env.VUE_APP_DIRIGENT_URL + this.path,
      { headers: { 'content-type': 'application/json' } }).then(
      response => {
        this.tomListeBesked = this.ingenFundet
        this.dokumenter = response.data
      }).catch(error => this.addToast(ToastUtil.createToastForErrorResponse(error, this.contentfulTcoModel)))
  }

  subscribeEvent (eventName: string, callbackFn: (arg1: any, arg2?: any, arg3?: any) => void): void {
    if (!this.eventSubscriptions.get(eventName)) {
      this.eventSubscriptions.set(eventName, [])
    }
    this.eventSubscriptions.get(eventName)!.push(callbackFn)
    this.emitter.on(eventName, callbackFn)
  }

  unmounted (): void {
    if (this.eventSubscriptions && this.eventSubscriptions.size > 0) {
      this.eventSubscriptions.forEach((callbacks, eventName) => {
        callbacks.forEach((eventHandler) => {
          this.emitter.off(eventName, eventHandler)
        })
      })
    }
    this.eventSubscriptions.clear()
  }
}
