import { ActionTree } from 'vuex'
import { State } from '.'
import { User } from 'oidc-client'
import ContentfulTcoContent from '@/components/models/ContentfulTcoContent'
import { ToastMessage } from '@/components/models/ToastMessage'

const actions: ActionTree<State, any> = {
  logout (context): void {
    context.commit('logout')
  },

  login (context, user: User): void {
    context.commit('login', user)
  },

  setContentfulTcoContent (context, contentfulTcoContent: ContentfulTcoContent): void {
    context.commit('setContentfulTcoContent', contentfulTcoContent)
  },

  setKlarTekstMap (context, klarTekstMap: Map<string, string>): void {
    context.commit('setKlarTekstMap', klarTekstMap)
  },

  addToast (context, toast: ToastMessage): void {
    context.commit('addToast', toast)
  },

  removeToast (context, id: string): void {
    context.commit('removeToast', id)
  },

  clearToasts (context): void {
    context.commit('clearToasts')
  },

  accessTokenExpiring (context): void {
    context.commit('accessTokenExpiring', true)
  },

  accessTokenExpired (context): void {
    context.commit('accessTokenExpiring', false)
  },

  setReturnUrlSessionRefresh (context, value): void {
    window.sessionStorage.setItem('returnUrlSessionRefresh', `${value}`)
    context.commit('setReturnUrlSessionRefresh', value)
  }
}

export default actions
