
import { Options, Vue } from 'vue-class-component'
import Chart from '@/components/charts/Chart.vue'
import { Chart as ChartJS, ChartData, ChartOptions, Plugin, TooltipItem } from 'chart.js'
import { Prop } from 'vue-property-decorator'
import { TCOComparisonPlugin, TCOLongLabelPlugin } from './ChartPlugins'
import { split } from 'cypress/types/lodash'

@Options({
  components: {
    Chart
  }
})
export default class TCOScoreProductComparisonChart extends Vue {
  @Prop()
  chartData: ChartData | null = null

  @Prop()
  chartTitle!: string

  @Prop()
  printMode = false;

  currentProductIndex = 1 // assuming this always contains at most 3 values

  chartOptions!: ChartOptions
  chartType = 'bar'

  chartPlugins: Plugin[] = [TCOComparisonPlugin, TCOLongLabelPlugin]

  created (): void {
    this.chartOptions = {
      layout: {
        padding: {
          top: 30
        }
      },
      datasets: {
        bar: {
          barPercentage: 0.5,
          categoryPercentage: 1
        }
      },
      plugins: {
        title: {
          display: this.chartTitle?.length > 0,
          text: this.chartTitle,
          font: {
            weight: 'bold',
            size: 18
          },
          padding: { bottom: 20 }
        },
        legend: {
          position: 'bottom',
          labels: {
            generateLabels: (chart: ChartJS) => {
              // Legend defaults to the first background color in the datasets but we want to use the middle one
              const labels = ChartJS.defaults.plugins.legend.labels.generateLabels(chart)
              labels.forEach((l, idx) => {
                const backgroundColors = chart.data?.datasets[idx]?.backgroundColor
                if (this.currentProductIndex >= 0 &&
                  backgroundColors &&
                  Array.isArray(backgroundColors) &&
                  backgroundColors.length > this.currentProductIndex) {
                  l.fillStyle = backgroundColors[this.currentProductIndex]
                }
              })

              return labels
            }
          }
        },
        datalabels: {
          align: 'end',
          anchor: 'end',
          clamp: true,
          display: function (context) {
            if (!context.chart.$tcoScore) return false
            const BILLIGSTE_PRODUCT_INDEX = context.chart.$tcoScore.minIndex
            const DYRESTE_PRODUCT_INDEX = context.chart.$tcoScore.maxIndex
            if (!context.chart.isDatasetVisible(context.datasetIndex)) return false
            if (context.dataIndex === DYRESTE_PRODUCT_INDEX || context.dataIndex === BILLIGSTE_PRODUCT_INDEX) {
              const maxVisibleDataset = context.chart.$tcoScore.maxVisibleDatasetIndex
              if (context.datasetIndex === maxVisibleDataset) {
                return true
              }
            }
            return false
          },
          formatter: function (value, context) {
            if (!context.chart.$tcoScore) return ''
            const BILLIGSTE_PRODUCT_INDEX = context.chart.$tcoScore.minIndex
            const DYRESTE_PRODUCT_INDEX = context.chart.$tcoScore.maxIndex
            if (context.dataIndex === DYRESTE_PRODUCT_INDEX) return 'Dyreste'
            else if (context.dataIndex === BILLIGSTE_PRODUCT_INDEX) return 'Billigste'
            else return ''
          }
        },
        tooltip: {
          callbacks: {
            title: (tooltip) => {
              let label = ''
              if (tooltip.length) {
                label = tooltip[0].label
              }

              if (label.length > TCOLongLabelPlugin.longLabelThreshold) {
                // Hack: long labels are split into an array of strings in TCOLongLabelPlugin but in the tooltip
                // chartjs has converted the array to string so essentially it will be a commaseparated string
                // this will not work correctly for long labels which contain comma
                label = label.replaceAll(',', '')
              }
              return label
            }
          }
        }
      },
      responsive: true,
      maintainAspectRatio: true,
      aspectRatio: 1.333, // 4:3 aspect ratio
      interaction: {
        mode: 'index'
      },
      scales: {
        x: {
          stacked: true,
          suggestedMax: 200,
          ticks: {
            font: {
              size: 14,
              weight: 'bold'
            }
          }
        },
        y: {
          stacked: true,
          title: {
            text: 'Kroner',
            display: true,
            font: {
              size: 14,
              weight: 'bold'
            },
            padding: {
              bottom: 10
            }
          }
        }
      }
    }
  }
}
