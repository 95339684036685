
import { Vue } from 'vue-class-component'
import { Prop, Watch } from 'vue-property-decorator'

export default class NumericInt extends Vue {
  // Input values coming from backend (eg. 0.0)
  @Prop()
  valueFromBackend = ''

  // Input values from user input (eg. 0,0). This is the value that is displayed in the input field.
  frontendValue = ''

  // Output to send to backend (eg 0.0)
  valueToBackend = ''

  @Watch('valueFromBackend', { deep: false })
  onBackendChanged () {
    this.frontendValue = this.valueFromBackend.replaceAll('.', ',')
  }

  @Watch('frontendValue', { deep: false })
  onFrontendChanged () {
    this.valueToBackend = this.frontendValue.replaceAll(',', '.')
    this.$emit('update:valueFromBackend', this.valueToBackend)
  }

  // Funktion der sikre at kun ',' og 0-9 kan tastes i nummer-felter
  kunNummer ($event : any) : void {
    const keyCode = ($event.keyCode ? $event.keyCode : $event.which)
    if ((keyCode < 48 || keyCode > 57) && keyCode !== 45) { // 44 is comma and 45 is minus
      $event.preventDefault()
    }
  }

  mounted () : void {
    // sets value manually when copying value from first item specification to another item specification in metadata
    if (this.valueFromBackend != null && this.valueFromBackend !== '') {
      this.onBackendChanged()
    }
  }
}
