
import { Options, Vue } from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import MicroModal from 'micromodal'
import KlarTekst from '@/components/KlarTekst.vue'
import ModalUtil from '@/components/util/ModalUtil'
import RichTekst from '@/components/RichTekst.vue'

@Options({
  components: {
    KlarTekst,
    RichTekst
  }
})
export default class OGEvaluatePrintModal extends Vue {
  @Prop()
  modalId

  @Prop()
  ogId!: string

  @Prop()
  sort?: string

  @Prop()
  sortOfferProduct?: string

  mounted () : void {
    MicroModal.init({
      onShow: modal => ModalUtil.dkfdsZIndexHack(true),
      onClose: modal => ModalUtil.dkfdsZIndexHack(false)
    })
  }

  printEvaluation (fullPrint: boolean) : void {
    const route = this.$router.resolve({ path: '/evalueringOG/print/' + this.ogId, query: { fullPrint: fullPrint + '', sort: this.sort, sortOfferProduct: this.sortOfferProduct } })
    window.open(route.href, '_blank')
    MicroModal.close(this.modalId)
  }
}
