
/* eslint-disable semi */
import { Getter } from 'vuex-class'
import { Vue } from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import { documentToHtmlString } from '@contentful/rich-text-html-renderer';
import { BLOCKS } from '@contentful/rich-text-types';

export default class extends Vue {
  renderOptions = {
    renderNode: {
      [BLOCKS.PARAGRAPH]: (node, next) =>
        `<span>${next(node.content)}<p></p></span>`
    }
  }

  @Prop()
  tekstNoegle = 'ukendt-noegle'

  @Prop()
  inFrontText = ''

  @Getter('getContentfulTcoModel') contentfulTcoModel

  richTekst = null

  richTextRendered = ''

  mounted () : void {
    this.richTekst = this.contentfulTcoModel.findRichTekst(this.tekstNoegle).json
    if (this.richTekst) {
      if (this.inFrontText && this.richTekst) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        this.richTextRendered = this.inFrontText + ' ' + documentToHtmlString(this.richTekst, this.renderOptions);
      } else {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        this.richTextRendered = documentToHtmlString(this.richTekst, this.renderOptions);
      }
    } else {
      this.richTextRendered = '<p>' + this.tekstNoegle + '</p>'
    }
  }
}
