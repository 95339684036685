
import { Chart as ChartJS, ChartData, ChartOptions, registerables, ChartType, Plugin, ChartItem } from 'chart.js'
import { Vue } from 'vue-class-component'
import { markRaw } from 'vue'
import { Prop, Ref, Watch } from 'vue-property-decorator'
import ChartDataLabels from 'chartjs-plugin-datalabels'

ChartJS.register(...registerables)
ChartJS.register(ChartDataLabels)

export default class Chart extends Vue {
  private static idx = 1

  @Prop({
    default: () => 'chart-' + (Chart.idx++)
  })
  chartId!: string

  @Prop()
  chartData!: ChartData

  @Prop({ default: 'bar' })
  chartType!: ChartType

  @Prop()
  options?: ChartOptions

  @Prop()
  chartPlugins?: Plugin[]

  @Prop()
  printMode?: boolean = false

  chart: ChartJS | null = null

  defaultOptions: ChartOptions = {
    responsive: true,
    maintainAspectRatio: true
  }

  @Ref('canvasRef')
  canvasRef!: HTMLCanvasElement;

  @Watch('chartData', { deep: true })
  onDataChange (): void {
    this.renderChart()
  }

  @Watch('options', { deep: true })
  onOptionChange (): void {
    this.renderChart()
  }

  private printMediaQuery = window.matchMedia('print')

  private resizeChart () : void {
    if (this.chart) {
      this.chart.resize()
    }
  }

  private mediaQueryListener (mql: MediaQueryListEvent) : void {
    if (mql.matches) {
      this.resizeChart()
    }
  }

  private beforePrintListener () : void {
    this.resizeChart()
  }

  beforeMount () : void {
    window.addEventListener('beforeprint', this.beforePrintListener)
    this.printMediaQuery.addListener(this.mediaQueryListener)
  }

  unmounted () : void {
    this.printMediaQuery.removeListener(this.mediaQueryListener)
    window.removeEventListener('beforeprint', this.beforePrintListener)
  }

  mounted (): void {
    this.renderChart()
  }

  beforeDestroy (): void {
    this.destroyChart()
  }

  renderChart (): void {
    this.destroyChart()
    const context = this.canvasRef?.getContext('2d') as ChartItem

    const options = this.options ? this.options : this.defaultOptions
    if (this.printMode) {
      options.animation = { duration: 0 }
    } else {
      options.animation = { duration: 1000, easing: 'easeOutQuart' }
    }

    this.chart = markRaw(new ChartJS(context, {
      type: this.chartType,
      data: this.chartData,
      options: this.options ? this.options : this.defaultOptions,
      plugins: this.chartPlugins ? this.chartPlugins : []
    }))
  }

  destroyChart (): void {
    if (this.chart) {
      this.chart.destroy()
    }
  }
}
