
import FormularFelt from '@/components/FormularFelt.vue'
import Opsummering from '@/components/Opsummering.vue'
import FeltModel from '@/components/models/FeltModel.vue'
import { Options, Vue } from 'vue-class-component'
import OGFormularModel from '@/components/models/OGFormularModel.vue'
import TrinModel from '@/components/models/TrinModel.vue'
import * as DKFDS from 'dkfds'
import axios from '@/services/axios-instance'
import MetadataDocument from '@/components/models/MetadataDocument'
import { DocumentStatus, FeltType } from '@/components/Const.ts'
import { Action, Getter } from 'vuex-class'
import { FormularFeltValidator, MandatoryValidator, MinMaxValidator, RegexValidator } from './FormularFeltValidator'
import KlarTekst from '@/components/KlarTekst.vue'
import ToastUtil from '@/ToastUtil'
import { ToastType } from './models/ToastMessage'
import FormularFeltUtil from './util/FormularFeltUtil'
import { Fejlbesked } from './models/Fejl'
import DateUtil from '@/DateUtil'
import FremskrivningResult from '@/components/models/FremskrivningResult'
import OGPublishReplaceModal from '@/components/OGPublishReplaceModal.vue'

@Options({
  components: {
    FormularFelt,
    Opsummering,
    KlarTekst,
    OGPublishReplaceModal
  },
  data () {
    return {
      FeltType
    }
  }
})
export default class OGFormular extends Vue {
  @Getter('getCurrentUser') currentUser
  @Getter('getContentfulTcoModel') contentfulTcoModel
  @Action('addToast') addToast

  @Getter('isOrdregiver') isOrdregiver
  emitter = require('tiny-emitter/instance')
  nuvaerendeTrin = 1
  nuvaerendeMaksTrin = 1
  samletAntalTrin = 0
  nuvaerendeFelter?: FeltModel[] = []
  formular: OGFormularModel = new OGFormularModel()
  maaGaaTilOpsummering = false
  redigerBlanket = false
  redigerPubliceretBlanket = false
  produktGrupper : MetadataDocument[] = []
  alleProduktGrupper : MetadataDocument[] = []
  path = ''
  title = ''
  gemPath = ''
  produktGruppeValgt = false
  blanketId
  publishInProgress = false
  isPublished = false
  isfirstValidation = true
  fremskrivningResult?: FremskrivningResult
  produktGruppeNavnValgt = ''

  feltValidators: Map<string, FormularFeltValidator[]> = new Map();
  feltReadOnly: Map<string, boolean> = new Map()

  eventSubscriptions: Map<string, ((arg1: any, arg2?: any) => void)[]> = new Map()

  hentProduktGrupper () : void {
    axios
      .get(process.env.VUE_APP_DIRIGENT_URL + '/web/form/alleAktiveMetadataList')
      .then(response => {
        this.produktGrupper = this.alleProduktGrupper = response.data
      })
      .catch(error => this.addToast(ToastUtil.createToastForErrorResponse(error, this.contentfulTcoModel)))
  }

  initBlanket (blanket : OGFormularModel) : void {
    this.unsubscribeEvents() // clean up events subscriptions - disse findes allerede hvis vi lige har valgt produktgruppe og er igang med reinitialisering
    this.formular = blanket
    this.samletAntalTrin = this.formular.trin?.length + 1 // +1 for at tilføje opsummering dummy
    // Tilføj opsummerings-dummy-trin
    if (this.formular.trin) {
      const opsummeringTrinModel = new TrinModel(this.contentfulTcoModel.findKlarTekst('tco.blanket.og-blanket.opsummering.overskrift'), this.samletAntalTrin)
      opsummeringTrinModel.undertitel = this.contentfulTcoModel.findKlarTekst('tco.blanket.og-blanket.opsummering.underoverskrift')
      const opsummeringBeskrivelse = this.contentfulTcoModel.findRichTekst('tco.blanket.og-blanket.opsummering.beskrivelse')

      if (opsummeringBeskrivelse && opsummeringBeskrivelse.json) {
        opsummeringTrinModel.beskrivelse = JSON.stringify(opsummeringBeskrivelse.json)
      }

      this.formular.trin.push(opsummeringTrinModel)
    }
    this.nuvaerendeFelter = this.formular.trin.filter(x => x.trin === this.nuvaerendeTrin)[0].felter

    if (this.formular.originalId != null && this.formular.originalId.length > 0) {
      this.redigerPubliceretBlanket = true
    }

    if (this.formular.status === 'published') {
      this.isPublished = true
    }

    // Lyt efter søskende-komponenters kald til redigerFelt
    this.subscribeEvent('redigerFelt', (arg1, arg2) => {
      this.redigerFelt(arg1, arg2)
    })
    this.subscribeEvent('felt-change', (felt) => {
      if (felt.tcoElementName === 'cac:TCOLine[1].cac:LineValidityPeriod.cbc:StartDate' ||
          felt.tcoElementName === 'cac:TCOLine[1].cbc:TCOPeriodQuantity' ||
          felt.tcoElementName === 'cac:TCOLine[1].cac:TCOItemPrice.cbc:TCOItemPriceDiscountingRatePercent') {
        this.lookupFremskrivningAfStandardVaerdier()
      } else if (felt.tcoElementName === 'cac:TCOLine[1].cac:TCOItemSpecification[1].cbc:TCOItemSpecificationCustomerConsumptionUnitPriceType') {
        const indexationPercentTypeFelt = this.findFelt('cac:TCOLine[1].cac:TCOItemSpecification[1].cbc:TCOItemSpecificationCustomerConsumptionIndexationPercentType')
        if (indexationPercentTypeFelt) {
          // keep indexationPercentType and unitPriceType in sync
          indexationPercentTypeFelt.vaerdi = felt.vaerdi
          if (felt.vaerdi === felt.jaRadioVaerdi || indexationPercentTypeFelt.nejInputTouched) {
            // kun aktiver validering hvis der er valgt standard eller hvis brugeren har rørt ved input feltet for brugerdefineret
            this.aktiverValidering([indexationPercentTypeFelt])
          }
        }
        this.lookupFremskrivningAfStandardVaerdier()
      } else if (felt.tcoElementName === 'cac:TCOLine[1].cac:TCOItemSpecification[1].cbc:TCOItemSpecificationCustomerConsumptionIndexationPercentType') {
        const unitPriceTypeFelt = this.findFelt('cac:TCOLine[1].cac:TCOItemSpecification[1].cbc:TCOItemSpecificationCustomerConsumptionUnitPriceType')
        if (unitPriceTypeFelt) {
          // keep indexationPercentType and unitPriceType in sync
          unitPriceTypeFelt.vaerdi = felt.vaerdi
          if (felt.vaerdi === felt.jaRadioVaerdi || unitPriceTypeFelt.nejInputTouched) {
            // kun aktiver validering hvis der er valgt standard eller hvis brugeren har rørt ved input feltet for brugerdefineret
            this.aktiverValidering([unitPriceTypeFelt])
          }
        }
      } else if (felt.tcoElementName === 'cac:TCOLine[1].cbc:CO2EmissionFactorInfluence') {
        var noInfluence = felt.vaerdi !== 'true'
        var felterValidering: FeltModel[] = []
        var co2EmissionQuantityFelt = this.findFelt('cac:TCOLine[1].cbc:CO2EmissionFactorQuantityType')
        var co2EmissionAmountFelt = this.findFelt('cac:TCOLine[1].cbc:CO2EmissionFactorAmountType')
        if (co2EmissionQuantityFelt) {
          co2EmissionQuantityFelt.felttype = noInfluence ? FeltType.HIDDEN : FeltType.RADIO_JA_NEJ_MED_INPUT
          co2EmissionQuantityFelt.erObligatorisk = !noInfluence
          felterValidering.push(co2EmissionQuantityFelt)
        }
        if (co2EmissionAmountFelt) {
          co2EmissionAmountFelt.felttype = noInfluence ? FeltType.HIDDEN : FeltType.RADIO_JA_NEJ_MED_INPUT
          co2EmissionAmountFelt.erObligatorisk = !noInfluence
          felterValidering.push(co2EmissionAmountFelt)
        }
        this.aktiverValidering(felterValidering)
      } else if (felt.tcoElementName === 'cac:ValidityPeriod.cbc:StartDate' ||
        felt.tcoElementName === 'cac:ValidityPeriod.cbc:StartTime' ||
        felt.tcoElementName === 'cac:ValidityPeriod.cbc:EndDate' ||
        felt.tcoElementName === 'cac:ValidityPeriod.cbc:EndTime') {
        // valider enddate og endtime når start/end date/time felterne ændre sig (pånær feltet selv da det allerede aktivere validering)
        var felter: FeltModel[] = []
        var endDateFelt = this.findFelt('cac:ValidityPeriod.cbc:EndDate')
        if (endDateFelt && (endDateFelt.vaerdi || endDateFelt.touched) && (endDateFelt.tcoElementName !== felt.tcoElementName)) {
          felter.push(endDateFelt)
        }

        var endTimeFelt = this.findFelt('cac:ValidityPeriod.cbc:EndTime')
        if (endTimeFelt && (endTimeFelt.vaerdi || endTimeFelt.touched) && (endTimeFelt.tcoElementName !== felt.tcoElementName)) {
          felter.push(endTimeFelt)
        }
        this.aktiverValidering(felter)
      } else if (felt.tcoElementName === 'cac:TCOLine[1].cac:TCOItemSpecification[1].cbc:TCOItemSpecificationCustomerConsumptionPercent' ||
                 felt.tcoElementName === 'cac:TCOLine[1].cac:TCOItemSpecification[2].cbc:TCOItemSpecificationCustomerConsumptionPercent' ||
                 felt.tcoElementName === 'cac:TCOLine[1].cac:TCOItemSpecification[3].cbc:TCOItemSpecificationCustomerConsumptionPercent' ||
                 felt.tcoElementName === 'cac:TCOLine[1].cac:TCOItemSpecification[4].cbc:TCOItemSpecificationCustomerConsumptionPercent') {
        var felterProcent: FeltModel[] = []
        const percentOn = this.findFelt('cac:TCOLine[1].cac:TCOItemSpecification[1].cbc:TCOItemSpecificationCustomerConsumptionPercent')
        if (percentOn) {
          felterProcent.push(percentOn)
        }
        const percentHibernation = this.findFelt('cac:TCOLine[1].cac:TCOItemSpecification[2].cbc:TCOItemSpecificationCustomerConsumptionPercent')
        if (percentHibernation) {
          felterProcent.push(percentHibernation)
        }
        const percentOff = this.findFelt('cac:TCOLine[1].cac:TCOItemSpecification[3].cbc:TCOItemSpecificationCustomerConsumptionPercent')
        if (percentOff) {
          felterProcent.push(percentOff)
        }
        const percentOther = this.findFelt('cac:TCOLine[1].cac:TCOItemSpecification[4].cbc:TCOItemSpecificationCustomerConsumptionPercent')
        if (percentOther) {
          felterProcent.push(percentOther)
        }
        this.aktiverValidering(felterProcent)
      }
    })

    this.subscribeEvent('initProduktGruppe', async (arg1) => this.assignProductGroup(arg1))

    this.hentProduktGrupper()

    const tab = new DKFDS.Tabnav(document.getElementById('tabs')) // NOSONAR

    const validitetsperiodeValidator = this.validitetsperiodeGyldighedValidator()
    this.feltValidators.set('cac:ValidityPeriod.cbc:EndDate', [validitetsperiodeValidator])
    this.feltValidators.set('cac:ValidityPeriod.cbc:EndTime', [validitetsperiodeValidator])

    const percentageValidator = this.CalculateTotalPercent()
    this.feltValidators.set('cac:TCOLine[1].cac:TCOItemSpecification[1].cbc:TCOItemSpecificationCustomerConsumptionPercent', [percentageValidator])
    this.feltValidators.set('cac:TCOLine[1].cac:TCOItemSpecification[2].cbc:TCOItemSpecificationCustomerConsumptionPercent', [percentageValidator])
    this.feltValidators.set('cac:TCOLine[1].cac:TCOItemSpecification[3].cbc:TCOItemSpecificationCustomerConsumptionPercent', [percentageValidator])
    this.feltValidators.set('cac:TCOLine[1].cac:TCOItemSpecification[4].cbc:TCOItemSpecificationCustomerConsumptionPercent', [percentageValidator])

    const customerSpecificUnitPriceValidator = this.customerSpecificConsumptionUnitPriceValidator()
    this.feltValidators.set('cac:TCOLine[1].cac:TCOItemSpecification[1].cbc:TCOItemSpecificationCustomerConsumptionUnitPriceType', [customerSpecificUnitPriceValidator])

    if (this.formular.status === DocumentStatus.PUBLISHED || this.redigerPubliceretBlanket) {
      this.formular.trin[0].felter.filter(f => f.felttype === FeltType.DATO)
        .map(f => {
          f.minDato = ''
        })
    }
  }

  assignProductGroup (product) {
    this.produktGruppeNavnValgt = product
  }

  created () : void {
    if (!this.isOrdregiver) {
      this.$router.replace('/')
      return
    }
    this.path = '/web/form/getForm'
    this.title = this.contentfulTcoModel.findKlarTekst('tco.blanket.og-blanket.overskrift')
    this.blanketId = this.$route.params.id as string
    if (this.blanketId) {
      axios.post(process.env.VUE_APP_DIRIGENT_URL + this.path + '/' + this.blanketId + '?updateContactInfo=true',
        '',
        { headers: { 'content-type': 'application/json' } }).then(
        response => {
          this.redigerBlanket = true
          this.initBlanket(response.data)
          // check if product group is selected
          const tempFormular : OGFormularModel = response.data
          if (tempFormular.natureCode != null && tempFormular.natureCode !== '') {
            this.produktGruppeValgt = true
            this.produktGruppeNavnValgt = tempFormular.natureCode
          }
        }).catch(error => this.addToast(ToastUtil.createToastForErrorResponse(error, this.contentfulTcoModel)))
    } else {
      axios.post(process.env.VUE_APP_DIRIGENT_URL + this.path,
        '',
        { headers: { 'content-type': 'application/json' } }).then(
        response => {
          this.initBlanket(response.data)
        }).catch(error => this.addToast(ToastUtil.createToastForErrorResponse(error, this.contentfulTcoModel)))
    }
  }

  mounted () : void {
    const tab = new DKFDS.Tabnav(document.getElementById('tabs')) // NOSONAR
    const tabpanelElement = document.getElementById('tab_kontaktinfo')
    if (tabpanelElement != null) {
      tabpanelElement.focus()
    }
  }

  scrollToTop () {
    window.scrollTo(0, 0)
  }

  async initProduktGruppe (id : string) : Promise<void> {
    if (!this.produktGruppeValgt) {
      await axios.post(process.env.VUE_APP_DIRIGENT_URL + '/web/form/tilfoejmetadata/OG/' + id,
        this.hentJsonFeltData(),
        { headers: { 'content-type': 'application/json' } }).then(
        response => {
          this.initBlanket(response.data)
          this.produktGruppeValgt = true
          const felter: FeltModel[] = []
          this.formular.trin.filter(x => x.trin < this.samletAntalTrin).forEach(x => {
            if (x.felter != null) {
              x.felter.forEach(f => felter.push(f))
            }
          })
          this.aktiverValidering(felter)
        }
      ).catch(error => this.addToast(ToastUtil.createToastForErrorResponse(error, this.contentfulTcoModel)))

      this.aktiverValidering(this.formular.contactInfo?.felter)
      this.skiftTilUdbudsInfo()
      this.skiftTrin(1)
    } else {
      this.skiftTrin(1)
    }
  }

  redigerFelt (feltId: string, trinNummer : number) : void {
    // Kontaktoplysninger
    if (trinNummer === 0) {
      this.skiftTilKontaktOplysninger()
    } else {
      this.skiftTrin(trinNummer, true)
    }

    setTimeout(() => { // hack til at afvente ovenstående kald
      const input = document.getElementById(feltId)
      if (input) input.focus()
    }, 0)
  }

  TjekFejlOpsummering () : boolean {
    if (this.nuvaerendeTrin === this.samletAntalTrin) {
      for (let i = 0; i < this.samletAntalTrin; i++) {
        if (this.hentFejl(i).length > 0 || this.hentFejlContactInfo().length > 0) {
          return true
        }
      }
    }
    return false
  }

  /**
   * @param trinNummer (valgfri) Hvis der angives et trin nummer, hentes fejl for det pågældende trin, eller hentes alle fejl i formularen
   */
  hentFejl (trinNummer? : number) : Fejlbesked[] {
    const fejl: Fejlbesked[] = []
    const trin = trinNummer != null && this.formular.trin && this.formular.trin.filter(x => x.trin === trinNummer)[0] ? this.formular.trin.filter(x => x.trin === trinNummer)[0] : new TrinModel()
    const felter = trinNummer != null ? trin.felter?.filter(y => !y.erValid && y.felttype !== FeltType.HIDDEN) : []
    if (typeof trinNummer === 'undefined' || trinNummer == null) {
      this.formular.trin.forEach(value => {
        if (value.felter) {
          value.felter.filter(y => !y.erValid && y.felttype !== FeltType.HIDDEN).forEach(felt => felter.push(felt))
        }
      })
    }
    if (felter) {
      felter.forEach(felt => {
        const feltId = felt.tcoElementName != null && felt.tcoElementName !== '' ? felt.tcoElementName : 'produktgruppeVaelger'
        if (felt.fejl) {
          felt.fejl.forEach(f => {
            const error = { tcoElementName: feltId, fejlbesked: `${felt.maerkat}: ${f}` }
            fejl.push(error)
          })
        }
      })
    }
    return fejl
  }

  hentFejlContactInfo () : Fejlbesked[] {
    const fejl: Fejlbesked[] = []
    const trin = this.formular.contactInfo
    const felter = trin.felter?.filter(y => !y.erValid && y.felttype !== FeltType.HIDDEN)
    if (felter) {
      felter.forEach(felt => {
        const feltId = felt.tcoElementName != null && felt.tcoElementName !== '' ? felt.tcoElementName : 'produktgruppeVaelger'
        if (felt.fejl) {
          felt.fejl.forEach(f => {
            const error = { tcoElementName: feltId, fejlbesked: `${felt.maerkat}: ${f}` }
            fejl.push(error)
          })
        }
      })
    }
    return fejl
  }

  skiftTrin (value: number, erSpecifik? : boolean) : void {
    this.gemKladde(true)
    if (this.nuvaerendeTrin === 1 && value === -1) {
      this.skiftTilKontaktOplysninger()
      return
    }

    if (this.nuvaerendeFelter) {
      this.aktiverValidering(this.nuvaerendeFelter)
    }

    this.hentFejl(this.nuvaerendeTrin)

    // Trinskift kun tilladt hvis der er valgt produktgruppe
    if (this.produktGruppeValgt) {
      // set default start time if not selected
      this.resetTime('cac:ValidityPeriod.cbc:StartTime')
      // set default end time if not selected
      this.resetTime('cac:ValidityPeriod.cbc:EndTime')

      if (!erSpecifik) {
        this.nuvaerendeTrin += value

        if (this.nuvaerendeTrin > this.nuvaerendeMaksTrin) {
          this.nuvaerendeMaksTrin = this.nuvaerendeTrin
        }
      } else {
        this.nuvaerendeTrin = value

        if (this.nuvaerendeTrin > this.nuvaerendeMaksTrin) {
          this.nuvaerendeMaksTrin = this.nuvaerendeTrin
        }
      }

      // Hvis vi tilgår Opsummeringen
      if (this.nuvaerendeTrin === this.samletAntalTrin) {
        this.maaGaaTilOpsummering = true
      }

      // Forhindre evt. dobbelt klik på Tilbage ved trin 2 (ingen trin før 1)
      if (this.nuvaerendeTrin < 1) {
        this.nuvaerendeTrin = 1
      }
    }
    // on next click we need to ensure that mandatory fields not have been skipped.
    if (this.nuvaerendeFelter) {
      for (const felt of this.nuvaerendeFelter) {
        if (felt.erObligatorisk && (felt.vaerdi == null || felt.vaerdi === '')) {
          felt.erValid = false
        }
      }
    }

    this.nuvaerendeFelter = this.formular.trin.filter(x => x.trin === this.nuvaerendeTrin)[0]?.felter

    if (this.blanketId) {
      // validate all fields when editing an existing formular, up to max trin
      this.formular.contactInfo.felter.forEach((f) => this.erValid(f))
      this.formular.trin.filter(t => t.trin < this.nuvaerendeMaksTrin).forEach((t) => t.felter.forEach((f) => this.erValid(f)))
    }
    const tilbageKnap = document.getElementById('tilbageKnap')
    if (tilbageKnap != null) {
      tilbageKnap.focus()
    }
  }

  skiftTilUdbudsInfo () : void {
    const produktTab = document.getElementById('tab_udbudsinfo')
    if (produktTab) {
      produktTab.click()
    }
  }

  // this function helps the trin guide to make sure that it shows error symbols if not mandatory field are filled out yet.
  isMandatoryFilled (trinNumber: number) : boolean {
    const fieldsInTrin = this.formular.trin.filter(t => t.trin === trinNumber)[0]
    let isMandatoryFilled = true
    if (fieldsInTrin.felter != null) {
      for (const f of fieldsInTrin.felter) {
        if (f.erObligatorisk && (f.vaerdi == null || f.vaerdi === '')) {
          isMandatoryFilled = false
          break
        }
      }
    }
    return isMandatoryFilled
  }

  hentNuvaerendeTrin () : TrinModel {
    return this.formular.trin ? this.formular.trin.filter(x => x.trin === this.nuvaerendeTrin)[0] : new TrinModel('1', 1)
  }

  hentNuvaerendeTrinBeskrivelse () : string {
    const trinModel = this.hentNuvaerendeTrin()
    return this.contentfulTcoModel.renderRichTekstFromJson(trinModel?.beskrivelse)
  }

  gemKladde (autoSaveDraft: boolean) : void {
    /* Oprettelse af kladde på en publiceret blanket */
    if (this.redigerBlanket && this.isPublished && this.formular.status === 'published') {
      if (autoSaveDraft) {
        this.gemFormular('/web/form/gemKladdeUdbud/' + this.blanketId + '/' + this.formular.id, false)
        return
      } /* automatisk gem af en publiceret blankets kladde */
    } else if (this.redigerPubliceretBlanket) {
      if (autoSaveDraft) {
        this.gemFormular('/web/form/gemKladdeUdbud/' + this.blanketId + '/' + this.formular.originalId, false)
        return
      } else {
        this.gemFormular('/web/form/gemKladdeUdbud/' + this.blanketId + '/' + this.formular.originalId, true)
        return
      }
    }
    if (this.redigerBlanket && !this.redigerPubliceretBlanket) {
      if (!(this.formular.status === 'published')) {
        if (autoSaveDraft) {
          this.gemFormular('/web/form/gemKladdeUdbud/' + this.blanketId, false)
        } else {
          this.gemFormular('/web/form/gemKladdeUdbud/' + this.blanketId, true)
        }
      }
    } else {
      if (!(this.formular.status === 'published')) {
        if (autoSaveDraft) {
          this.gemFormular('/web/form/gemKladdeUdbud', false)
        } else {
          this.gemFormular('/web/form/gemKladdeUdbud', true)
        }
      }
      this.redigerBlanket = true
    }
  }

  gemOgPublicer (id) : void {
    this.publishInProgress = true
    this.isPublished = true
    this.gemFormular('/web/form/publicerUdbud/' + id, false)
  }

  opdaterOgPublicer () : void {
    this.publishInProgress = true
    this.gemFormular('/web/form/opdaterUdbud/' + this.blanketId, false)
  }

  gemFormular (path : string, saveDraftNotification : boolean) : void {
    // POST request using axios
    let responseId

    axios.post(process.env.VUE_APP_DIRIGENT_URL + path, this.hentJsonFeltData(),
      {
        headers: {
          'content-type': 'application/json'
        }
      }).then(response => {
      responseId = response.data
      if (!path.includes('gemKladde')) {
        const udbudsNavn = this.formular.trin[0].felter[0].vaerdi.toString()
        const udbudsNummer = this.formular.trin[0].felter[2].vaerdi.toString()
        this.$router.push({
          name: 'Kvittering',
          params: { formularID: responseId.toString(), name: udbudsNavn, number: udbudsNummer }
        })
      } else {
        this.blanketId = responseId
        if (response.status === 200) {
          if (saveDraftNotification) {
            const headline = this.contentfulTcoModel.findKlarTekst('tco.blanket.og-blanket.gemkladde.success.overskrift')
            const text = this.contentfulTcoModel.findKlarTekst('tco.blanket.og-blanket.gemkladde.success.beskrivelse')
            const autodismiss = false
            this.addToast({ headline: headline, text: text, type: ToastType.SUCCESS, autoDismiss: autodismiss })
          }
        }
      }
    }).catch(error => {
      this.publishInProgress = false
      this.addToast(ToastUtil.createToastForErrorResponse(error, this.contentfulTcoModel))
    })
  }

  hentJsonFeltData () : string {
    return JSON.stringify(this.formular, null, 2)
  }

  // Funktion til at aktivere valideringen ved at fjerne "null" state for feltet og erstatte det med ""
  aktiverValidering (felter : FeltModel[]) : void {
    if (!this.isfirstValidation) {
      felter.forEach(felt => {
        // var domId = this.genererDomId(tcoElementName)
        if (felt.vaerdi == null || felt.vaerdi === '') {
          felt.vaerdi = ''
          this.emitter.emit('opdaterFeltValiditet', felt.tcoElementName)
        } else if (felt.felttype === FeltType.DATO) { // force revalidation af dato felter da de ikke virker automatisk on blur pga. DKFDS
          this.emitter.emit('opdaterFeltValiditet', felt.tcoElementName)
        } else {
          this.emitter.emit('opdaterFeltValiditet', felt.tcoElementName)
        }
      })
    }
    this.isfirstValidation = false
  }

  skiftTilKontaktOplysninger () : void {
    const kontaktTab = document.getElementById('tab_kontaktinfo')
    if (kontaktTab) {
      kontaktTab.click()
    }
  }

  CalculateTotalPercent (): FormularFeltValidator {
    return {
      validate: (felt: FeltModel) => {
        let sum = 0
        const fejl: string[] = []
        const percentOn = this.findFelt('cac:TCOLine[1].cac:TCOItemSpecification[1].cbc:TCOItemSpecificationCustomerConsumptionPercent')
        if (percentOn) {
          sum += parseFloat(percentOn.vaerdi)
        }
        const percentHibernation = this.findFelt('cac:TCOLine[1].cac:TCOItemSpecification[2].cbc:TCOItemSpecificationCustomerConsumptionPercent')
        if (percentHibernation) {
          sum += parseFloat(percentHibernation.vaerdi)
        }
        const percentOff = this.findFelt('cac:TCOLine[1].cac:TCOItemSpecification[3].cbc:TCOItemSpecificationCustomerConsumptionPercent')
        if (percentOff) {
          sum += parseFloat(percentOff.vaerdi)
        }
        const percentOther = this.findFelt('cac:TCOLine[1].cac:TCOItemSpecification[4].cbc:TCOItemSpecificationCustomerConsumptionPercent')
        if (percentOther) {
          sum += parseFloat(percentOther.vaerdi)
        }
        if (sum > 100 || sum < 100) {
          fejl.push(this.contentfulTcoModel.findKlarTekst('tco.blanket.og-blanket.procentbemærkning'))
        }
        return fejl
      }
    }
  }

  validitetsperiodeGyldighedValidator (): FormularFeltValidator {
    return {
      validate: (felt: FeltModel) => {
        const fejl: string[] = []
        const startDate = this.findFelt('cac:ValidityPeriod.cbc:StartDate')
        const startTime = this.findFelt('cac:ValidityPeriod.cbc:StartTime')
        const endDate = this.findFelt('cac:ValidityPeriod.cbc:EndDate')
        const endTime = this.findFelt('cac:ValidityPeriod.cbc:EndTime')

        if (startDate && endDate) {
          const start = this.parseDate(startDate.vaerdi, startTime?.vaerdi)
          const end = this.parseDate(endDate.vaerdi, endTime?.vaerdi)

          if (end < start) {
            fejl.push(this.contentfulTcoModel.findKlarTekst('tco.blanket.og-blanket.tilbudsfrist-foer-start'))
          }
        }
        return fejl
      }
    }
  }

  private parseDate (dateString: string, timeString?: string): Date {
    const str = dateString + 'T' + (timeString || '00:00:00')
    return new Date(str)
  }

  private findFelt (tcoElementName: string): FeltModel | null {
    if (!this.formular || !this.formular.trin) {
      return null
    }
    if (tcoElementName) {
      for (const trin of this.formular.trin) {
        if (trin.felter) {
          const felt = trin.felter.find(x => x.tcoElementName === tcoElementName)
          if (felt) {
            return felt
          }
        }
      }
    }
    return null
  }

  private isFeltDisabled (felt: FeltModel): boolean {
    if (felt && felt.tcoElementName) {
      // for published OG disabled start date + time fields if they are before now
      if (this.formular.status === DocumentStatus.PUBLISHED &&
        (felt.tcoElementName === 'cac:ValidityPeriod.cbc:StartDate' || felt.tcoElementName === 'cac:ValidityPeriod.cbc:StartTime')) {
        return !this.isFutureStartDate()
      } else if (this.redigerPubliceretBlanket &&
        (felt.tcoElementName === 'cac:ValidityPeriod.cbc:StartDate' || felt.tcoElementName === 'cac:ValidityPeriod.cbc:StartTime')) {
        return !this.isFutureStartDate()
      }
      return this.feltReadOnly.get(felt.tcoElementName) || false
    }
    return false
  }

  private isFutureStartDate (): boolean {
    const startDateFelt = this.findFelt('cac:ValidityPeriod.cbc:StartDate')
    const startTimeFelt = this.findFelt('cac:ValidityPeriod.cbc:StartTime')
    if (startDateFelt && startDateFelt.vaerdi) {
      const startDate = this.parseDate(startDateFelt.vaerdi, startTimeFelt?.vaerdi)
      return startDate > new Date()
    }
    return false
  }

  private resetTime (name: string): void {
    const timeFelt = this.findFelt(name)
    if (timeFelt && timeFelt.vaerdi === '') {
      timeFelt.vaerdi = '00:00:00'
    }
  }

  private defaultFeltValidators (): FormularFeltValidator[] {
    const validators: FormularFeltValidator[] = []
    validators.push(new MandatoryValidator(this.contentfulTcoModel))
    validators.push(new MinMaxValidator(this.contentfulTcoModel))
    validators.push(new RegexValidator(this.contentfulTcoModel))
    return validators
  }

  erValid (felt: FeltModel) : void { // NOSONAR
    felt.fejl = []
    felt.touched = true
    felt.erValid = true

    // disable date validation if document is published and startdate is before now
    if (this.formular.status === DocumentStatus.PUBLISHED &&
      (felt.tcoElementName === 'cac:ValidityPeriod.cbc:StartDate' || felt.tcoElementName === 'cac:ValidityPeriod.cbc:StartTime') &&
      !this.isFutureStartDate()) {
      return
    }

    // disable date validation if document is a draft of a published document and startdate is before now
    if (this.redigerPubliceretBlanket &&
      (felt.tcoElementName === 'cac:ValidityPeriod.cbc:StartDate' || felt.tcoElementName === 'cac:ValidityPeriod.cbc:StartTime') &&
      !this.isFutureStartDate()) {
      return
    }

    const defaultValidators = this.defaultFeltValidators()

    for (const validator of defaultValidators) {
      const fejl = validator.validate(felt)
      if (fejl && fejl.length > 0) {
        this.tilfoejFejl(felt, fejl)
        felt.erValid = false
      }
    }

    const validators = this.feltValidators.get(felt.tcoElementName)
    if (validators && validators.length) {
      for (const validator of validators) {
        const fejl = validator.validate(felt)
        if (fejl && fejl.length > 0) {
          this.tilfoejFejl(felt, fejl)
          felt.erValid = false
        }
      }
    }
  }

  tilfoejFejl (felt: FeltModel, nyeFejl: string[]): void {
    if (nyeFejl && nyeFejl.length > 0) {
      nyeFejl.forEach(nf => {
        if (felt.fejl.findIndex(f => f === nf) < 0) {
          felt.fejl.push(nf)
        }
      })
    }
  }

  subscribeEvent (eventName: string, callbackFn: (arg1: any, arg2?: any) => void): void {
    if (!this.eventSubscriptions.get(eventName)) {
      this.eventSubscriptions.set(eventName, [])
    }
    this.eventSubscriptions.get(eventName)!.push(callbackFn)
    this.emitter.on(eventName, callbackFn)
  }

  unmounted (): void {
    this.unsubscribeEvents()
  }

  unsubscribeEvents (): void {
    if (this.eventSubscriptions && this.eventSubscriptions.size > 0) {
      this.eventSubscriptions.forEach((callbacks, eventName) => {
        callbacks.forEach((eventHandler) => {
          this.emitter.off(eventName, eventHandler)
        })
      })
    }
    this.eventSubscriptions.clear()
  }

  genererDomId (tcoElementName: string): string {
    const felt = this.findFelt(tcoElementName)
    if (felt?.felttype === FeltType.RADIO_JA_NEJ_MED_INPUT) {
      if (felt.vaerdi === felt.jaRadioVaerdi) {
        return FormularFeltUtil.escapeForDomId('ja-input-' + felt.jaInputTcoElementName)
      } else {
        return FormularFeltUtil.escapeForDomId('nej-input-' + felt.nejInputTcoElementName)
      }
    }
    return FormularFeltUtil.escapeForDomId(tcoElementName)
  }

  printOpsummering () : void {
    const route = this.$router.resolve({ path: '/blanketprint/' + this.blanketId })
    window.open(route.href, '_blank')
  }

  lookupFremskrivningAfStandardVaerdier (): void {
    var startAarFelt = this.findFelt('cac:TCOLine[1].cac:LineValidityPeriod.cbc:StartDate')
    var periodFelt = this.findFelt('cac:TCOLine[1].cbc:TCOPeriodQuantity')
    var diskonteringsrenteFelt = this.findFelt('cac:TCOLine[1].cac:TCOItemPrice.cbc:TCOItemPriceDiscountingRatePercent')
    var bruttoElprisFelt = this.findFelt('cac:TCOLine[1].cac:TCOItemSpecification[1].cbc:TCOItemSpecificationCustomerConsumptionUnitPriceType')

    if (startAarFelt && startAarFelt?.vaerdi && periodFelt && periodFelt?.vaerdi && diskonteringsrenteFelt && diskonteringsrenteFelt?.vaerdi) {
      var startAar = DateUtil.getYear(startAarFelt.vaerdi)
      var antalAar = periodFelt.vaerdi
      var diskonteringsrente = diskonteringsrenteFelt.vaerdi

      const queryParams: any = {}
      queryParams.startAar = startAar
      queryParams.antalAar = antalAar
      queryParams.diskonteringsrente = diskonteringsrente
      if (bruttoElprisFelt && bruttoElprisFelt.vaerdi === bruttoElprisFelt.nejRadioVaerdi && bruttoElprisFelt.nejInputVaerdi) {
        // hvis bruger har tilføjet brugerspecifikt brutto elpris
        queryParams.customerSpecificBruttoElpris = bruttoElprisFelt.nejInputVaerdi
      }

      axios.get(process.env.VUE_APP_DIRIGENT_URL + '/web/form/fremskrivning', { params: queryParams })
        .then(response => {
          this.fremskrivningResult = response.data

          if (this.fremskrivningResult) {
            const felter: FeltModel[] = []
            const co2EmissionQuantityFelt = this.findFelt('cac:TCOLine[1].cbc:CO2EmissionFactorQuantityType')
            if (co2EmissionQuantityFelt) {
              co2EmissionQuantityFelt.jaInputVaerdi = this.fremskrivningResult.co2EmissionFactorQuantity + ''
              felter.push(co2EmissionQuantityFelt)
            }

            const co2EmissionQuantityDiscountedFelt = this.findFelt('cac:TCOLine[1].cbc:CO2EmissionFactorQuantityDiscounted')
            if (co2EmissionQuantityDiscountedFelt) {
              co2EmissionQuantityDiscountedFelt.vaerdi = this.fremskrivningResult.co2EmissionFactorQuantityDiscounted + ''
              felter.push(co2EmissionQuantityDiscountedFelt)
            }

            const unitPriceFelt = this.findFelt('cac:TCOLine[1].cac:TCOItemSpecification[1].cbc:TCOItemSpecificationCustomerConsumptionUnitPriceType')
            if (unitPriceFelt) {
              unitPriceFelt.jaInputVaerdi = this.fremskrivningResult.tcoItemSpecificationCustomerConsumptionUnitPrice + ''
              // aktiver kun validering på standard brutto elpris hvis der pt. er valgt at bruge standard værdi
              if (unitPriceFelt.vaerdi === unitPriceFelt.jaRadioVaerdi) {
                felter.push(unitPriceFelt)
              }
            }

            const taxFelt = this.findFelt('cac:TCOLine[1].cac:TCOItemSpecification[1].cbc:TCOItemSpecificationCustomerConsumptionUnitPriceTax')
            if (taxFelt) {
              taxFelt.vaerdi = this.fremskrivningResult.tcoItemSpecificationCustomerConsumptionUnitPriceTax + ''
              felter.push(taxFelt)
            }

            const netFelt = this.findFelt('cac:TCOLine[1].cac:TCOItemSpecification[1].cbc:TCOItemSpecificationCustomerConsumptionUnitPriceNet')
            if (netFelt) {
              netFelt.vaerdi = this.fremskrivningResult.tcoItemSpecificationCustomerConsumptionUnitPriceNetFirstYear + ''
              felter.push(netFelt)
            }

            if (felter.length > 0) {
              this.aktiverValidering(felter)
            }
          }
        })
        .catch(error => {
          this.addToast(ToastUtil.createToastForErrorResponse(error, this.contentfulTcoModel))
        })
    }
  }

  customerSpecificConsumptionUnitPriceValidator (): FormularFeltValidator {
    return {
      validate: (felt: FeltModel) => {
        const fejl: string[] = []
        if (felt.tcoElementName === 'cac:TCOLine[1].cac:TCOItemSpecification[1].cbc:TCOItemSpecificationCustomerConsumptionUnitPriceType' && felt.vaerdi === felt.nejRadioVaerdi) {
          const taxFelt = this.findFelt('cac:TCOLine[1].cac:TCOItemSpecification[1].cbc:TCOItemSpecificationCustomerConsumptionUnitPriceTax')
          if (taxFelt && taxFelt.vaerdi && felt.nejInputVaerdi && Number(felt.nejInputVaerdi) <= Number(taxFelt.vaerdi)) {
            const msg = this.contentfulTcoModel.findKlarTekst('tco.blanket.og-blanket.elpris-mindre-end-afgift')
            const formattedTax = Number(taxFelt.vaerdi).toLocaleString('da-DK', { minimumFractionDigits: 0, maximumFractionDigits: 4 })
            fejl.push(msg.replaceAll('{0}', formattedTax))
          }
        }
        return fejl
      }
    }
  }
}
