import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  id: "formContainer",
  class: "container page-container"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TGDetails = _resolveComponent("TGDetails")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_TGDetails, {
      formular: this.formular,
      printMode: this.printMode,
      noContactInfo: this.noContactInfo,
      blanketId: this.blanketId
    }, null, 8, ["formular", "printMode", "noContactInfo", "blanketId"])
  ]))
}