
import { Options, Vue } from 'vue-class-component'
import OGFormularModel from '@/components/models/OGFormularModel.vue'
import Opsummering from '@/components/Opsummering.vue'
import KlarTekst from '@/components/KlarTekst.vue'
import axios from '@/services/axios-instance'
import { DocumentStatus, FeltType } from '@/components/Const'
import { Action, Getter } from 'vuex-class'
import ToastUtil from '@/ToastUtil'

@Options({
  components: {
    Opsummering,
    OGFormularModel,
    KlarTekst
  },
  computed: {
    isPublished () {
      return this.formular.status === DocumentStatus.PUBLISHED
    },
    isDraft () {
      return this.formular.status === DocumentStatus.DRAFT
    },
    isClosed () {
      return this.formular.status === DocumentStatus.CLOSED
    },
    isPublishedDraft () {
      return this.formular.originalId
    }
  },
  data () {
    return {
      FeltType
    }
  }
})
export default class PrintOpsummering extends Vue {
  @Getter('getContentfulTcoModel') contentfulTcoModel
  @Action('addToast') addToast

  blanketId = ''
  formular = new OGFormularModel()
  path = ''

  overskrift = ''
  underoverskrift = ''
  beskrivelse

  created () : void {
    this.overskrift = this.contentfulTcoModel.findKlarTekst('tco.blanket.og-blanket.opsummering.overskrift')
    this.underoverskrift = this.contentfulTcoModel.findKlarTekst('tco.blanket.og-blanket.opsummering.underoverskrift')
    const beskrivelse = this.contentfulTcoModel.findRichTekst('tco.blanket.og-blanket.opsummering.beskrivelse')
    if (beskrivelse && beskrivelse.json) {
      this.beskrivelse = this.contentfulTcoModel.renderRichTekstFromJson(JSON.stringify(beskrivelse.json))
    }

    this.path = '/web/form/getForm/'
    this.blanketId = this.$route.params.id as string

    if (this.blanketId) {
      axios.post(process.env.VUE_APP_DIRIGENT_URL + this.path + this.blanketId,
        '',
        { headers: { 'content-type': 'application/json' } }).then(
        response => {
          this.formular = response.data
          this.setDocumentTitle()
          if (window && !((window as any).Cypress)) {
            // only open print dialogue if cypress is not running
            setTimeout(() => window!.print(), 300)
            setTimeout(() => window!.close(), 300)
          }
        }).catch(error => this.addToast(ToastUtil.createToastForErrorResponse(error, this.contentfulTcoModel)))
    }
  }

  beforeUnmount () : void {
    // Reset document title since we haven't implemented it on all pages
    document.title = 'TCO'
  }

  private setDocumentTitle () : void {
    let title = 'Opsummering af indkøb'
    const nameField = this.formular.trin[0].felter.filter(x => x.tcoElementName === 'cbc:Name')
    if (nameField.length > 0) {
      title += ' ' + nameField[0].vaerdi
    }
    document.title = title + ' | TCO'
  }
}
