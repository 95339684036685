
import FeltModel from '@/components/models/FeltModel.vue'
export default class TrinModel {
  trin = 1
  overskrift = ''
  undertitel = ''
  beskrivelse = ''
  felter: FeltModel[] = []

  constructor (overskrift? : string, trinNummer? : number) {
    if (overskrift) {
      this.overskrift = overskrift
    }
    if (trinNummer) {
      this.trin = trinNummer
    }
  }
}
