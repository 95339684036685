import { createStore } from 'vuex'
import * as getters from './getters'
import * as mutations from './mutations'
import actions from './actions'
import { VuexOidcClientSettings, vuexOidcCreateStoreModule } from 'vuex-oidc'
import { oidcConfig } from '@/config/oidc'
import { accessTokenExpiringEvent, accessTokenExpiredEvent, loginEvent, logoutEvent } from './AuthenticationEventHandler'
import { User } from 'oidc-client'
import ContentfulTcoContent from '@/components/models/ContentfulTcoContent'
import { ToastMessage } from '@/components/models/ToastMessage'

export interface State {
  currentUser: User;
  contentfulTcoModel: ContentfulTcoContent;
  klarTekstMap: Map<string, string>;
  toastMessages: ToastMessage[]
  nextToastId: number,
  accessTokenExpiring: boolean
}

export default createStore<State>({
  strict: process.env.NODE_ENV !== 'production',

  state: {
    currentUser: {} as User,
    contentfulTcoModel: {} as ContentfulTcoContent,
    klarTekstMap: new Map<string, string>(),
    toastMessages: [],
    nextToastId: 1,
    accessTokenExpiring: false
  },
  getters,
  mutations,
  actions,

  modules: {
    oidcStore: vuexOidcCreateStoreModule(
      oidcConfig.oidcSettings as VuexOidcClientSettings,
      {
        namespaced: true,
        dispatchEventsOnWindow: true
      },
      {
        userLoaded: (user) => loginEvent(user),
        userSignedOut: () => logoutEvent(),
        accessTokenExpiring: () => accessTokenExpiringEvent(),
        accessTokenExpired: () => accessTokenExpiredEvent()
      }
    )
  }
})
