
import TrinModel from '@/components/models/TrinModel.vue'

export default class OGFormularModel {
  id = ''
  status = ''
  version? = ''
  originalId = ''
  trin: TrinModel[] = []
  contactInfo: TrinModel = new TrinModel()
  natureCode = ''
}

