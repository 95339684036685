import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-401aa6f7"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "container page-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_KlarTekst = _resolveComponent("KlarTekst")!
  const _component_Opsummering = _resolveComponent("Opsummering")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", null, [
      _createElementVNode("a", {
        id: "tilbageKnap",
        class: _normalizeClass('back-link d-none d-lg-inline-block'),
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$router.go(-1)))
      }, [
        _createVNode(_component_KlarTekst, { "tekst-noegle": "tco.tg-opsummering.tilbage" })
      ])
    ]),
    _createVNode(_component_Opsummering, {
      "v-if": _ctx.ogInformation,
      formular: _ctx.ogInformation,
      editable: false
    }, null, 8, ["v-if", "formular"])
  ]))
}