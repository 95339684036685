
import { Options, Vue } from 'vue-class-component'
import FeltModel from './models/FeltModel.vue'
import { Prop } from 'vue-property-decorator'
import * as DKFDS from 'dkfds'
import Numeric from '@/components/Numeric.vue'
import { FeltType } from '@/components/Const.ts'
import { FormularFeltValidator, MandatoryValidator, MinMaxValidator, RegexValidator } from '@/components/FormularFeltValidator'
import KlarTekst from '@/components/KlarTekst.vue'
import { Getter } from 'vuex-class'
import FormularFeltUtil from '@/components/util/FormularFeltUtil'
import NumericInt from '@/components/NumericInt.vue'

@Options({
  components: {
    NumericInt,
    Numeric,
    KlarTekst
  },
  data () {
    return {
      FeltType
    }
  }
})
export default class FormularFelt extends Vue {
  emitter = require('tiny-emitter/instance')
  @Getter('getContentfulTcoModel') contentfulTcoModel

  @Prop({ type: FeltModel })
  felt?: FeltModel

  @Prop({ type: String })
  maerkat = ''

  @Prop({ type: [] })
  produktGrupper = []

  @Prop({ type: Boolean })
  readOnly = false

  @Prop()
  validators?: FormularFeltValidator[]

  @Prop({ type: Boolean })
  validateOnKeyUp = false

  eventSubscriptions: Map<string, ((arg1: any) => void)[]> = new Map()

  get fejlbeskeder (): string[] {
    return this.felt?.fejl || []
  }

  genererDomId (tcoElementName : string) : string {
    if (tcoElementName != null && tcoElementName !== '') {
      return FormularFeltUtil.escapeForDomId(tcoElementName)
    }
    return 'produktgruppeVaelger'
  }

  initValgteProduktGruppe (id : string) : void {
    if (id !== '') {
      this.emitter.emit('initProduktGruppe', id)
    }
  }

  private defaultFeltValidators (): FormularFeltValidator[] {
    const validators: FormularFeltValidator[] = []
    validators.push(new MandatoryValidator(this.contentfulTcoModel))
    validators.push(new MinMaxValidator(this.contentfulTcoModel))
    validators.push(new RegexValidator(this.contentfulTcoModel))
    return validators
  }

  onKeyUp (felt: FeltModel) {
    if (this.validateOnKeyUp) {
      felt.touched = true
      this.erValid(felt)
    }
  }

  onChange (felt: FeltModel) {
    this.emitter.emit('felt-change', felt)
  }

  onBlur (felt) {
    felt.touched = true
    this.erValid(felt)
  }

  onDatoFeltChange (felt: FeltModel) {
    this.emitter.emit('felt-change', felt)
    this.onBlur(felt)
  }

  onRadioJaNejChange (felt: FeltModel) {
    this.emitter.emit('felt-change', felt)
    this.onBlur(felt)
  }

  onBlurRadioJaNejInput (felt, isJa) {
    if (isJa) {
      felt.jaInputTouched = true
    } else {
      felt.nejInputTouched = true
    }
    this.onBlur(felt)
  }

  erValid (felt: FeltModel) : void { // NOSONAR
    felt.fejl = []
    felt.erValid = true
    const defaulValidators = this.defaultFeltValidators()
    for (const validator of defaulValidators) {
      const fejl = validator.validate(felt)
      if (fejl && fejl.length > 0) {
        this.tilfoejFejl(felt, fejl)
        felt.erValid = false
      }
    }

    if (this.validators && this.validators.length) {
      for (const validator of this.validators) {
        const fejl = validator.validate(felt)
        if (fejl && fejl.length > 0) {
          this.tilfoejFejl(felt, fejl)
          felt.erValid = false
        }
      }
    }
    this.emitter.emit('feltValideret', felt)
  }

  tilfoejFejl (felt: FeltModel, nyeFejl: string[]): void {
    if (nyeFejl && nyeFejl.length > 0) {
      nyeFejl.forEach(nf => {
        if (felt.fejl.findIndex(f => f === nf) < 0) {
          felt.fejl.push(nf)
        }
      })
    }
  }

  created () : void {
    this.subscribeEvent('opdaterFeltValiditet', (arg1) => {
      if (this.felt) {
        if (arg1.includes(this.felt.tcoElementName)) {
          this.felt.touched = true
          if (this.felt.felttype === FeltType.RADIO_JA_NEJ_MED_INPUT) {
            if (this.felt.vaerdi === this.felt.jaRadioVaerdi) {
              this.felt.jaInputTouched = true
            } else {
              this.felt.nejInputTouched = true
            }
          }
          this.erValid(this.felt)
        }
      }
    })
  }

  mounted () : void {
    // Animer tooltip

    if (this.felt) {
      const tooltip = document.getElementById('tco_tooltip_' + this.genererDomId(this.felt.tcoElementName))
      if (tooltip) {
        try {
          const initTooltip = new DKFDS.Tooltip(tooltip) // NOSONAR
        } catch (err) {
          console.error(err)
        }
      }

      if (this.felt.felttype === FeltType.DATO) {
        // note: doesnt work if you give the element as input instead of document.body
        const initDatepicker = DKFDS.datePicker.on(document.body) // NOSONAR
      }

      if (this.felt.felttype === FeltType.RADIO_JA_NEJ_MED_INPUT) {
        const initRadio = new DKFDS.RadioToggleGroup(document.getElementById('radio-collapse-' + this.genererDomId(this.felt.tcoElementName))) // NOSONAR
      }
    }

    // Fix unit-labels (inset)
    const unitLabels = document.getElementsByClassName('tco-unit')
    Array.prototype.forEach.call(unitLabels, function (el) {
      el.style.right = el.clientWidth + 3 + 'px'
    })

    // Valider
    if (this.felt) {
      // Her er tom streng valid
      this.erValid(this.felt)
    }
  }

  subscribeEvent (eventName: string, callbackFn: (arg1: any) => void): void {
    if (!this.eventSubscriptions.get(eventName)) {
      this.eventSubscriptions.set(eventName, [])
    }
    this.eventSubscriptions.get(eventName)!.push(callbackFn)
    this.emitter.on(eventName, callbackFn)
  }

  unmounted (): void {
    if (this.eventSubscriptions && this.eventSubscriptions.size > 0) {
      this.eventSubscriptions.forEach((callbacks, eventName) => {
        callbacks.forEach((eventHandler) => {
          this.emitter.off(eventName, eventHandler)
        })
      })
    }
    this.eventSubscriptions.clear()
  }

  formatProduktGruppe (productGroup: any): string {
    let val = productGroup.product_group_name
    if (productGroup.product_subgroup_name) {
      val += ' - ' + productGroup.product_subgroup_name
    }
    return val
  }

  formatStaticTekst (felt: FeltModel) {
    if (!felt) return ''
    if (felt.felttype === FeltType.STATIC_TEKST && felt.displayVaerdi) {
      return felt.displayVaerdi
    } else {
      return felt.vaerdi
    }
  }
}
