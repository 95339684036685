import { Chart } from 'chart.js'

declare module 'chart.js' {
  export interface TCOComparisonContents {
    totals: Record<number, number>,
    minIndex: number,
    maxIndex: number,
    maxVisibleDatasetIndex: number
  }

  // avoid typescript errors for charts using the plugin
  export interface Chart {
    $tcoScore?: TCOComparisonContents;
  }
}

export const TCOComparisonPlugin = {
  id: 'tcoScore',

  beforeUpdate: (chart: Chart): void => {
    const totals = {}
    let maxVisibleIndex = 0

    chart.data.datasets.forEach((dataset, datasetIndex) => {
      if (chart.isDatasetVisible(datasetIndex)) {
        maxVisibleIndex = datasetIndex
      }

      dataset.data.forEach((value, index) => {
        totals[index] = (totals[index] || 0) + value
      })
    })

    const minIdx: number | null = 0 // is min always the first?
    const maxIdx: number | null = chart.data.datasets.length > 0 ? (chart.data.datasets[0].data.length - 1) : 0 // is max always the last?

    chart.$tcoScore = {
      totals: totals,
      minIndex: minIdx,
      maxIndex: maxIdx,
      maxVisibleDatasetIndex: maxVisibleIndex
    }
  }
}

export const TCOLongLabelPlugin = {
  id: 'tcoLongLabel',
  longLabelThreshold: 40,

  beforeInit: function (chart) {
    const length = this.longLabelThreshold
    chart.data.labels.forEach(function (value, index, array) {
      if (Array.isArray(value)) return
      if (typeof value !== 'string') return
      if (value.length < length) return

      const compareLength = (longest, currentWord) => currentWord.length > longest.length ? currentWord : longest
      const longestWord = value.split(' ').reduce(compareLength, '')

      if (value.includes(' ') && longestWord < length) {
        // split on space into chunks of up to {length} characters
        const a = value.match(new RegExp('.{1,' + length + '}(\\s|$)', 'g'))
        array[index] = a
      } else {
        // hard split on every {length} characters
        const a: string[] = []
        a.push(value.slice(0, length))
        let i = 1
        while (value.length > (i * length)) {
          a.push(value.slice(i * length, (i + 1) * length))
          i++
        }
        array[index] = a
      }
    })
  }
}
