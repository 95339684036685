import ContentfulTcoContent from './components/models/ContentfulTcoContent'
import { ToastMessage, ToastType } from './components/models/ToastMessage'

export default class ToastUtil {
  /**
   * Create toast message for error response from backend. Message property is used as error text if present otherwise
   * generic error response for http status code is used
   * @param errorResponse
   * @param contentfulTcoModel
   * @returns
   */
  static createToastForErrorResponse (errorResponse, contentfulTcoModel: ContentfulTcoContent): ToastMessage {
    if (errorResponse.response && errorResponse.response.data && errorResponse.response.data.errorMessage) {
      return { text: errorResponse.response.data.errorMessage as string, type: ToastType.ERROR }
    } else {
      const errorMessage = contentfulTcoModel.findKlarTekst('tco.fejl.http.' + errorResponse.response.status)
      return { text: errorMessage, type: ToastType.ERROR }
    }
  }
}
