

import { Action, Getter, namespace } from 'vuex-class'
import { Options, Vue } from 'vue-class-component'
import Toast from '@/components/Toast.vue'
import KlarTekst from '@/components/KlarTekst.vue'
import RichTekst from '@/components/RichTekst.vue'
import store from '@/components/store'
import { Watch } from 'vue-property-decorator'
import DateUtil from '@/DateUtil'

const oidcStoreModule = namespace('oidcStore')

@Options({
  components: {
    Toast,
    KlarTekst,
    RichTekst
  }
})
export default class ToastMessages extends Vue {
  @Getter('getToastMessages') toastMessages

  @oidcStoreModule.Action('authenticateOidc') loginAction
  @oidcStoreModule.Action('signOutOidc') oidcStoreLogoutAction
  @Action('logout') logoutAction

  @oidcStoreModule.Getter('oidcAccessTokenExp') expiration
  @Action('setReturnUrlSessionRefresh') setReturnUrlSessionRefresh

  showSessionExpirationToast = false
  sessionExpirationTime = ''

  private storeUnsubscribe?: () => void

  @Watch('expiration')
  onExpirationChanged (): void {
    this.toggleNotification(store.getters.accessTokenExpiring)
  }

  created () : void {
    if (this.storeUnsubscribe) {
      this.storeUnsubscribe()
      this.storeUnsubscribe = undefined
    }

    this.storeUnsubscribe = store.subscribe((mutation, state) => {
      if (mutation.type === 'accessTokenExpiring') {
        this.toggleNotification(state.accessTokenExpiring)
      }
    })
  }

  unmounted () : void {
    if (this.storeUnsubscribe) {
      this.storeUnsubscribe()
      this.storeUnsubscribe = undefined
    }
  }

  async logout () : Promise<void> {
    this.oidcStoreLogoutAction()
    this.logoutAction()
  }

  async refreshLogin () : Promise<void> {
    const route = this.$router.currentRoute.value
    let path = route.fullPath
    // Handle special cases where user is in the middle of creating a new
    // blanket, as this means drafts are saved, but returning to the same URL
    // means a new draft is started instead of continuing the current one
    // so we redirect to overview instead
    if (route.name === 'og-blanket-formular' && !route.params.id) {
      path = '/overblikOG?tab=drafts'
    } else if (route.name === 'tg-blanket-formular' && !route.params.id) {
      path = '/overblikTG?tab=drafts'
    } else if (route.name === 'tg-blanket-formular-edit' && !route.params.id) {
      path = '/overblikTG?tab=drafts'
    }
    this.setReturnUrlSessionRefresh(path)
    this.loginAction()
  }

  private toggleNotification (show: boolean) : void {
    this.showSessionExpirationToast = show
    if (show) {
      const expirationDate = new Date(this.expiration)
      this.sessionExpirationTime = DateUtil.formatDateTime(expirationDate)
    } else {
      this.sessionExpirationTime = ''
    }
  }
}
