
/* eslint-disable semi */
import { Options, Vue } from 'vue-class-component'
import * as DKFDS from 'dkfds'
import KlarTekst from '@/components/KlarTekst.vue'
import RichTekst from '@/components/RichTekst.vue'

@Options({
  components: {
    KlarTekst,
    RichTekst
  }
})
export default class Vejledning extends Vue {
  mounted () : void {
    DKFDS.init()
  }
}
