
import { Options, Vue } from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import MicroModal from 'micromodal'
import KlarTekst from '@/components/KlarTekst.vue'
import ModalUtil from '@/components/util/ModalUtil'
import RichTekst from '@/components/RichTekst.vue'
import { Action, Getter } from 'vuex-class'

@Options({
  components: {
    KlarTekst,
    RichTekst
  }
})
export default class OGPublishReplaceModal extends Vue {
  @Getter('getContentfulTcoModel') contentfulTcoModel

  @Prop()
  modalId

  mounted () : void {
    MicroModal.init({
      onShow: modal => ModalUtil.dkfdsZIndexHack(true),
      onClose: modal => ModalUtil.dkfdsZIndexHack(false)
    })
  }

  publish () : void {
    this.$emit('publishChoice')
  }

  cancel () : void {
    MicroModal.close(this.modalId)
  }
}
