
import { Options, Vue } from 'vue-class-component'
import OGEvaluateEntry from '@/components/OGEvaluateEntry.vue'
import KlarTekst from '@/components/KlarTekst.vue'
import TGDetails from './TGDetails.vue'
import axios from '@/services/axios-instance'
import ToastUtil from '@/ToastUtil'
import { Action, Getter } from 'vuex-class'
import TCOEvaluationModel from './models/evaluation/TCOEvaluationModel.vue'
import OGEvaluateUtil from './util/OGEvaluateUtil'
import TGFormularModel from './models/TGFormularModel.vue'

@Options({
  components: {
    KlarTekst,
    TGDetails,
    OGEvaluateEntry
  }
})
export default class OGEvaluatePrint extends Vue {
  @Getter('getContentfulTcoModel') contentfulTcoModel
  @Action('addToast') addToast

  evaluation = new TCOEvaluationModel()
  tgFormular = new TGFormularModel()
  tgId?: string
  fullPrint = true
  sort?: string
  sortOfferProduct?: string

  created () : void {
    const ogId = this.$route.params.id
    this.tgId = this.$route.query.tg_id as string

    const fullPrintAsString = this.$route.query.fullPrint as string
    if (fullPrintAsString) {
      this.fullPrint = fullPrintAsString === 'true'
    }

    this.sort = this.$route.query.sort as string
    this.sortOfferProduct = this.$route.query.sortOfferProduct as string
    var promises: Promise<any>[] = []
    if (ogId) {
      let modifiedSort = this.sort
      if (this.sortOfferProduct === 'product') {
        modifiedSort = 'product-' + this.sort
      }
      const promise = axios.get(process.env.VUE_APP_DIRIGENT_URL + '/web/form/getEvaluering/' + ogId, { params: { sort: modifiedSort } })
        .then(response => {
          this.evaluation = response.data
          OGEvaluateUtil.setEvaluationLabels(this.evaluation, this.sortOfferProduct)
        }).catch(error => this.addToast(ToastUtil.createToastForErrorResponse(error, this.contentfulTcoModel)))
      promises.push(promise)
    }

    if (this.tgId) {
      const promise2 = axios.get(process.env.VUE_APP_DIRIGENT_URL + '/web/form/getFormTG/' + this.tgId)
        .then(
          response => {
            this.tgFormular = response.data
          })
        .catch(error => this.addToast(ToastUtil.createToastForErrorResponse(error, this.contentfulTcoModel)))
      promises.push(promise2)
    }

    Promise.all(promises).then(() => {
      if (window && !((window as any).Cypress)) {
        // only open print dialogue if cypress is not running
        setTimeout(() => window!.print(), 300)
        setTimeout(() => window!.close(), 300)
      }
    })

    document.title = 'Evaluering af tilbud - ' + (this.fullPrint ? 'fuldt' : 'simpelt') + ' overblik | TCO'
  }

  beforeUnmount () : void {
    // Reset document title since we haven't implemented it on all pages
    document.title = 'TCO'
  }
}
