
/* eslint-disable */
export default class Dokument {
  owner = ''
  version = ''
  xml = ''
  index_date = ''
  id = ''
  start_date = ''
  end_date = ''
  publication_date = ''
  closed_date = ''
  status = ''
  tender_name = ''
  tender_number = ''
  nature_code = ''
  tender_description = ''
  tender_quantity = ''
  display_ended = false
  display_published = false
  display_open = false
  isNotOriginalOG = false
  orderHasBeenEdited = false
  display_total_cost ?= ''
  display_lowest_cost ?=''
  offerCompanies: string[] = []
  product_group_name = ''
}
