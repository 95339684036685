
import { Options, Vue } from 'vue-class-component'
import FormularFelt from '@/components/FormularFelt.vue'
import FeltModel from '@/components/models/FeltModel.vue'
import axios from '@/services/axios-instance'
import FormularModel from '@/components/models/FormularModel.vue'
import { FeltType, DocumentStatus } from '@/components/Const.ts'
import { FormularFeltValidator } from './FormularFeltValidator'
import MetadataDokument from './models/MetadataDocument'
import KlarTekst from '@/components/KlarTekst.vue'
import { Action, Getter } from 'vuex-class'
import ToastUtil from '@/ToastUtil'
import { ToastType } from '@/components/models/ToastMessage'
import { Fejlbesked } from './models/Fejl'
import TrinModel from '@/components/models/TrinModel.vue'

@Options({
  components: {
    FormularFelt,
    KlarTekst
  },
  data () {
    return {
      FeltType,
      DocumentStatus
    }
  }
})

export default class Metadata extends Vue {
  @Getter('isAdministrator') isAdministrator
  @Action('addToast') addToast
  @Getter('getContentfulTcoModel') contentfulTcoModel

  emitter = require('tiny-emitter/instance')
  showSpecification = false
  formular: FormularModel = new FormularModel()
  formularIncSpec : FormularModel = new FormularModel()
  fields: FeltModel[] = []
  itemSpecificationsFields: FeltModel[] = []
  itemSpecifications: {
    code: FeltModel,
    value: FeltModel,
    unitPriceType: FeltModel,
    unitPriceNet: FeltModel,
    unitPriceTax: FeltModel,
    unitPrice: FeltModel,
    percent: FeltModel
  }[] = []

  itemSpecificationCodesOptions: Map<string, string> = new Map()

  metadataDocument: MetadataDokument | null = null;

  jsonDocument = ''
  productGroups = []

  feltValidators: Map<string, FormularFeltValidator[]> = new Map();
  feltReadOnly: Map<string, boolean> = new Map()

  submitting = false

  eventSubscriptions: Map<string, ((arg1: any) => void)[]> = new Map()

  mounted () : void {
    if (!this.isAdministrator) this.$router.replace('/')

    const validitetsperiodeValidator = this.validitetsperiodeGyldighedValidator()
    this.feltValidators.set('cac:ValidityPeriod.cbc:EndDate', [validitetsperiodeValidator])

    // tillad gem kladde
    this.subscribeEvent('gemForm', async () => this.saveDraft())
    this.subscribeEvent('felt-change', (felt) => {
      if (felt?.tcoElementName?.includes('cbc:Name')) {
        this.getSpecification(felt)
      }
    })

    const id = this.$route.params.id as string
    if (id) {
      this.getExisting(id)
    } else {
      this.newGroup()
    }
  }

  subscribeEvent (eventName: string, callbackFn: (arg1: any) => void): void {
    if (!this.eventSubscriptions.get(eventName)) {
      this.eventSubscriptions.set(eventName, [])
    }
    this.eventSubscriptions.get(eventName)!.push(callbackFn)
    this.emitter.on(eventName, callbackFn)
  }

  unmounted (): void {
    if (this.eventSubscriptions && this.eventSubscriptions.size > 0) {
      this.eventSubscriptions.forEach((callbacks, eventName) => {
        callbacks.forEach((eventHandler) => {
          this.emitter.off(eventName, eventHandler)
        })
      })
    }
    this.eventSubscriptions.clear()
  }

  async newGroup (): Promise<void> {
    this.metadataDocument = null
    await axios.get(process.env.VUE_APP_DIRIGENT_URL + '/web/form/getMetadataForm').then(
      response => {
        this.formular = response.data
        this.fields = this.formular.trin[0].felter
      }).catch(error => this.addToast(ToastUtil.createToastForErrorResponse(error, this.contentfulTcoModel)))
  }

  getFieldValidatorName (tcoElementName) {
    if (tcoElementName.includes('cbc:TCOItemSpecificationCustomerConsumptionPercent')) return 'cbc:TCOItemSpecificationCustomerConsumptionPercent'
    else return ''
  }

  getSpecification (felt) : void{
    axios.get(process.env.VUE_APP_DIRIGENT_URL + '/web/form/getMetadataFormSpecification/' + felt.vaerdi).then(
      response => {
        // Push new fields to model
        const newFields = response.data.trin[0].felter
        newFields.forEach(field =>
          this.itemSpecificationsFields.push(field)
        )
        this.formular.trin[0].felter = this.formular.trin[0].felter.concat(response.data.trin[0].felter)
        // Make productgroup choice readonly after successful fetch
        this.feltReadOnly.set('cbc:Name', true)
        this.showSpecification = true

        const usagePercentValidator = this.itemSpecCustomerConsumptionPercentTotalValidator()
        this.feltValidators.set('cbc:TCOItemSpecificationCustomerConsumptionPercent', [usagePercentValidator])
      }).catch(error => this.addToast(ToastUtil.createToastForErrorResponse(error, this.contentfulTcoModel)))
  }

  async valider (): Promise<boolean> {
    /* eslint-disable promise/param-names */
    return new Promise<boolean>(validationResolve => {
      var felter = this.formular.trin[0].felter

      // setup så vi kan vente på alle felter bliver valideret asynkront
      var promises = felter.map(felt => {
        if (felt.felttype === FeltType.HIDDEN) {
          return true
        } else {
          return new Promise<boolean>((resolve) => {
            this.emitter.on('feltValideret', f => {
              if (felt.tcoElementName === f.tcoElementName) {
                resolve(felt.erValid as boolean)
              }
            })
          })
        }
      })
      felter.forEach(felt => {
        felt.touched = true
        if (felt.vaerdi == null) {
          felt.vaerdi = ''
          this.emitter.emit('opdaterFeltValiditet', felt.tcoElementName)
        } else if (felt.felttype === FeltType.DATO) { // force revalidation af dato felter da de ikke virker automatisk on blur pga. DKFDS
          this.emitter.emit('opdaterFeltValiditet', felt.tcoElementName)
        } else {
          this.emitter.emit('opdaterFeltValiditet', felt.tcoElementName)
        }
      })

      Promise.all(promises).then(result => {
        this.emitter.off('feltValideret') // ryd op så vi ikke kører event handlers dobbelt
        var valid = true
        this.formular.trin[0].felter.forEach(f => {
          if (f.felttype !== FeltType.HIDDEN) {
            valid = valid && (f.erValid as boolean)
          }
        })
        return validationResolve(valid)
      })
    })
  }

  async send (): Promise<void> {
    const valid = await this.valider()
    if (valid) {
      await this.gemMetadata('/web/form/publicerMetadata', false)
    } else {
      window.scrollTo(0, 0)
    }
  }

  async saveDraft (): Promise<void> {
    await this.gemMetadata('/web/form/gemKladdeMetadata', true)
  }

  private async gemMetadata (path: string, saveDraft: boolean): Promise<void> {
    if (this.submitting) return
    this.submitting = true
    this.jsonDocument = this.buildJsonDocument()
    console.log(this.jsonDocument)
    await axios.post(process.env.VUE_APP_DIRIGENT_URL + path, this.jsonDocument,
      {
        headers: {
          'content-type': 'application/json'
        }
      })
      .then(response => {
        this.submitting = false
        this.formular.id = response.data
        if (saveDraft) {
          if (response.status === 200) {
            const headline = this.contentfulTcoModel.findKlarTekst('tco.produktgruppe.gemkladde.success.overskrift')
            const text = this.contentfulTcoModel.findKlarTekst('tco.produktgruppe.gemkladde.success.beskrivelse')
            this.addToast({ headline: headline, text: text, type: ToastType.SUCCESS })
          }
        } else {
          this.$router.push({ path: '/metadata', query: { keepAlerts: 'true' } })
        }
      })
      .catch(error => {
        this.submitting = false
        this.addToast(ToastUtil.createToastForErrorResponse(error, this.contentfulTcoModel))
      })
  }

  buildJsonDocument (): string {
    return JSON.stringify(this.formular, null, 2)
  }

  getExisting (id: string): void {
    var promise1 = axios.get(process.env.VUE_APP_DIRIGENT_URL + '/web/form/produktgruppe/' + id)
    var promise2 = axios.get(process.env.VUE_APP_DIRIGENT_URL + '/web/form/getMetadataForm/' + id)

    Promise.all([promise1, promise2]).then(results => {
      this.metadataDocument = results[0].data
      this.formular = results[1].data
      const felter = this.formular.trin[0].felter

      this.feltReadOnly.clear()
      if (this.metadataDocument && this.metadataDocument.status === DocumentStatus.PUBLISHED) {
        felter.forEach(felt => {
          // kun start + slut dato skal være redigerbare
          if (felt.tcoElementName &&
            felt.tcoElementName !== 'cac:ValidityPeriod.cbc:StartDate' &&
            felt.tcoElementName !== 'cac:ValidityPeriod.cbc:EndDate') {
            this.feltReadOnly.set(felt.tcoElementName, true)
          }
        })
      }
      this.fields = felter.filter(f => !f.tcoElementName.includes('cac:TCOItemSpecification') && !f.tcoElementName.includes('cbc:Note'))
      this.itemSpecificationsFields = felter.filter(f => f.tcoElementName.includes('cac:TCOItemSpecification') || f.tcoElementName.includes('cbc:Note'))
      if (this.itemSpecificationsFields.length > 0) {
        this.showSpecification = true
      }
      if (this.metadataDocument?.product_group_name !== '') {
        this.feltReadOnly.set('cbc:Name', true)
      }
    })
  }

  /**
   * @param trinNummer (valgfri) Hvis der angives et trin nummer, hentes fejl for det pågældende trin, eller hentes alle fejl i formularen
   */
  hentFejl () : Fejlbesked[] {
    const fejl: Fejlbesked[] = []
    const felter = this.formular?.trin[0]?.felter?.filter(y => !y.erValid && y.felttype !== FeltType.HIDDEN)

    if (felter) {
      felter.forEach(felt => {
        if (felt.fejl && felt.tcoElementName) {
          felt.fejl.forEach(f => {
            const error = { tcoElementName: felt.tcoElementName, fejlbesked: `${felt.maerkat}: ${f}` }
            fejl.push(error)
          })
        }
      })
    }
    return fejl
  }

  validitetsperiodeGyldighedValidator (): FormularFeltValidator {
    return {
      validate: (felt: FeltModel) => {
        const fejl: string[] = []
        const startDate = this.findFelt('cac:ValidityPeriod.cbc:StartDate')
        const endDate = this.findFelt('cac:ValidityPeriod.cbc:EndDate')

        if (startDate && endDate) {
          const start = this.parseDate(startDate.vaerdi)
          const end = this.parseDate(endDate.vaerdi)

          if (end < start) {
            fejl.push('Tilbudsfrist dato og tidspunkt skal være senere end startdato')
          }
        }
        return fejl
      }
    }
  }

  itemSpecCustomerConsumptionPercentTotalValidator (): FormularFeltValidator {
    return {
      validate: (felt: FeltModel) => {
        var percentFields = this.itemSpecificationsFields.filter(x => x.tcoElementName.includes('TCOItemSpecificationCustomerConsumptionPercent'))
        if (percentFields.every(is => is.vaerdi !== null && is.touched === true)) {
          const fejl: string[] = []
          var total = 0
          percentFields.forEach(is => {
            is.fejl = []
            is.erValid = true
            total += is.vaerdi ? +parseInt(is.vaerdi) : 0
          })
          console.log(total)
          if (total > 100) {
            fejl.push(this.contentfulTcoModel.findKlarTekst('tco.produktgruppe.item-specifikation.procentbemærkningover'))
          } else if (total < 100) {
            fejl.push(this.contentfulTcoModel.findKlarTekst('tco.produktgruppe.item-specifikation.procentbemærkningunder'))
          }
          return fejl
        } else {
          return []
        }
      }
    }
  }

  private findFelt (tcoElementName: string): FeltModel | null {
    if (!this.formular || !this.formular.trin) {
      return null
    }
    if (tcoElementName) {
      for (const trin of this.formular.trin) {
        if (trin.felter) {
          const felt = trin.felter.find(x => x.tcoElementName === tcoElementName)
          if (felt) {
            return felt
          }
        }
      }
    }
    return null
  }

  private parseDate (dateString: string): Date {
    const str = dateString + 'T00:00:00'
    return new Date(str)
  }

  private isFeltDisabled (felt: FeltModel): boolean {
    if (felt && felt.tcoElementName) {
      return this.feltReadOnly.get(felt.tcoElementName) || false
    }
    return false
  }

  canSaveDraft (): boolean {
    if (this.metadataDocument) {
      return this.metadataDocument.status !== DocumentStatus.PUBLISHED
    }
    return true
  }
}
