
import { Options, Vue } from 'vue-class-component'
import { Getter } from 'vuex-class'
import KlarTekst from '@/components/KlarTekst.vue'

@Options({
  components: {
    KlarTekst
  }
})

export default class Formular extends Vue {
  @Getter('isOrdregiver') isOrdregiver
  @Getter('isTilbudsgiver') isTilbudsgiver
  @Getter('isAdministrator') isAdministrator

  emitter = require('tiny-emitter/instance')
  gemKladde () : void {
    this.emitter.emit('gemForm')
  }
}
