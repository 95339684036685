import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  id: "formContainer",
  class: "container page-container"
}
const _hoisted_2 = { class: "row" }
const _hoisted_3 = { class: "col-12 col-lg-12" }
const _hoisted_4 = { class: "row description-box" }
const _hoisted_5 = {
  id: "ogDescription",
  class: "col-7 col-lg-7"
}
const _hoisted_6 = {
  key: 0,
  id: "ogButtonBox",
  class: "col-3"
}
const _hoisted_7 = {
  id: "tcoOgInfoAccordions",
  class: "col-7 col-lg-7"
}
const _hoisted_8 = { class: "accordion accordion-multiselectable" }
const _hoisted_9 = {
  class: "accordion-button info-accordion-button",
  "aria-expanded": "false",
  "aria-controls": "ogInfoAccordion1"
}
const _hoisted_10 = {
  id: "ogInfoAccordion1",
  "aria-hidden": "true",
  class: "accordion-content"
}
const _hoisted_11 = {
  class: "accordion-button info-accordion-button",
  "aria-expanded": "false",
  "aria-controls": "ogInfoAccordion2"
}
const _hoisted_12 = {
  id: "ogInfoAccordion2",
  "aria-hidden": "true",
  class: "accordion-content"
}
const _hoisted_13 = { class: "row description-box" }
const _hoisted_14 = {
  id: "tgDescription",
  class: "col-12 col-lg-12"
}
const _hoisted_15 = {
  id: "tcoTgInfoAccordions",
  class: "col-7 col-lg-7"
}
const _hoisted_16 = { class: "accordion accordion-multiselectable" }
const _hoisted_17 = {
  class: "accordion-button info-accordion-button",
  "aria-expanded": "false",
  "aria-controls": "tgInfoAccordion1"
}
const _hoisted_18 = {
  id: "tgInfoAccordion1",
  "aria-hidden": "true",
  class: "accordion-content"
}
const _hoisted_19 = {
  class: "accordion-button info-accordion-button",
  "aria-expanded": "false",
  "aria-controls": "tgInfoAccordion2"
}
const _hoisted_20 = {
  id: "tgInfoAccordion2",
  "aria-hidden": "true",
  class: "accordion-content"
}
const _hoisted_21 = { class: "row" }
const _hoisted_22 = {
  id: "tcoInformationPage",
  class: "col-12 col-lg-12"
}
const _hoisted_23 = { id: "tcoInformationAccordions" }
const _hoisted_24 = { class: "accordion accordion-multiselectable" }
const _hoisted_25 = {
  class: "accordion-button",
  "aria-expanded": "false",
  "aria-controls": "accordionBtn1"
}
const _hoisted_26 = {
  id: "accordionBtn1",
  "aria-hidden": "true",
  class: "accordion-content"
}
const _hoisted_27 = {
  class: "accordion-button",
  "aria-controls": "accordionBtn2"
}
const _hoisted_28 = {
  id: "accordionBtn2",
  "aria-hidden": "true",
  class: "accordion-content"
}
const _hoisted_29 = {
  class: "accordion-button",
  "aria-controls": "accordionBtn3"
}
const _hoisted_30 = {
  id: "accordionBtn3",
  "aria-hidden": "true",
  class: "accordion-content"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_KlarTekst = _resolveComponent("KlarTekst")!
  const _component_RichTekst = _resolveComponent("RichTekst")!
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("h1", null, [
          _createVNode(_component_KlarTekst, { tekstNoegle: 'tco.forside.hovedtitel' }, null, 8, ["tekstNoegle"])
        ]),
        _createElementVNode("span", null, [
          _createVNode(_component_KlarTekst, { tekstNoegle: 'tco.forside.hovedtitelbeskrivelse' }, null, 8, ["tekstNoegle"])
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("span", null, [
          _createVNode(_component_RichTekst, { tekstNoegle: 'tco.forside.ogbeskrivelse' }, null, 8, ["tekstNoegle"])
        ]),
        _createElementVNode("span", null, [
          _createVNode(_component_RichTekst, { tekstNoegle: 'tco.forside.oginformation' }, null, 8, ["tekstNoegle"])
        ])
      ]),
      (!_ctx.isAuthenticated || _ctx.isOrdregiver)
        ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
            _createVNode(_component_router_link, {
              to: "/blanket",
              class: "button button-primary",
              "active-class": "current",
              id: "ogBlanketbtn"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_KlarTekst, { tekstNoegle: 'tco.forside.knap.nytindkøb' }, null, 8, ["tekstNoegle"])
              ]),
              _: 1
            })
          ]))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_7, [
        _createElementVNode("ul", _hoisted_8, [
          _createElementVNode("li", null, [
            _createElementVNode("h3", null, [
              _createElementVNode("button", _hoisted_9, [
                _createVNode(_component_KlarTekst, { tekstNoegle: 'tco.forside.oginformation.accordiontitel1' }, null, 8, ["tekstNoegle"])
              ])
            ]),
            _createElementVNode("div", _hoisted_10, [
              _createVNode(_component_RichTekst, { tekstNoegle: 'tco.forside.oginformation.accordionbeskrivelse1' }, null, 8, ["tekstNoegle"])
            ])
          ]),
          _createElementVNode("li", null, [
            _createElementVNode("h3", null, [
              _createElementVNode("button", _hoisted_11, [
                _createVNode(_component_KlarTekst, { tekstNoegle: 'tco.forside.oginformation.accordiontitel2' }, null, 8, ["tekstNoegle"])
              ])
            ]),
            _createElementVNode("div", _hoisted_12, [
              _createVNode(_component_RichTekst, { tekstNoegle: 'tco.forside.oginformation.accordionbeskrivelse2' }, null, 8, ["tekstNoegle"])
            ])
          ])
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_13, [
      _createElementVNode("div", _hoisted_14, [
        _createElementVNode("span", null, [
          _createVNode(_component_RichTekst, { tekstNoegle: 'tco.forside.tgbeskrivelse' }, null, 8, ["tekstNoegle"])
        ]),
        _createElementVNode("span", null, [
          _createVNode(_component_RichTekst, { tekstNoegle: 'tco.forside.tginformation' }, null, 8, ["tekstNoegle"])
        ])
      ]),
      _createElementVNode("div", _hoisted_15, [
        _createElementVNode("ul", _hoisted_16, [
          _createElementVNode("li", null, [
            _createElementVNode("h3", null, [
              _createElementVNode("button", _hoisted_17, [
                _createVNode(_component_KlarTekst, { tekstNoegle: 'tco.forside.tginformation.accordiontitel1' }, null, 8, ["tekstNoegle"])
              ])
            ]),
            _createElementVNode("div", _hoisted_18, [
              _createVNode(_component_RichTekst, { tekstNoegle: 'tco.forside.tginformation.accordionbeskrivelse1' }, null, 8, ["tekstNoegle"])
            ])
          ]),
          _createElementVNode("li", null, [
            _createElementVNode("h3", null, [
              _createElementVNode("button", _hoisted_19, [
                _createVNode(_component_KlarTekst, { tekstNoegle: 'tco.forside.tginformation.accordiontitel2' }, null, 8, ["tekstNoegle"])
              ])
            ]),
            _createElementVNode("div", _hoisted_20, [
              _createVNode(_component_RichTekst, { tekstNoegle: 'tco.forside.tginformation.accordionbeskrivelse2' }, null, 8, ["tekstNoegle"])
            ])
          ])
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_21, [
      _createElementVNode("div", _hoisted_22, [
        _createElementVNode("h3", null, [
          _createVNode(_component_KlarTekst, { tekstNoegle: 'tco.forside.undertitel' }, null, 8, ["tekstNoegle"])
        ]),
        _createElementVNode("div", _hoisted_23, [
          _createElementVNode("ul", _hoisted_24, [
            _createElementVNode("li", null, [
              _createElementVNode("h3", null, [
                _createElementVNode("button", _hoisted_25, [
                  _createVNode(_component_KlarTekst, { tekstNoegle: 'tco.forside.accordiontitel1' }, null, 8, ["tekstNoegle"])
                ])
              ]),
              _createElementVNode("div", _hoisted_26, [
                _createVNode(_component_RichTekst, { tekstNoegle: 'tco.forside.accordiontekst1' }, null, 8, ["tekstNoegle"])
              ])
            ]),
            _createElementVNode("li", null, [
              _createElementVNode("h3", null, [
                _createElementVNode("button", _hoisted_27, [
                  _createVNode(_component_KlarTekst, { tekstNoegle: 'tco.forside.accordiontitel2' }, null, 8, ["tekstNoegle"])
                ])
              ]),
              _createElementVNode("div", _hoisted_28, [
                _createVNode(_component_RichTekst, { tekstNoegle: 'tco.forside.accordiontekst2' }, null, 8, ["tekstNoegle"])
              ])
            ]),
            _createElementVNode("li", null, [
              _createElementVNode("h3", null, [
                _createElementVNode("button", _hoisted_29, [
                  _createVNode(_component_KlarTekst, { tekstNoegle: 'tco.forside.accordiontitel3' }, null, 8, ["tekstNoegle"])
                ])
              ]),
              _createElementVNode("div", _hoisted_30, [
                _createVNode(_component_RichTekst, { tekstNoegle: 'tco.forside.accordiontekst3' }, null, 8, ["tekstNoegle"])
              ])
            ])
          ])
        ])
      ])
    ])
  ]))
}