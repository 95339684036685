
import { Options, Vue } from 'vue-class-component'
import axios from '@/services/axios-instance'
import TGFormularModel from '@/components/models/TGFormularModel.vue'
import TGResultat from '@/components/TGResultat.vue'
import KlarTekst from '@/components/KlarTekst.vue'
import ToastUtil from '@/ToastUtil'
import { Action, Getter } from 'vuex-class'
import { Prop } from 'vue-property-decorator'
import { nextTick } from '@vue/runtime-core'

@Options({
  components: {
    KlarTekst,
    TGResultat
  }
})
export default class TGResultatStandalone extends Vue {
  emitter = require('tiny-emitter/instance')
  @Getter('getContentfulTcoModel') contentfulTcoModel
  @Action('addToast') addToast
  id
  formular = new TGFormularModel()

  @Prop({ type: Boolean })
  printMode = false

  created () : void {
    this.setDocumentTitle()
  }

  mounted () : void {
    this.id = this.$route.params.id as string
    if (this.id) {
      axios.get(process.env.VUE_APP_DIRIGENT_URL + '/web/form/getFormTG/' + this.id, { params: { includeScore: true } })
        .then(
          response => {
            this.formular = response.data
            if (this.printMode && window && !((window as any).Cypress)) {
              // only open print dialogue if cypress is not running
              setTimeout(() => window!.print(), 300)
              setTimeout(() => window!.close(), 300)
            }
          })
        .catch(error => this.addToast(ToastUtil.createToastForErrorResponse(error, this.contentfulTcoModel)))
    }
  }

  beforeUnmount () : void {
    if (this.printMode) {
      // Reset document title since we haven't implemented it on all pages
      document.title = 'TCO'
    }
  }

  private setDocumentTitle () : void {
    if (this.printMode) {
      document.title = 'Tilbudsresultat | TCO'
    }
  }
}
