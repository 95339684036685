<template>
  <footer>
    <div class="footer">
      <div class="container" id="footerContainer">
        <div class="footer-col portal-footer-inner">
          <RichTekst :tekstNoegle="'tco.sidefod.version'"/><span>{{webVersion}}</span>
        </div>
        <div class="footer-col">
          <RichTekst :tekstNoegle="'tco.sidefod.kontakt'"/>
        </div>
        <div class="footer-col">
          <RichTekst :tekstNoegle="'tco.sidefod.links'"/>
        </div>
        <div class="footer-col">
          <RichTekst :tekstNoegle="'tco.sidefod.webinfo'"/>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
import { Options, Vue } from 'vue-class-component'
import KlarTekst from '@/components/KlarTekst.vue'
import RichTekst from '@/components/RichTekst.vue'

@Options({
  components: {
    KlarTekst,
    RichTekst
  }
})
export default class SideFod extends Vue {
  webVersion = ''

  created () {
    this.webVersion = process.env.VUE_APP_WEB_VERSION
  }
}
</script>

<style scoped>
#footerContainer {
  display: flex;
}

.footer-col {
  width: 30%;
}

.function-link {
  padding-top: 3%;
}

.footer .logo {
  background-image: url(/img/logo_virk.fc168e1b.svg);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  display: inline-block;
  height: 30px;
  width: 100px;
  max-width: 60%;
}
</style>
