
export default class OGEvaluateUtil {
  /**
   * Create toast message for error response from backend. Message property is used as error text if present otherwise
   * generic error response for http status code is used
   * @param errorResponse
   * @param contentfulTcoModel
   * @returns
   */
  static dkfdsZIndexHack (enable: boolean): void {
    /*
      HACK: DKFDS active tab button has a z-index of 350 while the visible associated .tab-panel has a z-index of 300
      Since this modal is place inside a .tab-panel the active tab button will be placed on top of both the modal and the modal backdrop/overlay.
      to fix this we temporarily change the z-index to 299 when the modal opens using the style property and remove the style again when the modal closes.
    */
    const aktivTabButtons = document.querySelectorAll<HTMLElement>('.tabnav>button[aria-selected=true]')
    aktivTabButtons.forEach(el => {
      if (enable) {
        el.style.setProperty('z-index', '299')
      } else {
        el.style.removeProperty('z-index')
      }
    })
  }
}
