
import { Options, Vue } from 'vue-class-component'
import axios from '@/services/axios-instance'
import TGFormularModel from './models/TGFormularModel.vue'
import TGDetails from '@/components/TGDetails.vue'
import ToastUtil from '@/ToastUtil'
import { Action, Getter } from 'vuex-class'
import { Prop } from 'vue-property-decorator'

@Options({
  components: {
    TGDetails
  }
})
export default class TGOpsummeringStandalone extends Vue {
  @Getter('getContentfulTcoModel') contentfulTcoModel
  @Action('addToast') addToast
  @Prop({ type: Boolean }) printMode = false
  @Prop({ type: Boolean }) noContactInfo = false
  blanketId
  formular = new TGFormularModel()

  created () : void {
    this.blanketId = this.$route.params.id
    if (this.blanketId) {
      axios.get(process.env.VUE_APP_DIRIGENT_URL + '/web/form/getFormTG/' + this.blanketId)
        .then(
          response => {
            this.formular = response.data
            if (this.printMode && window && !((window as any).Cypress)) {
              // only open print dialogue if cypress is not running
              setTimeout(() => window!.print(), 300)
              setTimeout(() => window!.close(), 300)
            }
          })
        .catch(error => this.addToast(ToastUtil.createToastForErrorResponse(error, this.contentfulTcoModel)))
    }

    this.setDocumentTitle()
  }

  beforeUnmount () : void {
    if (this.printMode) {
      // Reset document title since we haven't implemented it on all pages
      document.title = 'TCO'
    }
  }

  private setDocumentTitle () : void {
    if (this.printMode) {
      document.title = 'Opsummering af tilbud | TCO'
    }
  }
}
