
/* eslint-disable semi */
import { Getter } from 'vuex-class'
import { Vue } from 'vue-class-component'
import { Prop } from 'vue-property-decorator';

export default class extends Vue {
  @Prop()
  tekstNoegle = 'ukendt-noegle'

  // @Getter('getKlarTekstMap') klarTekstMap

  @Getter('getContentfulTcoModel') contentfulTcoModel

  klarTekst = ''

  mounted () : void {
    this.klarTekst = this.contentfulTcoModel.findKlarTekst(this.tekstNoegle)
  }
  /*
  getTekst (tekstNoegle: string): string {
    const value = this.klarTekstMap.get(tekstNoegle)
    return (value != null ? value : tekstNoegle)
  }
 */
}
