
/* eslint-disable semi */
import { Options, Vue } from 'vue-class-component'
import { DateTime } from 'luxon';
import KlarTekst from '@/components/KlarTekst.vue'
import RichTekst from '@/components/RichTekst.vue'

@Options({
  components: {
    KlarTekst,
    RichTekst
  }
})

export default class Kvittering extends Vue {
  currentDate = DateTime.now().toFormat('dd-MM-yyyy kl. HH:mm')
  referenceID
  udbudsNavn
  udbudsNummer
  url

  kvitteringsInformation = 'Husk at dele linket til indkøbet med tilbudsgivere på den relevante kanal, f.eks. på udbuddet i udbudssystemet eller via mail ved indkøb under rammeaftale o.l. På den måde får tilbudsgivere adgang til at foretage TCO-beregning på dit indkøb.'

  created () : void {
    window.scrollTo(0, 0)
    this.referenceID = this.$route.params.formularID
    this.udbudsNavn = this.$route.params.name
    this.udbudsNummer = this.$route.params.number
    if (this.udbudsNummer === null || this.udbudsNummer === '') {
      this.udbudsNummer = '-'
    }
    this.url = '/blanketprint/' + this.referenceID

    document.title = 'Kvittering - Indkøb | TCO'
  }

  beforeUnmount () : void {
    // Reset document title since we haven't implemented it on all pages
    document.title = 'TCO'
  }

  printExternal (url : string) : void {
    const route = this.$router.resolve({ path: url });
    window.open(route.href, '_blank')
  }
}
