
/* eslint-disable handle-callback-err */
import { Options, Vue } from 'vue-class-component'
import axios from '@/services/axios-instance'
import KlarTekst from '@/components/KlarTekst.vue'
import RichTekst from '@/components/RichTekst.vue'
import OGFormularModel from './models/OGFormularModel.vue'
import Opsummering from '@/components/Opsummering.vue'
import * as DKFDS from 'dkfds'
import { Action, Getter } from 'vuex-class'
import { ToastType } from './models/ToastMessage'
import ToastUtil from '@/ToastUtil'

@Options({
  components: {
    KlarTekst,
    RichTekst,
    Opsummering
  }
})
export default class TGLandingPage extends Vue {
  @Action('addToast') addToast
  @Getter('getContentfulTcoModel') contentfulTcoModel
  @Getter('isTilbudsgiver') isTilbudsgiver
  ogBlanketId = ''
  ogInformation: OGFormularModel | null = null
  existingTGBlanketId = ''
  unknownOgBlanketId = false
  productGroup = ''

  created () : void {
    if (!this.isTilbudsgiver) {
      this.$router.replace('/')
    }

    this.unknownOgBlanketId = false
    this.ogBlanketId = this.$route.params.id as string
    if (this.ogBlanketId) {
      axios.post(process.env.VUE_APP_DIRIGENT_URL + '/web/form/getForm/' + this.ogBlanketId + '?active=true', '', { headers: { 'content-type': 'application/json' } })
        .then(
          response => {
            this.ogInformation = response.data
          })
        .catch(error => {
          this.unknownOgBlanketId = true
          if (error.response.status === 404) {
            const headline = this.contentfulTcoModel.findKlarTekst('tco.blanket.tg-blanket.introduktion.fejl.ukendt-og-blanket.overskrift')
            const text = this.contentfulTcoModel.findKlarTekst('tco.blanket.tg-blanket.introduktion.fejl.ukendt-og-blanket.beskrivelse')
            this.addToast({ headline: headline, text: text, type: ToastType.ERROR })
          } else {
            this.addToast(ToastUtil.createToastForErrorResponse(error, this.contentfulTcoModel))
          }
        })

      axios.get(process.env.VUE_APP_DIRIGENT_URL + '/web/form/getEksisterendeTGBlanketIdForUdbud/' + this.ogBlanketId)
        .then(
          response => {
            this.existingTGBlanketId = response.data
          })
        .catch(error => this.addToast(ToastUtil.createToastForErrorResponse(error, this.contentfulTcoModel)))
    }
  }

  mounted () : void {
    const tab = new DKFDS.Tabnav(document.getElementById('tabs')) // NOSONAR
  }

  openOgInformationTab (): void {
    const ogTab = document.getElementById('tab_og_oplysninger')
    if (ogTab) {
      ogTab.click()
    }
  }
}
