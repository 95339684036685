import FeltModel from '@/components/models/FeltModel.vue'
import { FeltType } from '@/components/Const'
import DateUtil from '@/DateUtil'
import ContentfulTcoContent from './models/ContentfulTcoContent'

export interface FormularFeltValidator {
    validate (felt: InstanceType<typeof FeltModel>): string[]
}

export class MandatoryValidator implements FormularFeltValidator {
  defaultErrorMessage = 'tco.fejl.felt-kraevet'
  private contentfulTcoModel: ContentfulTcoContent

  constructor (contentfulTcoModel: ContentfulTcoContent) {
    this.contentfulTcoModel = contentfulTcoModel
  }

  validate (felt: InstanceType<typeof FeltModel>): string[] {
    const fejl: string[] = []
    if (felt.felttype === FeltType.VALGLISTE || felt.felttype === FeltType.PRODUKTGRUPPEVÆLGER) {
      if (felt.touched && felt.erObligatorisk && felt.vaerdi === '') {
        if (felt.valideringsFejlBesked) {
          fejl.push(felt.valideringsFejlBesked)
        } else {
          fejl.push(this.contentfulTcoModel.findKlarTekst(this.defaultErrorMessage))
        }
      }
    } else if (felt.felttype === FeltType.RADIO_JA_NEJ_MED_INPUT) {
      const touched = felt.vaerdi === felt.jaRadioVaerdi ? felt.jaInputTouched : felt.nejInputTouched
      if (touched && felt.erObligatorisk) {
        const vaerdi = felt.vaerdi === felt.jaRadioVaerdi ? felt.jaInputVaerdi : felt.nejInputVaerdi
        if (!vaerdi) {
          if (felt.valideringsFejlBesked) {
            fejl.push(felt.valideringsFejlBesked)
          } else {
            fejl.push(this.contentfulTcoModel.findKlarTekst(this.defaultErrorMessage))
          }
        }
      }
    } else if (felt.touched && felt.erObligatorisk && !felt.vaerdi) {
      if (felt.valideringsFejlBesked) {
        fejl.push(felt.valideringsFejlBesked)
      } else {
        fejl.push(this.contentfulTcoModel.findKlarTekst(this.defaultErrorMessage))
      }
    }
    return fejl
  }
}

export class MinMaxValidator implements FormularFeltValidator {
  private contentfulTcoModel: ContentfulTcoContent

  constructor (contentfulTcoModel: ContentfulTcoContent) {
    this.contentfulTcoModel = contentfulTcoModel
  }

  validate (felt: InstanceType<typeof FeltModel>): string[] {
    const fejl: string[] = []

    if (felt.felttype === FeltType.TEKSTFELT_TAL || felt.felttype === FeltType.TEKSTFELT_HELTAL) {
      if (felt.maksVaerdi > 0 && (+felt.vaerdi > felt.maksVaerdi)) {
        const msg = this.contentfulTcoModel.findKlarTekst('tco.fejl.tal.max-vaerdi')
        fejl.push(msg.replaceAll('{0}', '' + felt.maksVaerdi))
      }

      if (felt.minVaerdi != null && felt.vaerdi !== null && felt.vaerdi !== '' && (+felt.vaerdi < felt.minVaerdi)) {
        const msg = this.contentfulTcoModel.findKlarTekst('tco.fejl.tal.min-vaerdi')
        fejl.push(msg.replaceAll('{0}', '' + felt.minVaerdi))
      }
    } else if (felt.felttype === FeltType.TEKSTFELT_TEKST || felt.felttype === FeltType.TEKSTFELT_AREA) {
      const maksLaengde = felt.maksVaerdi > 0 ? felt.maksVaerdi : 4048
      if (felt.maksVaerdi > 0 && felt.vaerdi !== null && felt.vaerdi !== '' && +felt.vaerdi.length > maksLaengde) {
        const msg = this.contentfulTcoModel.findKlarTekst('tco.fejl.tekst.max-laengde')
        fejl.push(msg.replaceAll('{0}', '' + maksLaengde))
      }

      if (felt.minVaerdi > 0 && felt.vaerdi != null && felt.vaerdi !== '' && +felt.vaerdi.length < felt.minVaerdi) {
        const msg = this.contentfulTcoModel.findKlarTekst('tco.fejl.tekst.min-laengde')
        fejl.push(msg.replaceAll('{0}', '' + felt.minVaerdi))
      }
    } else if (felt.felttype === FeltType.DATO && felt.vaerdi) {
      const date = new Date(felt.vaerdi)
      const minDato = felt.minDato ? new Date(felt.minDato) : null
      const maxDato = felt.maksDato ? new Date(felt.maksDato) : null
      if (minDato && date < minDato) {
        const msg = this.contentfulTcoModel.findKlarTekst('tco.fejl.dato.min-dato')
        fejl.push(msg.replaceAll('{0}', DateUtil.formatDate(felt.minDato)))
      }

      if (maxDato && date > maxDato) {
        const msg = this.contentfulTcoModel.findKlarTekst('tco.fejl.dato.max-dato')
        fejl.push(msg.replaceAll('{0}', DateUtil.formatDate(felt.maksDato)))
      }
    } else if (felt.felttype === FeltType.RADIO_JA_NEJ_MED_INPUT) {
      const vaerdi = felt.vaerdi === felt.jaRadioVaerdi ? felt.jaInputVaerdi : felt.nejInputVaerdi
      if (vaerdi && felt.maksVaerdi > 0 && (+vaerdi > felt.maksVaerdi)) {
        const msg = this.contentfulTcoModel.findKlarTekst('tco.fejl.tal.max-vaerdi')
        fejl.push(msg.replaceAll('{0}', '' + felt.maksVaerdi))
      }

      if (vaerdi && felt.minVaerdi != null && (+vaerdi < felt.minVaerdi)) {
        const msg = this.contentfulTcoModel.findKlarTekst('tco.fejl.tal.min-vaerdi')
        fejl.push(msg.replaceAll('{0}', '' + felt.minVaerdi))
      }
    }

    return fejl
  }
}

export class RegexValidator implements FormularFeltValidator {
  private errorMessage?: string
  private contentfulTcoModel!: ContentfulTcoContent

  constructor (contentfulTcoModel: ContentfulTcoContent, errorMessage?: string) {
    this.contentfulTcoModel = contentfulTcoModel
    this.errorMessage = errorMessage
  }

  validate (felt: InstanceType<typeof FeltModel>): string[] {
    const fejl: string[] = []
    if (felt && felt.valideringsRegex) {
      let vaerdi = felt.vaerdi

      if (felt.felttype === FeltType.RADIO_JA_NEJ_MED_INPUT) {
        vaerdi = (felt.vaerdi === felt.jaRadioVaerdi ? felt.jaInputVaerdi : felt.nejInputVaerdi) || ''
      }

      if (vaerdi) {
        const regExp = new RegExp(felt.valideringsRegex)
        if (!regExp.test(vaerdi)) {
          fejl.push(this.errorMessage ? this.errorMessage : this.contentfulTcoModel.findKlarTekst('tco.fejl.ugyldigt-format'))
        }
      }
    }
    return fejl
  }
}
