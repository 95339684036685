import { createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6ea03a83"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["id"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.chartData)
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: _normalizeClass([{ 'print-graph-container': _ctx.printMode }, "align-text-center"])
      }, [
        _createElementVNode("canvas", {
          ref: "canvasRef",
          id: _ctx.chartId
        }, null, 8, _hoisted_1)
      ], 2))
    : _createCommentVNode("", true)
}