
/* eslint-disable semi */
import { Options, Vue } from 'vue-class-component'
import { DateTime } from 'luxon';
import KlarTekst from '@/components/KlarTekst.vue'
import RichTekst from '@/components/RichTekst.vue'

@Options({
  components: {
    KlarTekst,
    RichTekst
  }
})

export default class KvitteringTG extends Vue {
  currentDate = DateTime.now().toFormat('dd-MM-yyyy kl. HH:mm')
  referenceID
  ogid
  udbudsNavn
  url

  created () : void {
    window.scrollTo(0, 0)
    this.referenceID = this.$route.params.formularID
    this.ogid = this.$route.params.ogid
    this.udbudsNavn = this.$route.params.ogName

    document.title = 'Kvittering - tilbud | TCO'
  }

  beforeUnmount () : void {
    // Reset document title since we haven't implemented it on all pages
    document.title = 'TCO'
  }

  printResultat () : void {
    const route = this.$router.resolve({ path: '/blanketTG/resultat/print/' + this.referenceID });
    window.open(route.href, '_blank')
  }

  printOpsummering () : void {
    const route = this.$router.resolve({ path: '/opsummeringTG/print/' + this.referenceID })
    window.open(route.href, '_blank')
  }
}
