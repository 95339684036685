import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6c727ab0"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "container page-container" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = {
  style: {"margin-top":"0"},
  id: "blanketOverskrift"
}
const _hoisted_4 = {
  key: 1,
  class: "font-lead"
}
const _hoisted_5 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_KlarTekst = _resolveComponent("KlarTekst")!
  const _component_Opsummering = _resolveComponent("Opsummering")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (!_ctx.firstPage)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("a", {
            id: "tilbageKnap",
            class: _normalizeClass('back-link d-none d-lg-inline-block'),
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$router.go(-1)))
          }, [
            _createVNode(_component_KlarTekst, { "tekst-noegle": "tco.og-opsummering.tilbage" })
          ])
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("h2", _hoisted_3, _toDisplayString(_ctx.overskrift), 1),
    (_ctx.underoverskrift)
      ? (_openBlock(), _createElementBlock("p", _hoisted_4, _toDisplayString(_ctx.underoverskrift), 1))
      : _createCommentVNode("", true),
    _createElementVNode("div", { innerHTML: _ctx.beskrivelse }, null, 8, _hoisted_5),
    _createVNode(_component_Opsummering, {
      "v-if": _ctx.ogInformation,
      formular: _ctx.ogInformation,
      editable: false
    }, null, 8, ["v-if", "formular"])
  ]))
}