export const FeltType = Object.freeze({
  TEKSTFELT_TAL: 1,
  OVERSKRIFT: 0,
  TEKSTFELT_TEKST: 2,
  VALGLISTE: 3,
  // Skal laves om til "versioner" af VALGLISTE
  VERSION_FELT: 4,
  ITEM_SPECIFICATION_FELT: 5,
  RADIO_JA_NEJ: 6,
  FIL_UPLOAD: 7,
  TEKSTFELT_AREA: 8,
  PRODUKTGRUPPEVÆLGER: 9,
  HIDDEN: 10,
  DATO: 11,
  STATIC_TEKST: 12,
  RADIO_JA_NEJ_MED_INPUT: 13,
  TEKSTFELT_HELTAL: 14
})

export const DocumentStatus = Object.freeze({
  DRAFT: 'draft',
  PUBLISHED: 'published',
  CLOSED: 'closed'
})

export const Sector9Claims = Object.freeze({
  SUB: 'sub',
  NAME: 'name',
  CPR: 'dk:gov:cpr',
  CVR: 'dk:gov:cvr',
  ORG_NAME: 'dk:gov:org_name',
  PRIVILEGES: 'dk:gov:privileges',
  ROLES: 'dk:gov:virk:roles'
})

export const TCORole = Object.freeze({
  ADMIN: 'TCO_ADMIN',
  ORDREGIVER: 'TCO_ORDREGIVER',
  TILBUDSGIVER: 'TCO_TILBUDSGIVER'
})

export const TCOPrivilege = Object.freeze({
  ORDREGIVER: 'http://tcohandel.dk/ordregiver',
  TILBUDSGIVER: 'http://tcohandel.dk/tilbudsgiver'
})

export const timezone = 'Europe/Copenhagen'

export const ChartColors = Object.freeze({
  CURRENT_EFTERFORBRUG_TOTAL: '#15B094',
  OTHER_EFTERFORBRUG_TOTAL: '#454545',
  CURRENT_DRIFTOMKOSTNINGER_TOTAL: '#73D0BF',
  OTHER_DRIFTOMKOSTNINGER_TOTAL: '#747474',
  CURRENT_SERVICE_TOTAL: '#56D4D6',
  OTHER_SERVICE_TOTAL: '#999999',
  CURRENT_INVERSTERINGSOMKOSTNINGER_TOTAL: '#C8CADF',
  OTHER_INVESTERINGSOMKOSTNINGER_TOTAL: '#BFBFBF',
  CURRENT_SAMFUNDSOEKONOMISKE_TOTAL: '#DDBFDD',
  OTHER_SAMFUNDSOEKONOMISKE_TOTAL: '#DCDCDC',
  CURRENT_CO2_TOTAL: '#73CFBE',
  OTHER_CO2_TOTAL: '#DBDBDB'
})

export const TCOScoreLabels = Object.freeze({
  EFTERFORBRUG_TOTAL: 'Efterforbrug, omkostninger og indtægter i alt',
  DRIFTOMKOSTNINGER_TOTAL: 'Driftsomkostninger i alt',
  SERVICE_TOTAL: 'Service, vedligehold og løbende omkostninger i alt',
  INVERSTERINGSOMKOSTNINGER_TOTAL: 'Investeringsomkostninger i alt',
  SAMFUNDSOEKONOMISKE_TOTAL: 'Samfundsøkonomiske omkostninger af eksterne virkninger på miljøet',
  CO2_TOTAL: 'Samlede eksterne virkninger på miljøet fra brugsfasen',
  TCO_TOTAL: 'TCO inkl. værdisætning af eksterne virkninger på miljøet',
  TCO_CURRENT: 'TCO for den angivne tidsperiode (nutidsværdi)',
  AVERAGE_YEARLY: 'Gennemsnitlige årlige omkostninger'
})
