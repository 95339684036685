import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "navbar navbar-primary" }
const _hoisted_2 = { class: "navbar-inner container" }
const _hoisted_3 = { class: "nav-primary" }
const _hoisted_4 = { key: 0 }
const _hoisted_5 = { key: 1 }
const _hoisted_6 = { key: 2 }
const _hoisted_7 = { key: 3 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_KlarTekst = _resolveComponent("KlarTekst")!
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("ul", _hoisted_3, [
        _createElementVNode("li", null, [
          _createVNode(_component_router_link, {
            to: "/",
            class: "nav-link",
            "active-class": "current"
          }, {
            default: _withCtx(() => [
              _createElementVNode("span", null, [
                _createVNode(_component_KlarTekst, { tekstNoegle: 'tco.introduktion.overskrift.nav' }, null, 8, ["tekstNoegle"])
              ])
            ]),
            _: 1
          })
        ]),
        _createElementVNode("li", null, [
          _createVNode(_component_router_link, {
            to: "/vejledning",
            class: "nav-link",
            "active-class": "current"
          }, {
            default: _withCtx(() => [
              _createElementVNode("span", null, [
                _createVNode(_component_KlarTekst, { tekstNoegle: 'tco.vejledning.overskrift.nav' }, null, 8, ["tekstNoegle"])
              ])
            ]),
            _: 1
          })
        ]),
        (_ctx.isOrdregiver)
          ? (_openBlock(), _createElementBlock("li", _hoisted_4, [
              _createVNode(_component_router_link, {
                to: "/blanket",
                class: "nav-link",
                "active-class": "current"
              }, {
                default: _withCtx(() => [
                  _createElementVNode("span", null, [
                    _createVNode(_component_KlarTekst, { tekstNoegle: 'tco.blanket.og-blanket.overskrift.nav' }, null, 8, ["tekstNoegle"])
                  ])
                ]),
                _: 1
              })
            ]))
          : _createCommentVNode("", true),
        (_ctx.isAdministrator)
          ? (_openBlock(), _createElementBlock("li", _hoisted_5, [
              _createVNode(_component_router_link, {
                to: "/metadata",
                class: "nav-link",
                "active-class": "current"
              }, {
                default: _withCtx(() => [
                  _createElementVNode("span", null, [
                    _createVNode(_component_KlarTekst, { tekstNoegle: 'tco.produktgruppe.overskrift.nav' }, null, 8, ["tekstNoegle"])
                  ])
                ]),
                _: 1
              })
            ]))
          : _createCommentVNode("", true),
        (_ctx.isOrdregiver)
          ? (_openBlock(), _createElementBlock("li", _hoisted_6, [
              _createVNode(_component_router_link, {
                to: "/overblikOG",
                class: "nav-link",
                "active-class": "current"
              }, {
                default: _withCtx(() => [
                  _createElementVNode("span", null, [
                    _createVNode(_component_KlarTekst, { tekstNoegle: 'tco.ogoverblik.overskrift.nav' }, null, 8, ["tekstNoegle"])
                  ])
                ]),
                _: 1
              })
            ]))
          : _createCommentVNode("", true),
        (_ctx.isTilbudsgiver || _ctx.isOrdregiver)
          ? (_openBlock(), _createElementBlock("li", _hoisted_7, [
              _createVNode(_component_router_link, {
                to: "/overblikTG",
                class: "nav-link",
                "active-class": "current"
              }, {
                default: _withCtx(() => [
                  _createElementVNode("span", null, [
                    _createVNode(_component_KlarTekst, { tekstNoegle: 'tco.tg-overblik.overskrift.nav' }, null, 8, ["tekstNoegle"])
                  ])
                ]),
                _: 1
              })
            ]))
          : _createCommentVNode("", true)
      ])
    ])
  ]))
}