import { vModelText as _vModelText, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return _withDirectives((_openBlock(), _createElementBlock("input", {
    onfocus: "this.select()",
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.frontendValue) = $event)),
    onKeypress: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.kunNummer && _ctx.kunNummer(...args)))
  }, null, 544)), [
    [_vModelText, _ctx.frontendValue]
  ])
}